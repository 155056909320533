import { MultiLingualString } from "../../types/MultiLingualString";
import {en} from "../../helpers/language/en";
import {nl} from "../../helpers/language/nl";
import {de} from "../../helpers/language/de";
import {be_nl} from "../../helpers/language/be_nl";
import {be_fr} from "../../helpers/language/be_fr";
import {fr} from "../../helpers/language/fr";
import {pl} from "../../helpers/language/pl";
import {es} from "../../helpers/language/es";

export const ResultErrorTypes = {
    getResult: 'getResult',
    getResults: 'getResults',
    postResult: 'postResult',
    postResultManual: 'postResultManual',
    changeStatusBulk: 'changeStatusBulk',
    changeResult: 'changeResult',
    changeResultDetails: 'changeResultDetails',
    deleteResult: 'deleteResult',
    changeResultValidatedProfile: 'changeResultValidatedProfile',
    updateResultByField: 'updateResultByField',
    updateResultExtraCompetence: 'updateResultExtraCompetence',
    changeResultExtraCompetenceOrder: 'changeResultExtraCompetenceOrder',
    saveExtraCompetence: 'saveExtraCompetence'
} as const

export type ResultErrorTypeKeys = keyof typeof ResultErrorTypes;

export const ResultsErrors: { [key in ResultErrorTypeKeys]: MultiLingualString } = {
    getResult: {
        'en-EN': en.getResult,
        'nl-NL': nl.getResult,
        'de-DE': de.getResult,
        'nl-BE': be_nl.getResult,
        'fr-BE': be_fr.getResult,
        'fr-FR': fr.getResult,
        'pl-PL': pl.getResult,
        'es-ES': es.getResult,
    },
    getResults: {
        'en-EN': en.getResults,
        'nl-NL': nl.getResults,
        'de-DE': de.getResults,
        'nl-BE': be_nl.getResults,
        'fr-BE': be_fr.getResults,
        'fr-FR': fr.getResults,
        'pl-PL': pl.getResults,
        'es-ES': es.getResults,
    },
    postResult: {
        'en-EN': en.postResult,
        'nl-NL': nl.postResult,
        'de-DE': de.postResult,
        'nl-BE': be_nl.postResult,
        'fr-BE': be_fr.postResult,
        'fr-FR': fr.postResult,
        'pl-PL': pl.postResult,
        'es-ES': es.postResult,
    },
    postResultManual: {
        'en-EN': en.postResultManual,
        'nl-NL': nl.postResultManual,
        'de-DE': de.postResultManual,
        'nl-BE': be_nl.postResultManual,
        'fr-BE': be_fr.postResultManual,
        'fr-FR': fr.postResultManual,
        'pl-PL': pl.postResultManual,
        'es-ES': es.postResultManual,
    },
    changeStatusBulk: {
        'en-EN': en.changeStatusBulkError,
        'nl-NL': nl.changeStatusBulkError,
        'de-DE': de.changeStatusBulkError,
        'nl-BE': be_nl.changeStatusBulkError,
        'fr-BE': be_fr.changeStatusBulkError,
        'fr-FR': fr.changeStatusBulkError,
        'pl-PL': pl.changeStatusBulkError,
        'es-ES': es.changeStatusBulkError,
    },
    changeResult: {
        'en-EN': en.changeResult,
        'nl-NL': nl.changeResult,
        'de-DE': de.changeResult,
        'nl-BE': be_nl.changeResult,
        'fr-BE': be_fr.changeResult,
        'fr-FR': fr.changeResult,
        'pl-PL': pl.changeResult,
        'es-ES': es.changeResult,
    },
    changeResultDetails: {
        'en-EN': en.changeResultDetails,
        'nl-NL': nl.changeResultDetails,
        'de-DE': de.changeResultDetails,
        'nl-BE': be_nl.changeResultDetails,
        'fr-BE': be_fr.changeResultDetails,
        'fr-FR': fr.changeResultDetails,
        'pl-PL': pl.changeResultDetails,
        'es-ES': es.changeResultDetails,
    },
    deleteResult: {
        'en-EN': en.deleteResult,
        'nl-NL': nl.deleteResult,
        'de-DE': de.deleteResult,
        'nl-BE': be_nl.deleteResult,
        'fr-BE': be_fr.deleteResult,
        'fr-FR': fr.deleteResult,
        'pl-PL': pl.deleteResult,
        'es-ES': es.deleteResult,
    },
    changeResultValidatedProfile: {
        'en-EN': en.changeResultValidatedProfile,
        'nl-NL': nl.changeResultValidatedProfile,
        'de-DE': de.changeResultValidatedProfile,
        'nl-BE': be_nl.changeResultValidatedProfile,
        'fr-BE': be_fr.changeResultValidatedProfile,
        'fr-FR': fr.changeResultValidatedProfile,
        'pl-PL': pl.changeResultValidatedProfile,
        'es-ES': es.changeResultValidatedProfile,
    },
    updateResultByField: {
        'en-EN': en.updateResultByField,
        'nl-NL': nl.updateResultByField,
        'de-DE': de.updateResultByField,
        'nl-BE': be_nl.updateResultByField,
        'fr-BE': be_fr.updateResultByField,
        'fr-FR': fr.updateResultByField,
        'pl-PL': pl.updateResultByField,
        'es-ES': es.updateResultByField,
    },
    updateResultExtraCompetence: {
        'en-EN': en.updateResultExtraCompetence,
        'nl-NL': nl.updateResultExtraCompetence,
        'de-DE': de.updateResultExtraCompetence,
        'nl-BE': be_nl.updateResultExtraCompetence,
        'fr-BE': be_fr.updateResultExtraCompetence,
        'fr-FR': fr.updateResultExtraCompetence,
        'pl-PL': pl.updateResultExtraCompetence,
        'es-ES': es.updateResultExtraCompetence,
    },
    changeResultExtraCompetenceOrder: {
        'en-EN': en.changeResultExtraCompetenceOrder,
        'nl-NL': nl.changeResultExtraCompetenceOrder,
        'de-DE': de.changeResultExtraCompetenceOrder,
        'nl-BE': be_nl.changeResultExtraCompetenceOrder,
        'fr-BE': be_fr.changeResultExtraCompetenceOrder,
        'fr-FR': fr.changeResultExtraCompetenceOrder,
        'pl-PL': pl.changeResultExtraCompetenceOrder,
        'es-ES': es.changeResultExtraCompetenceOrder,
    },
    saveExtraCompetence: {
        'en-EN': en.saveExtraCompetence,
        'nl-NL': nl.saveExtraCompetence,
        'de-DE': de.saveExtraCompetence,
        'nl-BE': be_nl.saveExtraCompetence,
        'fr-BE': be_fr.saveExtraCompetence,
        'fr-FR': fr.saveExtraCompetence,
        'pl-PL': pl.saveExtraCompetence,
        'es-ES': es.saveExtraCompetence,
    },
}

export const getResultsErrorMessage = (errorName: ResultErrorTypeKeys,  language: keyof MultiLingualString) =>  {
    const message = ResultsErrors[errorName];
    return message[language] || message['en-EN'];
}
