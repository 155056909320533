import { LangObject } from 'helpers/language';

export const es: LangObject = {
    id: '5f26d3ef-6112-4cd4-8e7e-305f629d6231',
    styrAllocator: 'Asignador Styr',
    dashboard: 'Cuadro de mandos',
    organisation: 'Organización/Unidad',
    startAllocator: 'Asignador de inicio',
    searchJobsnRoles: 'Buscar puestos/funciones',
    talentManagementTool: 'Herramienta de gestión profesional',
    elearningStyrModel: 'Modelo Styr de e-learning',
    styrUpdates: 'Actualizaciones de Styr',
    forgotPassword: '¿Ha olvidado la contraseña?',
    logout: 'Cerrar sesión',
    edit: 'Editar',
    add: 'Añadir',
    unitEmpty:
        'El nombre de la unidad no debe estar vacío. Introduzca un nombre para continuar',
    unitAlreadyExists:
        'La unidad ya existe; seleccione otro nombre para continuar',
    noUnits: 'No hay unidades disponibles',
    editJob: 'Editar trabajo',
    editFilterOptions: 'Editar opciones de filtro',
    styrProfile: 'Perfil validado',
    viewValidatedProfile: 'Ver perfil validado',
    viewJobFamily: 'Ver trabajo (Familia)',
    viewJobMatrix: 'Ver matriz de puestos',
    talentMatrix: 'Matriz profesional',
    theTalentMatrix: 'La Matriz profesional',
    jobMatrix: 'Matriz de empleo',
    theJobMatrix: 'La Matriz de empleo',
    jobTalentMatrix: 'Matriz Talentos Trabajo',
    jobMatrixArchitectureName: 'Nombre Arquitectura Matriz Trabajo',
    nameAlreadyExists:
        'El nombre ya existe, introduzca otro nombre para continuar',
    pleaseEnterUnit: 'IntroduzcaUnidad',
    uploadImage: 'Cargar imagen',
    chooseImage: 'Seleccionar imagen',
    styrLevel: 'Nivel Styr',
    filterTags: 'Tags',
    freeInputfieldColumn: 'Columna adicional de la matriz de empleo',
    freeInputfield: 'Columna adicional de la matriz de empleo',
    freeInputfield2: 'Columna adicional de la matriz de empleo 2',
    freeInputfield3: 'Columna adicional de la matriz de empleo 3',
    salaryScale: 'Escala salarial',
    naturalCareerPath: 'Trayectoria profesional',
    styrGroups: 'Grupo Styr',
    styrGroup: 'Grupo Styr',
    problemSolvingCapability: 'Capacidad de resolución de problemas',
    expertiseLevel: 'Nivel de experiencia',
    chooseUserRole: 'Elegir un rol de usuario',
    userRole: 'Rol de usuario',
    planningHorizon: 'Horizonte de planificación',
    continuousImprovement: 'Mejora continua',
    autonomy: 'Autonomía',
    communicationSkills: 'Capacidad de comunicación',
    managementSkill: 'Capacidad de coordinación (opcional)',
    management: 'Gestión',
    educationLevel:
        'Nivel de experiencia. Nivel de trabajo y pensamiento/años de experiencia (indicativo)',
    educationLevelValidatedProfile:
        'Nivel de trabajo y pensamiento / experiencia (indicación)',
    levelOfExpertise: 'Nivel de especialización',
    planning_horizon: 'Horizonte de planificación',
    continuous_improvement: 'Mejora continua',
    communication_skills: 'Capacidad de comunicación',
    coordinationSkills: 'Capacidad de coordinación',
    projectManagementSkills: 'Capacidad de gestión (de proyectos)',
    education_level:
        'Nivel de trabajo y pensamiento / experiencia (indicación)',
    styr_competencies: 'Competencias Styr',
    extra_competencies: 'Competencias adicionales',
    question: '¿Cómo se llama el cargo o la función?',
    unitShort: 'Arquitectura 2',
    jobMatrixArchitecture: 'Arquitectura de la matriz de empleo',
    deleteWarningOrganisationUnit:
        '¡Tenga cuidado! Cuando se elimine esta organización/unidad, también se eliminarán todos los resultados relacionados',
    deleteWarningUnit:
        '¡Tenga cuidado! Cuando se elimine esta unidad, también se eliminarán todos los resultados relacionados',
    deleteWarningUser: '¿Estás seguro de que deseas eliminar este usuario?',
    organisationUnit: 'Organización/Unidad',
    unit: 'Unidad',
    organisationUnitHasNoUnits: (organisationUnitName: string) =>
        `La organización/unidad ${organisationUnitName} no tiene unidades`,
    jobAlreadyExist:
        'Job already exists, please enter a different name to continue',
    noNameEntered: 'No name entered',
    unitMultiple: 'Unidad',
    addNewOrganisationUnit: 'Añadir a organización/unidad',
    addNewUnit: 'Añadir a unidad',
    inviteNewUser: 'Invitar a un nuevo usuario',
    inviteExistingUser: 'Invitar a un usuario existente',
    userInvited: 'Se ha invitado a un nuevo usuario',
    inviteNewPartner: 'Invitar a un nuevo consultor',
    partnerInvited: 'Nuevo consultor invitado',
    next: 'Siguiente',
    back: 'Volver',
    user: 'usuario',
    users: 'Usuarios',
    manageUsers: 'Gestión de usuarios',
    close: 'Cerrar',
    title: 'Título',
    description: 'Descripción',
    comment: 'Mensaje',
    placeholder: (item: string) => `Introduce ${item} aquí...`,
    placeholderError: (item: string) => `¡Por favor, introduce tu ${item}!`,
    noResults: 'No se han encontrado resultados',
    questionsServiceDeskHome:
        'Póngase en contacto con el servicio de asistencia de Styr si tiene alguna pregunta.',
    post: 'Enviar',
    attachment: 'Anexo',
    submit: 'Enviar',
    updates: 'Últimas actualizaciones',
    recentAllocated: 'Asignación más reciente',
    date: 'Fecha',
    retry: 'Repetir',
    role: 'Cargo/ función',
    functionOrRole: 'Puesto / función',
    jobRole: 'Título de la función o cargo',
    jobNRole: 'Cargos/ funciones',
    level: 'Nivel',
    organisationName: 'Nombre de la organización',
    createDetails: 'Detalles de la creación',
    createdOn: 'Creado el',
    createOrganisation: 'Crear organización',
    createUser: 'Crear nuevo usuario',
    createNewOrganisation: 'Añadir organización',
    note: 'Información complementaria',
    save: 'Guardar',
    saving: 'Guardar...',
    saveResults: 'Guardar resultados',
    delete: 'Elimine',
    deletePlural: 'Eliminar',
    newName: 'Nuevo nombre',
    name: 'Nombre',
    firstName: 'Nombre',
    lastName: 'Apellido',
    editPlural: 'Editar',
    confirmation: (action, item: string) =>
        `¿Estás seguro de que quieres ${action} ${item}?`,
    result: 'Resultado',
    cancel: 'Cancelar',
    freeInputLevel: 'Columna adicional de la matriz de empleo',
    columns: 'Columnas',
    compare: 'Compare',
    choose: 'Seleccione',
    change: 'Cambie',
    manageOrganisation: 'Gestionar organización',
    manageValuation: 'Gestionar método de valoración',
    manageValuationDescription:
        'Vincule los niveles clásicos de clasificación de trabajos a los niveles Styr. \\n\\n¡Observación! Esto hará que solo se vean los niveles clásicos de clasificación de trabajos en el módulo de Perfilador de Trabajos.',
    pleaseSelectAtleastTwo: 'Elija al menos 2 niveles para comparar',
    styrDifferencingFactors: 'Factores diferenciadores Styr',
    showTalentpath: 'Ver la trayectoria profesional',
    changeStyrLevel: 'Comparar niveles Styr',
    selectLevelAfterTalentpathChange:
        'Seleccione un nivel después de cambiar la ruta de talentos',
    backToValidatedProfile: 'Volver al perfil',
    backToPreview: 'Volver a la vista previa',
    changeLevel: 'Cambiar nivel Styr',
    talentpath: 'Trayectoria profesional',
    chooseStyrLevels: 'Elija los niveles Styr (máx. 3)',
    selectStyrLevelsToCompare:
        'Seleccione los niveles que desea comparar (máx. 3)',
    currentValidatedProfileLevelIs: 'El nivel Styr actual es',
    youreAboutToChange: 'Está a punto de cambiar esto al nivel',
    changing: 'Modificar',
    theStyrLevel: 'El nivel Styr',
    view: 'Ver',
    allocatorWarningOrganisation:
        'No es posible utilizar el Asignador Styr sin añadir una arquitectura de matriz de empleo a la organización.',
    viewInformation: 'Información complementaria',
    maxLogoWidthDescription: 'Ancho máximo del logotipo 1000px',
    maxLogoWidthWarning: 'Imagen demasiado ancha',
    supportedFileTypes: 'Tipos de archivo admitidos',
    goToManagement: 'Ir a Gestión',
    talentpathAdministrative: 'Administrativo',
    talentpathAdvisory: 'Opinión',
    talentpathCommercial: 'Comercial',
    talentpathExpert: 'Experto',
    talentpathManagement: 'Gestión',
    talentpathProjectManagement: 'Gestión de proyectos',
    talentpathSupportCoordination: 'Apoyo y coordinación',
    talentpathProcessManagement: 'Gestión de procesos',
    talentpathOperationalTechnical: 'Operativo/Técnico',
    manageJobFamily: 'Gestionar Perfilador de empleo',
    manageJobFamilyNotEnabled:
        'El puesto (familias) no está disponible para la organización actual',
    search: 'Buscar',
    jobFamilyAlreadyExist: 'El empleo (familia) ya existe',
    pleaseEnterFamilyName: 'Olvidar  nombre de empleo (familia)',
    addJobFamily: 'Añadir trabajo (Familia)',
    goToSettings: 'Ir a configuración',
    addNewJob: 'Crear nuevo trabajo',
    status: 'Estado',
    changeStatusBulk: 'Cambiar estado:',
    jobFamily: 'Trabajo (Familia)',
    newJobFamily: 'Nuevo trabajo (familia)',
    nameJobFamily: 'Nombre de la familia de empleo',
    lastModified: 'Última modificación',
    differentiatingFactorsTitle: 'Factores diferenciadores',
    changeDifferentiatingFactorsTitle: 'Gestionar Perfilador de trabajos',
    differentiatingFactorsDescription:
        'Para gestionar la visibilidad de los factores diferenciadores, vaya a configuración y actívelos o desactívelos',
    competencies: 'Competencias',
    defaultStyrCompetencies: 'Competencias Styr-TMA',
    extraCompetencies: 'Competencias adicionales',
    mainResponsibilities: 'Principales responsabilidades',
    manage: 'Gestionar',
    row: 'Fila',
    enterTitle: 'Título',
    enterDescription: 'descripción',
    addExtraCompetence: 'Añadir competencia',
    manageRows: 'Filtrar filas',
    createNewJob: 'Crear nuevo trabajo',
    selectOrganisationUnit: 'Seleccionar organización/unidad',
    selectUnit: 'Seleccionar unidad',
    selectTalentPath: 'Seleccionar trayectoria profesional',
    selectLevel: 'Seleccionar nivel Styr',
    pleaseFillInAllField: 'Ha olvidado cumplimentar el formulario.',
    accountability: 'Rendición de cuentas',
    attention_to_detail: 'Atención al detalle',
    business_orientation: 'Orientación empresarial',
    commercial_power: 'Capacidad comercial',
    conduct: 'Conducta',
    controlling_progress: 'Control del progreso',
    cooperation: 'Cooperación',
    creativity: 'Creatividad',
    customer_orientation: 'Orientación al cliente',
    decisiveness: 'Capacidad de decisión',
    delegating: 'Capacidad de delegar',
    developing_employees: 'Desarrolla a los empleados',
    discipline_competence: 'Disciplina',
    flexible_behavior: 'Comportamiento flexible',
    focus_on_quality: 'Foco de atención en la calidad',
    forming_judgment: 'Formación de opinión',
    identification_with_management: 'Identificación con la dirección',
    independence: 'Independencia',
    innovative_power: 'Capacidad innovadora',
    insight: 'Capacidad de percepción',
    leadership_of_groups: 'Liderazgo de grupos',
    managing: 'Gestión',
    need_to_achieve: 'Necesidad de conseguir logros',
    networking: 'Formación de redes',
    perseverance: 'Perseverancia',
    persuasiveness: 'Capacidad de persuasión',
    planning_and_organizing: 'Planificación y organización',
    result_orientedness: 'Orientación a los resultados',
    sociability: 'Sociabilidad',
    social_awareness: 'Conciencia social',
    verbal_expression: 'Expresión verbal',
    vision: 'Visión',
    workmanship: 'Calidad del trabajo',
    written_expression: 'Expresión escrita',
    courage: 'Coraje',
    integrity: 'Integridad',
    backToJobFamilies: 'Volver al trabajo (familias)',
    showAll: 'Mostrar todo',
    jobfamilySearchPlaceholder:
        'Buscar en: Título, organización, unidad, nivel, etc...',
    filterResults: 'Perfiles de filtro',
    password: 'Contraseña',
    accessCode: 'Código de acceso',
    accessCodeDescription:
        'El código de acceso no es una contraseña. El enlace para compartir en combinación con el código de acceso pueden compartirse a través de una intranet o un canal seguro similar. No puede contener caracteres especiales.',
    accessCodeError: 'No puede contener caracteres especiales.',
    usernameOrEmail: 'Nombre de usuario o dirección de correo electrónico',
    usernameEmpty: 'Introduzca el nombre de usuario para continuar',
    passwordEmpty: 'Introduzca la contraseña para continuar',
    microsoftNotWorking:
        'No se ha podido verificar la cuenta. Vuelva a intentarlo más tarde o utilice una cuenta distinta.',
    switchOrganisation: 'Cambiar organizaciones',
    viewOtherOrganisations: 'Ver otras organizaciones',
    login: 'Iniciar sesión',
    forgotPasswordTitle: '¿Ha olvidado la contraseña?',
    backToLogin: 'Volver al inicio de sesión',
    requestNewPassword: 'Solicitar contraseña',
    tryAgain: 'Vuelva a intentarlo',
    newPasswordSend:
        'Se le ha enviado un correo electrónico con un enlace para restablecer la contraseña. ¿No ha recibido el correo electrónico?',
    emailFieldEmpty: 'El campo de correo electrónico está vacío.',
    resetPasswordTitle: 'Restablecer la contraseña',
    resetPasswordSuccess: 'La contraseña se ha restablecido correctamente.',
    newPassword: 'Nueva contraseña',
    duplicateOrganisation: 'Duplicar la organización actual',
    deleteOrganisation: 'Borrar organización',
    manageShareLinkTitle: 'Enlace para compartir de la organización',
    manageShareLinkDescription:
        'Siga los 3 pasos siguientes para crear un enlace compartido que permita que los empleados accedan a la Matriz de puestos/Matriz de talentos y a sus perfiles. Solo los perfiles que estén activos se mostrarán en la Matriz de puestos / Matriz de talentos.\\n    1) Haga clic en "crear un nuevo enlace" y seleccione la vista que desea mostrar.\\n    2) Introduzca un código de acceso para proteger el enlace compartido.\\n    3) Por último, comparta el enlace y código de acceso con el destinatario previsto y asegúrese de que se mantenga confidencial para garantizar el acceso/seguridad previsto.\\n',
    createNewLink: 'Crear nuevo enlace',
    link: 'Enlace',
    dateAdded: 'Fecha añadida',
    questionDeleteShareLink: '¿Seguro que quiere borrarlo?',
    createNewShareLinkTitle: 'Crear enlace para compartir',
    createNewShareLinkDescription:
        'Establezca los derechos de visualización para que los empleados tengan acceso a los niveles pertinentes de la organización/unidades y Styr. Debe proteger el enlace con un código de acceso.',
    allOrganisationUnitsTitle: 'Todos',
    createdBy: 'Clasificado por',
    headerSearchbarNoResults: 'No se han encontrado resultados',
    headerSearchbarPlaceholder: 'Buscar por trabajo, familia, etc...',
    createNewProfile: 'Crear nuevo perfil',
    pleaseSelectAnAnswer: 'Seleccione una respuesta para continuar',
    pleaseSelectALevel:
        'Seleccione un nivel para la nueva trayectoria de talentos.',
    showStyrProfile: 'Mostrar perfil validado',
    createUpdate: 'Crear actualización',
    allocatorQuestions: 'Preguntas del asignador',
    allocatorQuestion: 'Pregunta del asignador',
    errorTitle: 'Algo ha salido mal',
    errorFailedFetchText:
        "No podemos conectar con el servidor. Póngase en contacto con el <a href='mailto:helpdesk@styr.nl'>servicio de ayuda</a> si persiste este problema.",
    errorEndText: 'Vuelva a intentarlo más tarde.',
    tagsHeader: 'Seleccionar etiquetas',
    tagsUntagged: 'Sin etiqueta',
    tagsFutureState: 'Estado futuro',
    tagsCurrentState: 'Estado actual',
    multiselectAllItemsAreSelected: 'Se han seleccionado todos los elementos',
    multiselectClearSearch: 'Eliminar búsqueda',
    multiselectClearSelected: 'Eliminar seleccionados',
    multiselectNoOptions: 'Sin opciones',
    multiselectSearch: 'Buscar',
    multiselectSelectAll: 'Seleccionar todo',
    multiselectSelectAllFiltered: 'Seleccionar todo (filtrado)',
    multiselectSelectSomeItems: 'Seleccionar...',
    multiselectCreate: 'Crear',
    mulitselectSelect: 'Seleccionar',
    loadQuestions:
        'No se pueden cargar las preguntas solicitadas, vuelva a intentarlo más tarde',
    loadValidatedProfiles:
        'No se pueden cargar los perfiles validados, vuelva a intentarlo más tarde',
    loadLevels:
        'No se pueden cargar los niveles, vuelva a intentarlo más tarde',
    loadTalentpaths:
        'No se pueden cargar las trayectorias de talentos, vuelva a intentarlo más tarde',
    createQuestion: 'No se puede crear la pregunta, inténtelo más tarde',
    updateQuestion:
        'No se puede actualizar la pregunta, vuelva a intentarlo más tarde',
    deleteQuestion:
        'No se puede eliminar la pregunta, vuelva a intentarlo más tarde',
    createAnswer:
        'No se puede crear la respuesta, vuelva a intentarlo más tarde',
    updateAnswer:
        'No se puede actualizar la respuesta, vuelva a intentarlo más tarde',
    deleteAnswer:
        'No se puede borrar la respuesta, vuelva a intentarlo más tarde',
    createValidatedProfile:
        'No se puede crear el perfil validado, vuelva a intentarlo más tarde',
    updateValidatedProfile:
        'No se puede actualizar el perfil validado, vuelva a intentarlo más tarde',
    deleteValidatedProfile:
        'No se puede eliminar el perfil validado, vuelva a intentarlo más tarde',
    updateOrganisationRequest:
        'No se puede obtener la organización, vuelva a intentarlo más tarde',
    userSharedLinkLogin:
        'No se puede iniciar sesión con el enlace compartido, vuelva a intentarlo más tarde',
    updateOrganisationUsers:
        'No se pueden obtener los usuarios de la organización, vuelva a intentarlo más tarde',
    updateOrganisationsRequest:
        'No se puede obtener las organizaciones, vuelva a intentarlo más tarde',
    changeRole:
        'No se puede cambiar el rol del usuario, vuelva a intentarlo más tarde',
    inviteUser: 'No se puede invitar al usuario, vuelva a intentarlo más tarde',
    invitePartner:
        'No se puede invitar al socio, vuelva a intentarlo más tarde',
    deleteUser:
        'No se puede eliminar al usuario, vuelva a intentarlo más tarde',
    deleteOrganisationError:
        'No se puede eliminar la organización, vuelva a intentarlo más tarde',
    duplicateOrganisationError:
        'No se puede duplicar la organización, vuelva a intentarlo más tarde',
    createNewOrganisationError:
        'No se puede crear una nueva organización, vuelva a intentarlo más tarde',
    updateOrganisationValuation:
        'No se puede actualizar la valoración de la organización, vuelva a intentarlo más tarde',
    updateOrganisationTMALink:
        'No se puede actualizar el enlace TMA de la organización, vuelva a intentarlo más tarde',
    updateOrganisationJobFamilies:
        'No se pueden actualizar las familias de trabajos de la organización, vuelva a intentarlo más tarde',
    updateOrganisationName:
        'No se puede actualizar el nombre de la organización, vuelva a intentarlo más tarde',
    updateOrganisationMatrixes:
        'No se pueden habilitar o deshabilitar matrices organizativas. Vuelva a intentarlo más tarde.',
    updateResultInFamily:
        'No se puede actualizar el trabajo en la familia, vuelva a intentarlo más tarde',
    addOrganisationUnit:
        'No se puede añadir la unidad de organización, vuelva a intentarlo más tarde',
    updateOrganisationUnit:
        'No se puede actualizar la unidad de organización, vuelva a intentarlo más tarde',
    deleteOrganisationUnit:
        'No se puede eliminar la unidad organizativa, vuelva a intentarlo más tarde',
    addUnit: 'No se puede añadir la unidad, vuelva a intentarlo más tarde',
    deleteUnit: 'No se puede eliminar la unidad, vuelva a intentarlo más tarde',
    updateUnit:
        'No se puede actualizar la unidad, vuelva a intentarlo más tarde',
    updateUnitsAndOrganisationUnitsOrder:
        'No se puede actualizar el orden de las unidades y unidades de organización, vuelva a intentarlo más tarde',
    updateLevelNotes:
        'No se pueden actualizar las notas de nivel, vuelva a intentarlo más tarde',
    uploadOrganisationImage:
        'No se puede cargar la imagen de la organización, vuelva a intentarlo más tarde',
    createShareLink:
        'No se puede crear el enlace para compartir, vuelva a intentarlo más tarde',
    updateShareLink:
        'No se puede actualizar el enlace para compartir, vuelva a intentarlo más tarde',
    deleteShareLink:
        'No se puede eliminar el enlace para compartir, vuelva a intentarlo más tarde',
    updatePermissions:
        'No se pueden actualizar los permisos, vuelva a intentarlo más tarde',
    getRole: 'No se puede obtener el rol, vuelva a intentarlo más tarde',
    loadAllOrganisationsRequest:
        'No se pueden cargar todas las organizaciones, inténtelo de nuevo más tarde',
    loadSelectedUserRequest:
        'No se pueden cargar los usuarios seleccionados, inténtelo de nuevo más tarde',
    updateUserRequest:
        'No se puede actualizar el usuario, inténtelo de nuevo más tarde',
    loadPagedUserRequest:
        'No se pueden cargar los usuarios paginados, inténtelo de nuevo más tarde',
    updatesCreateUpdate:
        'No se puede crear una actualización en este momento, vuelva a intentarlo más tarde',
    updatesGetUpdates:
        'No se pueden obtener actualizaciones en este momento, vuelva a intentarlo más tarde',
    updatesChangeUpdate:
        'No se puede cambiar la actualización en este momento, vuelva a intentarlo más tarde',
    updatesDeleteUpdate:
        'No se puede eliminar la actualización en este momento, vuelva a intentarlo más tarde',
    getResult:
        'No se puede recuperar el trabajo solicitado, vuelva a intentarlo más tarde.',
    getResults:
        'No se pueden recuperar los trabajos solicitados, vuelva a intentarlo más tarde.',
    postResult: 'No se puede crear el trabajo, vuelva a intentarlo más tarde.',
    postResultManual:
        'No se ha podido crear el trabajo, vuelva a intentarlo más tarde.',
    changeStatusBulkError:
        'No se puede cambiar el estado de los trabajos en bloque, vuelva a intentarlo más tarde.',
    changeResult:
        'No se puede editar el trabajo, vuelva a intentarlo más tarde.',
    changeResultDetails:
        'No se pueden cambiar los detalles del trabajo, vuelva a intentarlo más tarde.',
    deleteResult:
        'No se puede eliminar el trabajo, vuelva a intentarlo más tarde.',
    changeResultValidatedProfile:
        'No se puede cambiar el nivel styr del perfil validado, vuelva a intentarlo más tarde.',
    updateResultByField:
        'No se puede actualizar la familia de trabajos, vuelva a intentarlo más tarde.',
    updateResultExtraCompetence:
        'No se puede actualizar la competencia adicional del trabajo, vuelva a intentarlo más tarde.',
    changeResultExtraCompetenceOrder:
        'No se puede cambiar el orden de las competencias adicionales del trabajo, vuelva a intentarlo más tarde.',
    saveExtraCompetence:
        'No se puede guardar la competencia adicional del trabajo, vuelva a intentarlo más tarde.',
    getRemoteLanguages:
        'No se pueden recuperar los idiomas remotos, vuelva a intentarlo más tarde.',
    createJobfamily:
        'No se puede crear una familia de trabajos en este momento, vuelva a intentarlo más tarde',
    updateJobfamily:
        'No se puede actualizar la familia de trabajos en este momento, vuelva a intentarlo más tarde',
    deleteJobfamily:
        'No se puede eliminar la familia de trabajos en este momento, vuelva a intentarlo más tarde',
    updateDifferentiatingFactor:
        'No se puede actualizar el factor diferenciador en este momento, vuelva a intentarlo más tarde',
    updateJobFamilyByField:
        'No se puede actualizar la familia de trabajos en este momento, vuelva a intentarlo más tarde',
    paginationTake: 'Mostrar',
    filterOrganisation: 'Filtrar por organización',
    organisations: 'Organizaciones',
    allOrganisations: 'Todas las organizaciones',
    userExportFileName: 'ExportaciónDeUsuarios',
    dockInformation: 'Información de Dock',
    manual: 'Manual (Descargar PDF)',
    ictIPS: 'IPS TIC (Descargar PDF)',
    manageMatrixes: 'Manejar matrices',
    manageMatrixesDescription:
        '1 matriz siempre debe estar activa. Si ambas matrices están apagadas, 1 matriz se activará automáticamente.',
    noPermissionHeader: 'No tienes permiso para ver esta página',
    noPermissionText:
        'Por favor, póngase en contacto con el administrador para obtener acceso a esta página.',

    elementDisabledHeader: (element: string) => `El ${element} está deshabilitado`,
    elementDisabledText: (element: string) => `Por favor, póngase en contacto con el administrador para habilitar el ${element}.`,

    writeDescription: 'Escribir descripción',
    createNewVersion: 'Crear nueva versión',

    noExistingFiles: 'No hay archivos existentes',
    versionManagement: 'Gestión de versiones',
};
