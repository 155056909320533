import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import ConnectedAdminUserManagementSingle from 'sections/Admin/AdminUserManagementSingle/AdminUserManagementSingle.connected';
import { useParams } from 'react-router-dom';


const AdminUserManagementSingle: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <AdminLayout>
            <ConnectedAdminUserManagementSingle id={id} />
        </AdminLayout>
    );
};

export default AdminUserManagementSingle;
