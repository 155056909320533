import React, { useEffect, useState } from 'react';
import { H2, TextSmall } from 'components/Typography/Typography';
import Button from 'components/Button/Button';
import { SelectImage, SelectImageButton, FormContainer, Content } from './ManageOrganisationImage.components';
import ManageOrganisationImageProps from './ManageOrganisationImage.types';
import getLanguageObject from 'helpers/language';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';
import { loadImage, validateImage, toBase64 } from 'helpers/image';
import { API_URL } from 'config';

const ManageOrganisationImage: React.FC<ManageOrganisationImageProps> = ({
    currentLanguage,
    currentOrganisation,
    uploadOrganisationImage
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [image, setImage] = useState<string | null>(null);
    const [imageFileExtension, setImageFileExtension] = useState<string>("png"); // ['png', 'jpg', 'jpeg']
    const [imageDimensions, setImageDimensions] = useState({
        height: 0,
        width: 0
    });
    const imageUrl = image || (currentOrganisation?.imageUrl ? API_URL + currentOrganisation.imageUrl : require('assets/images/logo.png'));
    const [errorSize, setErrorSize] = useState(false);

    const submit = () => {
        if (image && !errorSize) {
            uploadOrganisationImage(image, imageFileExtension);
            setErrorSize(false);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files ? e.target.files[0] : undefined;
        if (file) {
            toBase64(file).then((res) => {
                const isValidPromise = validateImage(res);
                isValidPromise.then((isValid) => {
                    if (!isValid) {
                        return setErrorSize(true);
                    }
                    setErrorSize(false);
                    setImage(res);
                    setImageFileExtension(file.name.split('.').pop() || 'png');
                })
                .catch(e => console.warn(e))
            });
        }

    }


    return (
        <Content>
            <H2>{lang.uploadImage}</H2>
            <TextSmall>{lang.maxLogoWidthDescription} ({lang.supportedFileTypes}: <i>.jpg, .jpeg, .png, .svg</i>)</TextSmall>
            <img src={imageUrl} alt="Styr Jobmatrix" style={{ height: 80 }} />
            {errorSize && <InputFieldErrorMessages text={lang.maxLogoWidthWarning} />}
            <FormContainer>
                <SelectImageButton>
                    <SelectImage
                        type="file"
                        accept="image/*"
                        onChange={onChange}
                    />
                    {lang.chooseImage}
                </SelectImageButton>
                <Button text="Upload" onClick={submit} priority="primary" disabled={errorSize} />

            </FormContainer>
        </Content>
    );
};

export default ManageOrganisationImage;
