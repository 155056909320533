import { LangObject } from 'helpers/language';

export const pl: LangObject = {
    id: '77009e07-2cf6-4e6f-a7a3-d96e2ade6deb',
    styrAllocator: 'Alokator Styr',
    dashboard: 'Pulpit nawigacyjny',
    organisation: 'Firma/Jednostka',
    startAllocator: 'Uruchom alokator',
    searchJobsnRoles: 'Wyszukaj stanowiska/role',
    talentManagementTool: 'Narzędzie do zarządzania talentami',
    elearningStyrModel: 'Szkolenie online model Styr',
    styrUpdates: 'Aktualizacje Styr',
    forgotPassword: 'Nie pamiętasz hasła?',
    logout: 'Wylogowanie',
    edit: 'Edytuj',
    add: 'Dodaj',
    unitEmpty:
        'Należy podać nazwę jednostki. Wpisz jej nazwę, aby przejść dalej',
    unitAlreadyExists:
        'Ta jednostka już istnieje. Wybierz inną nazwę, aby przejść dalej',
    noUnits: 'Brak dostępnych jednostek',
    editJob: 'Edytuj stanowisko',
    editFilterOptions: 'Edytuj opcje filtra',
    styrProfile: 'Zweryfikowany profil',
    viewValidatedProfile: 'Wyświetl zweryfikowany profil',
    viewJobFamily: 'Wyświetl stanowisko (rodzina)',
    viewJobMatrix: 'Wyświetl macierz stanowisk',
    talentMatrix: 'Matryca talentów',
    jobMatrix: 'Matryca zawodów',
    jobTalentMatrix: 'Macierz stanowisk / Macierz talentów',
    jobMatrixArchitectureName: 'Nazwa struktury macierzy stanowisk',
    nameAlreadyExists:
        'Ta nazwa już istnieje. Wpisz inną nazwę, aby przejść dalej',
    pleaseEnterUnit: 'Wprowadź strukturę macierzy stanowisk',
    uploadImage: 'Załaduj obraz',
    chooseImage: 'Wybierz obraz',
    styrLevel: 'Poziom Styr',
    filterTags: 'Tagi',
    freeInputfieldColumn: 'Dodatkowa kolumna macierzy zawodów',
    freeInputfield: 'Dodatkowa kolumna macierzy zawodów',
    freeInputfield2: 'Dodatkowa kolumna macierzy zawodów 2',
    freeInputfield3: 'Dodatkowa kolumna macierzy zawodów 3',
    salaryScale: 'Skala wynagrodzeń',
    naturalCareerPath: 'Ścieżka kariery',
    styrGroups: 'Grupa Styr',
    styrGroup: 'Grupa Styr',
    problemSolvingCapability: 'Zdolność rozwiązywania problemów',
    expertiseLevel: 'Poziom doświadczenia',
    chooseUserRole: 'Wybierz rolę użytkownika',
    planningHorizon: 'Horyzont planowania',
    continuousImprovement: 'Ciągłe ulepszanie',
    autonomy: 'Autonomia',
    communicationSkills: 'Umiejętności komunikacyjne',
    managementSkill: 'Umiejętności koordynacyjne (opcjonalnie)',
    management: 'Kierownictwo',
    educationLevel:
        'Poziom doświadczenia. Poziom pracy i intelektu / lata doświadczenia (zalecane)',
    educationLevelValidatedProfile:
        'Poziom pracy i myślenia / doświadczenie (wskaźnik)',
    levelOfExpertise: 'Poziom wiedzy',
    planning_horizon: 'Horyzont planowania',
    continuous_improvement: 'Ciągłe ulepszanie',
    communication_skills: 'Umiejętności komunikacyjne',
    coordinationSkills: 'Umiejętności koordynacyjne',
    projectManagementSkills: 'Umiejętności zarządzania (projektami)',
    education_level: 'Poziom pracy i myślenia / doświadczenie (wskaźnik)',
    styr_competencies: 'Kompetencje Styr',
    extra_competencies: 'Dodatkowe kompetencje',
    question: 'Jaka jest nazwa stanowiska lub roli?',
    unitShort: 'Struktura 2',
    jobMatrixArchitecture: 'Struktura matrycy zawodów',
    deleteWarningOrganisationUnit:
        'Uwaga! Po usunięciu tej organizacji/jednostki wszystkie powiązane z nią wyniki również zostaną usunięte',
    deleteWarningUnit:
        'Uwaga! Po usunięciu tej jednostki wszystkie powiązane z nią wyniki również zostaną usunięte',
    organisationUnit: 'Organizacja/Jednostka',
    unit: 'Jednostka',
    unitMultiple: 'Jednostka',
    addNewOrganisationUnit: 'Dodaj do organizacji/jednostki',
    addNewUnit: 'Dodaj do jednostki',
    inviteNewUser: 'Zaproś nowego użytkownika',
    inviteExistingUser: 'Zaproś już istniejącego użytkownika',
    userInvited: 'Zaproszono nowego użytkownika',
    inviteNewPartner: 'Zaproś nowego konsultanta',
    partnerInvited: 'Zaproszono nowego konsultanta',
    next: 'Dalej',
    back: 'Wstecz',
    user: 'użytkownik',
    users: 'Użytkownicy',
    manageUsers: 'Zarządzaj użytkownikami',
    close: 'Zamknij',
    title: 'Tytuł',
    description: 'Opis',
    comment: 'Wiadomość',
    noResults: 'Nie znaleziono żadnych wyników',
    questionsServiceDeskHome:
        'W razie pytań proszę skontaktować się z działem obsługi klienta Styr.',
    post: 'Wyślij',
    attachment: 'Załącznik',
    submit: 'Wyślij',
    updates: 'Ostatnie aktualizacje',
    recentAllocated: 'Ostatnio dokonana alokacja',
    date: 'Data',
    retry: 'Spróbuj ponownie',
    role: 'Stanowisko / rola',
    functionOrRole: 'Stanowisko / rola',
    jobRole: 'Nazwa stanowiska lub roli',
    jobNRole: 'Stanowiska / role',
    level: 'Poziom',
    organisationName: 'Nazwa organizacji',
    createDetails: 'Utwórz szczegóły',
    createdOn: 'Utworzono dnia',
    createOrganisation: 'Utwórz firmę',
    createUser: 'Utwórz nowego użytkownika',
    createNewOrganisation: 'Dodaj firmę',
    note: 'Dodatkowe informacje',
    save: 'Zapisz',
    saving: 'Zapisywanie...',
    saveResults: 'Zapisz wyniki',
    delete: 'Usuń',
    deletePlural: 'Usuwanie',
    newName: 'Nowa nazwa',
    name: 'Nazwa',
    editPlural: 'Edytuj',
    result: 'Wynik',
    cancel: 'Anuluj',
    freeInputLevel: 'Dodatkowa kolumna macierzy zawodów',
    columns: 'Kolumny',
    compare: 'Porównaj',
    choose: 'Wybierz',
    change: 'Zmień',
    manageOrganisation: 'Zarządzaj firmą',
    manageValuation: 'Zarządzaj metodą oceny',
    manageValuationDescription:
        'Połącz klasyczne poziomy oceniania stanowisk z poziomami Styr. \n\nUwaga! Spowoduje to, że w module Narzędzie do profilowania widoczne będą wyłącznie klasyczne poziomy zaszeregowania stanowisk.',
    pleaseSelectAtleastTwo: 'Wybierz co najmniej 2 poziomy do porównania',
    styrDifferencingFactors: 'Czynniki różnicujące Styr',
    showTalentpath: 'Zobacz ścieżkę talentu',
    changeStyrLevel: 'Porównaj poziomy Styr',
    selectLevelAfterTalentpathChange:
        'Wybierz poziom po zmianie ścieżki talentu',
    backToValidatedProfile: 'Wróć do profilu',
    backToPreview: 'Powrót do podglądu',
    changeLevel: 'Zmień poziom Styr',
    talentpath: 'Ścieżka talentu',
    chooseStyrLevels: 'Wybierz poziomy Styr (maks. 3)',
    selectStyrLevelsToCompare:
        'Wybierz poziomy, które chcesz porównać (maks. 3)',
    currentValidatedProfileLevelIs: 'Obecny poziom Styr to ',
    youreAboutToChange: 'Zamierzasz zmienić ten poziom na poziom ',
    changing: 'Zmień',
    theStyrLevel: 'Poziom Styr',
    view: 'Zobacz',
    allocatorWarningOrganisation:
        'Nie można skorzystać z Alokatora Styr bez dodania struktury matrycy zawodów do firmy.',
    viewInformation: 'Dodatkowe informacje',
    maxLogoWidthDescription: 'Maks. szerokość logo 1000px',
    maxLogoWidthWarning: 'Obraz jest zbyt szeroki',
    goToManagement: 'Przejdź do zarządzania',
    talentpathAdministrative: 'Pracownik administracji',
    talentpathAdvisory: 'Doradca',
    talentpathCommercial: 'Handlowiec',
    talentpathExpert: 'Ekspert',
    talentpathManagement: 'Kierownictwo',
    talentpathProjectManagement: 'Zarządzanie projektami',
    talentpathSupportCoordination: 'Pracownik wsparcia i koordynacji',
    talentpathProcessManagement: 'Kierownik procesu',
    talentpathOperationalTechnical: 'Pracownik operacyjny / techniczny',
    manageJobFamily: "Zarządzaj Job Profilerem'",
    manageJobFamilyNotEnabled:
        'Zawody (rodziny zawodów) nie są dostępne dla aktualnej firmy',
    search: 'Szukaj',
    jobFamilyAlreadyExist: 'Ten zawód (rodzina zawodów) już istnieje',
    pleaseEnterFamilyName: 'Nie podano nazwy zawodu (rodziny zawodów)',
    addJobFamily: 'Dodaj stanowisko (rodzina)',
    goToSettings: 'Przejdź do ustawień',
    addNewJob: 'Utwórz nowe stanowisko',
    status: 'Status',
    changeStatusBulk: 'Zmień status:',
    jobFamily: 'Stanowisko (rodzina)',
    newJobFamily: 'Nowe Stanowisko (rodzina)',
    nameJobFamily: 'Nazwa rodziny stanowisk',
    lastModified: 'Ostatnia modyfikacja',
    differentiatingFactorsTitle: 'Czynniki różnicujące',
    changeDifferentiatingFactorsTitle: 'Zarządzaj narzędziem do profilowania',
    differentiatingFactorsDescription:
        'W celu zarządzania wyświetlaniem czynników różnicujących, przejdź do ustawień i włącz je lub wyłącz',
    competencies: 'Kompetencje',
    defaultStyrCompetencies: 'Kompetencje Styr-TMA',
    extraCompetencies: 'Dodatkowe kompetencje',
    mainResponsibilities: 'Główne obowiązki',
    manage: 'Zarządzaj',
    row: 'Wiersz',
    enterTitle: 'Tytuł',
    enterDescription: 'opis',
    addExtraCompetence: 'Dodaj kompetencje',
    manageRows: 'Filtruj wiersze',
    createNewJob: 'Utwórz nowe stanowisko',
    selectOrganisationUnit: 'Wybierz organizację/jednostkę',
    selectUnit: 'Wybierz jednostkę',
    selectTalentPath: 'Wybierz ścieżkę talentu',
    selectLevel: 'Wybierz poziom Styr',
    pleaseFillInAllField: 'Zapomniałeś(-aś) wypełnić formularz.',
    accountability: 'Odpowiedzialność',
    attention_to_detail: 'Dbałość o szczegóły',
    business_orientation: 'Orientacja biznesowa',
    commercial_power: 'Wpływ komercyjny',
    conduct: 'Zachowanie',
    controlling_progress: 'Kontrolowanie postępów',
    cooperation: 'Współpraca',
    creativity: 'Kreatywność',
    customer_orientation: 'Orientacja na klienta',
    decisiveness: 'Podejmowanie decyzji',
    delegating: 'Delegowanie',
    developing_employees: 'Rozwój pracowników',
    discipline_competence: 'Dyscyplina',
    flexible_behavior: 'Elastyczne zachowanie',
    focus_on_quality: 'Koncentracja na jakości',
    forming_judgment: 'Formowanie oceny',
    identification_with_management: 'Identyfikacja z kierownictwem',
    independence: 'Niezależność',
    innovative_power: 'Tworzenie innowacji',
    insight: 'Wgląd',
    leadership_of_groups: 'Kierowanie grupami',
    managing: 'Zarządzanie',
    need_to_achieve: 'Trzeba osiągnąć',
    networking: 'Nawiązywanie relacji biznesowych',
    perseverance: 'Wytrwałość',
    persuasiveness: 'Perswazja',
    planning_and_organizing: 'Planowanie i organizowanie',
    result_orientedness: 'Koncentracja na wynikach',
    sociability: 'Towarzyskość',
    social_awareness: 'Świadomość społeczna',
    verbal_expression: 'Wypowiadanie się ustnie',
    vision: 'Wizja',
    workmanship: 'Fachowość',
    written_expression: 'Wypowiadanie się pisemnie',
    courage: 'Odwaga',
    integrity: 'Uczciwość',
    backToJobFamilies: 'Wróć do stanowiska (rodziny)',
    showAll: 'Pokaż wszystkie',
    jobfamilySearchPlaceholder:
        'Szukaj według: Tytuł, organizacja, jednostka, poziom itp.',
    filterResults: 'Filtruj profile',
    password: 'Hasło',
    accessCode: 'Kod dostępu',
    accessCodeDescription:
        'Kod dostępu nie jest hasłem. Link wraz z kodem dostępu można udostępniać za pośrednictwem intranetu lub podobnego bezpiecznego kanału. Nie może zawierać znaków specjalnych.',
    accessCodeError: 'Nie może zawierać znaków specjalnych.',
    usernameOrEmail: 'Nazwa użytkownika lub adres e-mail',
    usernameEmpty: 'Wpisz nazwę użytkownika, aby kontynuować',
    passwordEmpty: 'Wpisz hasło, aby kontynuować',
    microsoftNotWorking:
        'Nie udało się zweryfikować konta. Spróbuj ponownie później lub użyj innego konta.',
    switchOrganisation: 'Zmień organizacje',
    viewOtherOrganisations: 'Wyświetl inne organizacje',
    login: 'Zaloguj się',
    forgotPasswordTitle: 'Nie pamiętasz hasła?',
    backToLogin: 'Wróć do logowania',
    requestNewPassword: 'Poproś o hasło',
    tryAgain: 'Spróbuj ponownie',
    newPasswordSend:
        'Wiadomość e-mail z linkiem umożliwiającym zresetowanie hasła została wysłana. Nie otrzymałeś(-aś) wiadomości e-mail?',
    emailFieldEmpty: 'Pole e-mail jest puste.',
    resetPasswordTitle: 'Zresetuj hasło',
    resetPasswordSuccess: 'Twoje hasło zostało pomyślnie zresetowane.',
    newPassword: 'Nowe hasło',
    duplicateOrganisation: 'Duplikuj bieżącą organizację',
    deleteOrganisation: 'Usuń organizację',
    manageShareLinkTitle: 'Link do udostępnienia organizacji',
    manageShareLinkDescription:
        'W celu utworzenia linku umożliwiającego pracownikom dostęp do macierzy stanowisk/macierzy talentów i ich profili, wykonaj następujące trzy kroki. W macierzy stanowisk / macierzy talentów będą wyświetlane wyłącznie profile ustawione jako aktywne.\\n 1) Kliknij „Utwórz nowy link” i wybierz widok, który chcesz wyświetlić.\\n    2) Wprowadź kod dostępu, aby zabezpieczyć udostępniany link.\\n    3) Na koniec udostępnij link i kod dostępu docelowemu odbiorcy i upewnij się, że pozostaną poufne, aby zagwarantować zamierzony dostęp/bezpieczeństwo.',
    createNewLink: 'Utwórz nowy link',
    link: 'Link',
    dateAdded: 'Data dodania',
    questionDeleteShareLink: 'Czy na pewno chcesz usunąć?',
    createNewShareLinkTitle: 'Utwórz link do udostępniania',
    createNewShareLinkDescription:
        'Ustaw uprawnienia do przeglądania, aby pracownicy mieli dostęp do odpowiednich organizacji/jednostek i poziomów Styr. Link należy zabezpieczyć za pomocą kodu dostępu.',
    allOrganisationUnitsTitle: 'Wszystkie',
    createdBy: 'Sklasyfikowane według',
    headerSearchbarNoResults: 'Nie znaleziono wyników',
    headerSearchbarPlaceholder: 'Wyszukaj stanowisko, rodzinę itp.',
    createNewProfile: 'Utwórz nowy profil',
    pleaseSelectAnAnswer: 'Wybierz odpowiedź, aby kontynuować',
    pleaseSelectALevel: 'Wybierz poziom dla nowej ścieżki talentu.',
    showStyrProfile: 'Pokaż zweryfikowany profil',
    createUpdate: 'Utwórz aktualizację',
    allocatorQuestions: 'Pytania alokatora',
    allocatorQuestion: 'Pytanie alokatora',
    errorTitle: 'Coś poszło nie tak',
    errorFailedFetchText:
        'Nie możemy połączyć się z serwerem. Jeśli problem będzie się powtarzał, skontaktuj się ze <a href="mailto:helpdesk@styr.nl">wsparciem technicznym</a>.',
    errorEndText: 'Spróbuj ponownie później.',
    tagsHeader: 'Wybierz tagi',
    tagsUntagged: 'Nieoznaczone',
    tagsFutureState: 'Stan przyszły',
    tagsCurrentState: 'Stan obecny',
    multiselectAllItemsAreSelected: 'Wszystkie elementy zostały wybrane',
    multiselectClearSearch: 'Wyczyść wyszukiwanie',
    multiselectClearSelected: 'Wyczyść wybrane',
    multiselectNoOptions: 'Brak opcji',
    multiselectSearch: 'Szukaj',
    multiselectSelectAll: 'Zaznacz wszystkie',
    multiselectSelectAllFiltered: 'Zaznacz wszystkie (filtrowane)',
    multiselectSelectSomeItems: 'Wybieranie...',
    multiselectCreate: 'Utwórz',
    mulitselectSelect: 'Wybierz',
    loadQuestions:
        'Nie można załadować żądanych pytań. Spróbuj ponownie później.',
    loadValidatedProfiles:
        'Nie można załadować zweryfikowanych profili. Spróbuj ponownie później.',
    loadLevels: 'Nie można załadować poziomów. Spróbuj ponownie później.',
    loadTalentpaths:
        'Nie można załadować ścieżek talentów. Spróbuj ponownie później.',
    createQuestion: 'Nie można utworzyć pytania. Spróbuj ponownie później.',
    updateQuestion:
        'Nie można zaktualizować pytania. Spróbuj ponownie później.',
    deleteQuestion: 'Nie można usunąć pytania. Spróbuj ponownie później.',
    createAnswer: 'Nie można utworzyć odpowiedzi. Spróbuj ponownie później.',
    updateAnswer:
        'Nie można zaktualizować odpowiedzi. Spróbuj ponownie później.',
    deleteAnswer: 'Nie można usunąć odpowiedzi. Spróbuj ponownie później.',
    createValidatedProfile:
        'Nie można utworzyć zweryfikowanego profilu. Spróbuj ponownie później.',
    updateValidatedProfile:
        'Nie można zaktualizować zweryfikowanego profilu. Spróbuj ponownie później.',
    deleteValidatedProfile:
        'Nie można usunąć zweryfikowanego profilu. Spróbuj ponownie później.',
    updateOrganisationRequest:
        'Nie udało się uzyskać organizacji. Spróbuj ponownie później.',
    userSharedLinkLogin:
        'Nie można zalogować się za pomocą udostępnionego linku. Spróbuj ponownie później.',
    updateOrganisationUsers:
        'Nie można uzyskać użytkowników organizacji. Spróbuj ponownie później.',
    updateOrganisationsRequest:
        'Nie można uzyskać organizacji. Spróbuj ponownie później.',
    changeRole: 'Nie można zmienić roli użytkownika. Spróbuj ponownie później.',
    inviteUser: 'Nie można zaprosić użytkownika. Spróbuj ponownie później.',
    invitePartner: 'Nie można zaprosić partnera. Spróbuj ponownie później.',
    deleteUser: 'Nie można usunąć użytkownika. Spróbuj ponownie później.',
    deleteOrganisationError:
        'Nie można usunąć organizacji. Spróbuj ponownie później.',
    duplicateOrganisationError:
        'Nie można zduplikować organizacji. Spróbuj ponownie później.',
    createNewOrganisationError:
        'Nie można utworzyć nowej organizacji. Spróbuj ponownie później.',
    updateOrganisationValuation:
        'Nie można zaktualizować oceny organizacji. Spróbuj ponownie później.',
    updateOrganisationTMALink:
        'Nie można zaktualizować linku TMA organizacji. Spróbuj ponownie później.',
    updateOrganisationJobFamilies:
        'Nie można zaktualizować rodzin stanowisk w organizacji. Spróbuj ponownie później.',
    updateOrganisationName:
        'Nie można zaktualizować nazwy organizacji. Spróbuj ponownie później.',
    updateResultInFamily:
        'Nie można zaktualizować stanowiska w rodzinie. Spróbuj ponownie później.',
    addOrganisationUnit:
        'Nie można dodać jednostki organizacyjnej. Spróbuj ponownie później.',
    updateOrganisationUnit:
        'Nie można zaktualizować jednostki organizacyjnej. Spróbuj ponownie później.',
    deleteOrganisationUnit:
        'Nie można usunąć jednostki organizacyjnej. Spróbuj ponownie później.',
    addUnit: 'Nie można dodać jednostki. Spróbuj ponownie później.',
    deleteUnit: 'Nie można usunąć jednostki. Spróbuj ponownie później.',
    updateUnit: 'Nie można zaktualizować jednostki. Spróbuj ponownie później.',
    updateUnitsAndOrganisationUnitsOrder:
        'Nie można zaktualizować kolejności jednostek i jednostek organizacyjnych. Spróbuj ponownie później.',
    updateLevelNotes:
        'Nie można zaktualizować uwag dotyczących poziomu. Spróbuj ponownie później.',
    uploadOrganisationImage:
        'Nie można przesłać obrazu organizacji. Spróbuj ponownie później.',
    createShareLink:
        'Nie można utworzyć linku do udostępniania. Spróbuj ponownie później.',
    updateShareLink:
        'Nie można zaktualizować linku do udostępniania. Spróbuj ponownie później.',
    deleteShareLink:
        'Nie można usunąć linku do udostępniania. Spróbuj ponownie później.',
    updatePermissions:
        'Nie można zaktualizować uprawnień. Spróbuj ponownie później.',
    getRole: 'Nie można uzyskać roli. Spróbuj ponownie później.',
    updatesCreateUpdate:
        'Nie można utworzyć aktualizacji w tej chwili. Spróbuj ponownie później.',
    updatesGetUpdates:
        'Nie można uzyskać aktualizacji w tej chwili. Spróbuj ponownie później.',
    updatesChangeUpdate:
        'Nie można zmienić aktualizacji w tej chwili. Spróbuj ponownie później.',
    updatesDeleteUpdate:
        'Nie można usunąć aktualizacji w tej chwili. Spróbuj ponownie później.',
    getResult:
        'Nie można pobrać żądanego stanowiska. Spróbuj ponownie później.',
    getResults:
        'Nie można pobrać żądanych stanowisk. Spróbuj ponownie później.',
    postResult: 'Nie można utworzyć stanowiska. Spróbuj ponownie później.',
    postResultManual:
        'Utworzenie stanowiska nie powiodło się. Spróbuj ponownie później.',
    changeStatusBulkError:
        'Nie można zmienić statusu kilku stanowisk. Spróbuj ponownie później.',
    changeResult: 'Nie można edytować stanowiska. Spróbuj ponownie później.',
    changeResultDetails:
        'Nie można zmienić szczegółów stanowiska. Spróbuj ponownie później.',
    deleteResult: 'Nie można usunąć stanowiska. Spróbuj ponownie później.',
    changeResultValidatedProfile:
        'Nie można zmienić poziomu Styr zweryfikowanego profilu. Spróbuj ponownie później.',
    updateResultByField:
        'Nie można zaktualizować rodziny stanowisk. Spróbuj ponownie później.',
    updateResultExtraCompetence:
        'Nie można zaktualizować dodatkowych kompetencji stanowiska. Spróbuj ponownie później.',
    changeResultExtraCompetenceOrder:
        'Nie można zmienić kolejności dodatkowych kompetencji stanowiska. Spróbuj ponownie później.',
    saveExtraCompetence:
        'Nie można zapisać dodatkowych kompetencji stanowiska. Spróbuj ponownie później.',
    getRemoteLanguages:
        'Nie można pobrać języków zdalnych. Spróbuj ponownie później.',
    createJobfamily:
        'Nie można utworzyć rodziny stanowiska w tej chwili. Spróbuj ponownie później.',
    updateJobfamily:
        'Nie można zaktualizować rodziny stanowiska w tej chwili. Spróbuj ponownie później.',
    deleteJobfamily:
        'Nie można usunąć rodziny stanowiska w tej chwili. Spróbuj ponownie później.',
    updateDifferentiatingFactor:
        'Nie można zaktualizować współczynnika różnicującego w tej chwili. Spróbuj ponownie później.',
    updateJobFamilyByField:
        'Nie można zaktualizować rodziny stanowiska w tej chwili. Spróbuj ponownie później.',
    theTalentMatrix: 'Matryca Talentów',
    theJobMatrix: 'Matryca Zawodów',
    userRole: 'Rola użytkownika',
    organisationUnitHasNoUnits: (organisationUnitName: string): string =>
        `Jednostka organizacyjna ${organisationUnitName} nie ma jednostek`,
    jobAlreadyExist:
        'Stanowisko już istnieje, wpisz inną nazwę, aby kontynuować',
    noNameEntered: 'Nie wprowadzono nazwy',
    deleteWarningUser: 'Czy na pewno chcesz usunąć tego użytkownika?',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    placeholder: (item: string): string => `Wprowadź ${item} tutaj...`,
    placeholderError: (item: string): string => `Proszę wprowadzić ${item}!`,
    confirmation: (action: string, item: string): string =>
        `Czy na pewno chcesz ${action} ${item}?`,
    supportedFileTypes: 'Obsługiwane typy plików',
    updateOrganisationMatrixes: 'Zaktualizuj matryce organizacyjne',
    loadAllOrganisationsRequest:
        'Nie można załadować wszystkich organizacji, spróbuj ponownie później',
    loadSelectedUserRequest:
        'Nie można załadować wybranego użytkownika, spróbuj ponownie później',
    updateUserRequest:
        'Nie można zaktualizować użytkownika, spróbuj ponownie później',
    loadPagedUserRequest:
        'Nie można załadować użytkowników, spróbuj ponownie później',
    paginationTake: 'Pokaż',
    filterOrganisation: 'Filtruj organizacje',
    organisations: 'Organizacje',
    allOrganisations: 'Wszystkie organizacje',
    userExportFileName: 'EksportUżytkownika',
    dockInformation: 'Informacje o Dock',
    manual: 'Instrukcja (do pobrania w formacie PDF)',
    ictIPS: 'ICT IPS (pobierz PDF)',
    manageMatrixes: 'Zarządzaj macierzami',
    manageMatrixesDescription:
        'Jedna matryca musi być zawsze aktywna. Jeżeli obie matryce zostaną wyłączone, automatycznie włączona zostanie jedna matryca.',
    noPermissionHeader: 'Brak uprawnień',
    noPermissionText:
        'Nie masz uprawnień do tej strony. Skontaktuj się z administratorem.',
    elementDisabledHeader: (element: string): string =>
        `${element} jest wyłączony`,
    elementDisabledText: (element: string): string =>
        `${element} jest wyłączony. Skontaktuj się z administratorem.`,
    writeDescription: 'Napisz opis',
    createNewVersion: 'Utwórz nową wersję',
    noExistingFiles: 'Brak istniejących plików',
    versionManagement: 'Zarządzanie wersjami',
};
