export async function createGuid(src: string) {
    // Encode the source string as UTF-8
    const encoder = new TextEncoder();
    const stringBytes = encoder.encode(src);
  
    // Compute the SHA-1 hash of the string
    const hashedBytes = await crypto.subtle.digest('SHA-1', stringBytes);
  
    // Convert the ArrayBuffer to a byte array (Uint8Array)
    let byteArray = new Uint8Array(hashedBytes);
  
    // Resize the array to 16 bytes to represent a GUID
    byteArray = byteArray.slice(0, 16);
  
    // Correct the byte order for certain parts of the GUID
    const byteOrderCorrection = (bytes: Uint8Array) => {
      const array = [...bytes];
      array.reverse();
      return array;
    };
  
    // Apply byte order correction to the first 3 groups of the GUID
    const part1 = byteOrderCorrection(byteArray.slice(0, 4));
    const part2 = byteOrderCorrection(byteArray.slice(4, 6));
    const part3 = byteOrderCorrection(byteArray.slice(6, 8));
  
    // Convert the byte array to a hexadecimal string
    // @ts-ignore
    const toHex = (bytes: number[] | Uint8Array) => bytes.map(byte => ('00' + byte.toString(16)).slice(-2)).join('');
  
    // Convert the byte array to a hexadecimal string
    const hexOctets = Array.from(byteArray, byte => {
      return ('00' + byte.toString(16)).slice(-2);
    });

    // Construct the GUID string
    const guid = `${toHex(part1)}-${toHex(part2)}-${toHex(part3)}-${hexOctets.slice(8, 10).join('')}-${hexOctets.slice(10, 16).join('')}`;
  
    return guid;
  }