import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import ConnectedAdminUserManagementList from 'sections/Admin/AdminUserManagementList/AdminUserManagementList.connected';


const AdminUserManagement: React.FC = () => {
    return (
        <AdminLayout>
            <ConnectedAdminUserManagementList />
        </AdminLayout>
    );
};

export default AdminUserManagement;
