import styled from 'styled-components';

export const HeaderContainer = styled.header`
    margin-left: auto;
    padding: 0 40px 0 0;
    background-color: #afc8e1;
    height: 70px;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 400px 60px;
    justify-content: end;
    align-items: center;
    gap: 1em;
    box-sizing: border-box;
`;
