import React, { useEffect, useState } from "react";
import { Language, MultiLingualString, emptyMultiLingualString, isMultilingualField } from "types/MultiLingualString";
import { StyrCompetency, ValidatedProfile } from "types/Result";
import BreadCrumbs from "components/BreadCrumbs/BreadCrumbs";
import getLanguageObject from "helpers/language";
import { Header } from 'layouts/MainLayout/MainLayout.components';
import Routes from "routes/Routes.types";
import { AdminAllocatorValidatedProfilesSingleContainer, StyledSelect, ValidatedProfileEditContainer } from "./AdminAllocatorValidatedProfilesSingle.components";
import { DeleteValidatedProfile, LoadLevels, LoadTalentpaths, LoadValidatedProfiles, UpdateValidatedProfile } from "store/allocator/allocator.actions";
import MultiLingualInputField from "containers/MultiLingualInputField/MultiLingualInputField";
import Button from "components/Button/Button";
import { QuestionActionsContainer } from "../AdminAllocatorQuestionsSingle/AdminAllocatorQuestionsSingle.components";
import AreYouSurePopup from "components/AreYouSurePopup/AreYouSurePopup";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RemoteLevel } from "types/Level";
import { RemoteTalentPath } from "types/TalentPath";
import InputField from "containers/InputField/InputField";
import { styrCompetenceOptions } from "utils/styrCompetences";

const StyledHeader = styled(Header)`
    display: grid;
    grid-template-columns: 1fr auto;
`

interface AdminAllocatorValidatedProfilesListProps {
    id: string;
    validatedProfiles: ValidatedProfile[];
    currentLanguage: Language;
    loadValidatedProfiles: LoadValidatedProfiles;
    updateValidatedProfile: UpdateValidatedProfile;
    getRemoteLanguages: () => void;
    deleteValidatedProfile: DeleteValidatedProfile;
    loadLevels: LoadLevels;
    loadTalentpaths: LoadTalentpaths;
    levels: RemoteLevel[];
    talentPaths: RemoteTalentPath[];
}

export const AdminAllocatorValidatedProfilesSingle: React.FC<AdminAllocatorValidatedProfilesListProps> = ({
    id,
    validatedProfiles,
    currentLanguage,
    loadValidatedProfiles,
    updateValidatedProfile,
    loadLevels,
    loadTalentpaths,
    getRemoteLanguages,
    deleteValidatedProfile,
    levels,
    talentPaths
}) => {
    const history = useHistory();
    const lang = getLanguageObject(currentLanguage);
    const validatedProfile = validatedProfiles.find((validatedProfile) => validatedProfile.id === id);

    useEffect(() => {
        getRemoteLanguages()
        loadValidatedProfiles()
    }, [])

    useEffect(() => {
        loadLevels();
    }, [])

    useEffect(() => {
        loadTalentpaths();
    }, [])

    if (!validatedProfile) return null;


    const onDelete = () => {
        deleteValidatedProfile(id).finally(() => {
            history.push(Routes.AdminAllocatorValidatedProfilesRoute);
        })
    }

    return (
        <AdminAllocatorValidatedProfilesSingleContainer>
            <StyledHeader>
                <BreadCrumbs title={validatedProfile.title} paths={[{
                    title: lang.jobNRole,
                    path: Routes.AdminAllocatorValidatedProfilesRoute
                }, {
                    title: validatedProfile.title,
                    path: Routes.AdminAllocatorValidatedProfileRoute
                }]} />
                <AreYouSurePopup
                    onClick={onDelete}
                    action={lang.deletePlural}
                    item={lang.allocatorQuestion}
                    InnerContent={() => <Button text="Delete Validated Profile" />}
                />
            </StyledHeader>
            <ValidatedProfileEdit validatedProfile={validatedProfile} updateValidatedProfile={updateValidatedProfile} levels={levels} talentPaths={talentPaths} />
        </AdminAllocatorValidatedProfilesSingleContainer>
    )
}

interface ValidatedProfileEditProps {
    validatedProfile: ValidatedProfile,
    updateValidatedProfile: UpdateValidatedProfile,
    levels: RemoteLevel[];
    talentPaths: RemoteTalentPath[];
}

const ValidatedProfileEdit: React.FC<ValidatedProfileEditProps> = ({ validatedProfile, updateValidatedProfile, levels, talentPaths }) => {
    const [newValidatedProfile, setNewValidatedProfile] = useState<ValidatedProfile>(validatedProfile);

    const onChangeValidatedProfileField = (field: keyof ValidatedProfile, value: string, language: Language) => {
        if (newValidatedProfile[field] === value) {
            return
        }

        if (isMultilingualField(newValidatedProfile[field])) {
            setNewValidatedProfile({
                ...newValidatedProfile,
                [field]: {
                    ...newValidatedProfile[field] as MultiLingualString,
                    [language]: value
                }
            })
        }
    }

    const competences = newValidatedProfile.competences || [];

    const onChangeCompetence = (index: number, value: string, language: Language) => {
        const newCompetences = [...competences];
        newCompetences[index].translations[language] = value;
        setNewValidatedProfile({
            ...newValidatedProfile,
            competences: newCompetences
        })
    }

    const onChangeCompetenceKey = (index: number, value: string) => {
        const newCompetences = [...competences];
        newCompetences[index].key = value;
        setNewValidatedProfile({
            ...newValidatedProfile,
            competences: newCompetences
        })
    }

    const deleteCompetence = (index: number) => {
        const newCompetences = [...competences];
        newCompetences.splice(index, 1);
        setNewValidatedProfile({
            ...newValidatedProfile,
            competences: newCompetences
        })
    }

    const addCompetence = () => {
        const newCompetences = [...competences];
        newCompetences.push({
            id: undefined,
            key: "autonomy",
            translations: emptyMultiLingualString,
        });
        setNewValidatedProfile({
            ...newValidatedProfile,
            competences: newCompetences
        })
    }

    const fields = [
        { name: "Autonomy", key: "autonomy" },
        { name: "Communication Skills", key: "communicationSkills" },
        { name: "Continuous Improvement", key: "continuousImprovement" },
        { name: "Description", key: "description" },
        { name: "Education Level", key: "educationLevel" },
        { name: "Expertise Level", key: "expertiseLevel" },
        { name: "Management Skills", key: "managementSkills" },
        { name: "Planning Horizon", key: "planningHorizon" },
        { name: "Problem Solving Capability", key: "problemSolvingCapability" },
        { name: "Project Management Skills", key: "projectManagementSkills" },
        { name: "Styr Group", key: "styrGroup" },
    ];

    return (
        <ValidatedProfileEditContainer>
            <h3>Title</h3>
            <InputField errorMessage={null} type="text" value={newValidatedProfile.title} onChange={(e) => {
                setNewValidatedProfile({
                    ...newValidatedProfile,
                    title: e.target.value
                })
            }} />
            <h3>Color</h3>
            <StyledSelect value={newValidatedProfile.color} onChange={(e) => {
                setNewValidatedProfile({
                    ...newValidatedProfile,
                    color: e.target.value
                })
            }}>
                <option value="">None</option>
                <option value="Purple">Purple</option>
                <option value="Yellow">Yellow</option>
                <option value="Orange">Orange</option>
                <option value="Blue">Blue</option>
                <option value="Green">Green</option>
            </StyledSelect>
            <h3>Level</h3>
            <StyledSelect value={newValidatedProfile.styrLevel.id} onChange={(e) => {
                setNewValidatedProfile({
                    ...newValidatedProfile,
                    styrLevel: {
                        id: e.target.value,
                        level: levels.find((level) => level.id === e.target.value)?.level || ""
                    }
                })
            }}>
                <option value="">None</option>
                {levels.map((level) => <option key={level.id} value={level.id}>{level.level}</option>)}
            </StyledSelect>
            <h3>Talent Path</h3>
            <StyledSelect value={newValidatedProfile.talentPath.id} onChange={(e) => {
                const newTalentpath = talentPaths.find((talentPath) => talentPath.id === e.target.value);
                if (!newTalentpath) return;

                setNewValidatedProfile({
                    ...newValidatedProfile,
                    talentPath: newTalentpath
                })
            }
            }>
                <option value="">None</option>
                {talentPaths.map((talentPath) => <option key={talentPath.id} value={talentPath.id}>{talentPath.names["en-EN"]}</option>)}
            </StyledSelect>
            <h3>TMA Code</h3>
            <InputField errorMessage={null} type="text" value={newValidatedProfile.tmaCode} onChange={(e) => {
                setNewValidatedProfile({
                    ...newValidatedProfile,
                    tmaCode: e.target.value
                })
            }} />
            <br />
            <hr />
            {fields.map((field, index) => (
                <React.Fragment key={index}>
                    <h3>{field.name}</h3>
                    <MultiLingualInputField value={newValidatedProfile[field.key as keyof ValidatedProfile] as MultiLingualString} onChange={(value, language) => onChangeValidatedProfileField(field.key as keyof ValidatedProfile, value, language)} />
                </React.Fragment>
            ))}
            <br />
            <hr />
            <CompetenceSection competences={competences} onChangeCompetence={onChangeCompetence} onChangeCompetenceKey={onChangeCompetenceKey} addCompetence={addCompetence} deleteCompetence={deleteCompetence} />
            <br />
            <hr />
            <QuestionActionsContainer>
                <Button text="Save Validated Profile" onClick={() => {
                    updateValidatedProfile(newValidatedProfile);
                }} />
            </QuestionActionsContainer>
        </ValidatedProfileEditContainer>
    )
}

interface CompetenceSectionProps {
    competences: StyrCompetency[];
    onChangeCompetence: (index: number, value: string, language: Language) => void;
    onChangeCompetenceKey: (index: number, value: string) => void;
    deleteCompetence: (index: number) => void;
    addCompetence?: () => void;
}

const CompetenceSection: React.FC<CompetenceSectionProps> = ({ competences, onChangeCompetence, onChangeCompetenceKey, addCompetence, deleteCompetence }) => {
    return (
        <>
            <h2>Competences</h2>
            {competences.map((competence, index) => (
                <div key={index}>
                    <h3>Competence {index + 1}</h3>
                    <Button text="Delete Competence" onClick={() => deleteCompetence(index)} />
                    <h4>Type</h4>
                    <StyledSelect onChange={(e) => onChangeCompetenceKey(index, e.target.value)} value={competence.key}>
                        {styrCompetenceOptions.map((option) => {
                            return <option key={option.key} value={option.key}>{option.value}</option>
                        })}
                    </StyledSelect>
                    <h4>Text</h4>
                    <MultiLingualInputField
                        value={competence.translations}
                        onChange={(value, language) => onChangeCompetence(index, value, language)}
                    />
                </div>
            ))}
            <br />
            <Button text="Add New Competence" onClick={addCompetence} />
        </>
    );
};
