import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'store/root-reducer';
import { saveState } from './localStorage';

const middleware = [thunk];

const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);

store.subscribe(() => {
    saveState({
        user: store.getState().user,
    });
});

export default store;
