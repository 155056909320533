import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import { getCurrentDateTime } from './image';

export const formatImageVersion = (dateString: string): string => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const hour = dateString.substring(9, 11);
    const minute = dateString.substring(11, 13);

    const utcDate = new Date(Date.UTC(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hour),
        parseInt(minute)
    ));

    const localDate = utcDate.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).replace(',', '').replace(/\//g, '-');

    return localDate;
};

export const formatJobFamilyImageFileName = (
    downloadType: string,
    createdDate?: string,
    matrixView?: MatrixViews
): string => {
    if(matrixView === MatrixViews.talentmatrix)
        return `talent-matrix-D${getCurrentDateTime(createdDate)}.${downloadType}`;

    if(matrixView === MatrixViews.jobmatrix)
        return `job-matrix-D${getCurrentDateTime(createdDate)}.${downloadType}`;

    return `job-(family)-D${getCurrentDateTime(createdDate)}.${downloadType}`;
};
