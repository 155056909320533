import { languages } from "helpers/language";

export type Language = 'en-EN' | 'nl-NL' | 'de-DE' | 'nl-BE' | 'fr-BE' | 'fr-FR' | 'pl-PL' | 'es-ES';

export type MultiLingualString = {
    [key in Language]: string;
}

export type MultiLingualObject<T> = {
    [key in Language]: T | null;
};

export const emptyMultiLingualString : MultiLingualString = {
    'en-EN': '',
    'nl-NL': '',
    'nl-BE': '',
    'fr-FR': '',
    'fr-BE': '',
    'de-DE': '',
    'pl-PL': '',
    'es-ES': ''
}

export const convertUserLang = (userLang: string): Language => {
    if(userLang.length == 5) {
        return userLang as Language;
    }
    if(userLang.length == 2) {
        switch(userLang) {
            case 'en': return 'en-EN';
            case 'nl': return 'nl-NL';
            case 'de': return 'de-DE';
            case 'fr': return 'fr-FR';
            case 'pl': return 'pl-PL';
            case 'es': return 'es-ES';
            default: return 'en-EN';
        }
    }
    return 'en-EN';
}

export const isLanguage = (input: string): input is Language => {
    return languages.includes(input as Language);
};

export const isMultilingualField = (input: any): input is MultiLingualString => {
    if (typeof input !== 'object') {
        return false;
    }

    const keys = Object.keys(input);

    return keys.every(key => isLanguage(key));
}
