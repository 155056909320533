enum AllocatorActions {
    AddError = 'AddError',
    SetProfile = 'SetProfile',
    LoadQuestions = 'LoadQuestions',
    LoadValidatedProfiles = 'loadValidatedProfiles',
    SetSelectedAnswers = 'SetSelectedAnswers',
    PreviousQuestion = 'PreviousQuestion',
    NextQuestion = 'NextQuestion',
    Retake = 'Retake',
    LoadLevels = 'LoadLevels',
    LoadTalentpaths = 'LoadTalentpaths',
    StartLoadingValidatedProfiles = 'StartLoadingValidatedProfiles',
}

export default AllocatorActions;
