// Check if value exists in current language, if not, check if it exists in English, if not, return first value that exists
export const getLocalizedValue = (obj: { [key: string]: string }, currentLanguage: string) => {
    const valueInCurrentLanguage = obj[currentLanguage];
    if (valueInCurrentLanguage) {
        return valueInCurrentLanguage;
    }

    for (const key in obj) {
        if (obj[key]) {
            if (key === 'en-EN') {
                return obj[key];
            }
        }
    }

    for (const key in obj) {
        if (obj[key]) {
            return obj[key];
        }
    }

    return '';
};