import styled from 'styled-components';
import theme from 'utils/theme';
import { ButtonContainerProps } from './Button.types';

export const ButtonContainer = styled.button<ButtonContainerProps>`
    all: unset;
    ${props => props.disabled ? `background-color: #eee; color: #333;` :
        `background-color: ${props.bgColor}; color: ${props.txtColor};`}
    padding: ${props => props.size === "small" ? ".25em .5em" : ".5em 1em"};
    border-radius: 5px;
    letter-spacing: 0.2px;
    display: inline-block;
    cursor: pointer;
    max-width: ${props => props.size === "stretch" ? 'unset' : 'fit-content'};
    min-width:  ${props => props.size === "small" ? "80px" : "132px"};
    width: 100%;

    height: 40px;
    text-align: ${props => props.center ? 'center' : 'left'};
    border: 2px solid ${props => props.bgColor === 'transparent' ? props.txtColor : 'transparent'};
    box-sizing: border-box;
    &:hover {
        opacity: 0.64;
    }

    span{
        font-size: 15px;
        font-weight: bold;
    }
`;

export const IconContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
`;

export const BackButtonContainer = styled.div`
    color: ${theme.colors.dark};
    font-family: 'Avenir';
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    line-height: 34px;
    letter-spacing: 0.2px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    width: 300px;
    height: 32px;

    display: grid;
    grid-template-columns: 16px 1fr;
    align-items: center;

    &:hover {
        opacity: 0.64;
    }
`
