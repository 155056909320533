import fallbackQuestions from 'helpers/fallbackAllocatorQuestions';
import QuestionNode from 'types/QuestionNode';
import AllocatorActions from './allocator.constants';
import AllocatorState, { AllocatorDispatches } from './allocator.types';
import { getLevelIndex } from 'utils/levels';

export const initialState: AllocatorState = {
    errors: [],
    questions: {
        history: [],
        questions: [],
        currentQuestion: fallbackQuestions[0],
        selectedAnswers: [],
    },
    validatedProfiles: [],
    loadingValidatedProfiles: false,
    talentPaths: [],
    levels: [],
};

const allocatorReducer = (
    state: AllocatorState = initialState,
    action: AllocatorDispatches
) => {
    switch (action.type) {
        case AllocatorActions.StartLoadingValidatedProfiles:
            return {
                ...state,
                loadingValidatedProfiles: true,
            };
        case AllocatorActions.AddError:
            return {
                ...state,
                errors: [...state.errors, action.payload.error],
            };
        case AllocatorActions.SetProfile:
            return {
                ...state,
                profile: action.payload.profile,
            };
        case AllocatorActions.LoadQuestions:
            const firstQuestion = action.payload.find(
                (x: QuestionNode) => x.isFirstQuestion
            );

            return {
                ...state,
                questions: {
                    ...state.questions,
                    questions: action.payload,
                    currentQuestion: firstQuestion || action.payload[0],
                },
            };
        case AllocatorActions.LoadValidatedProfiles:
            return {
                ...state,
                validatedProfiles: action.payload.validatedProfiles,
            };
        case AllocatorActions.SetSelectedAnswers:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    selectedAnswers: action.payload,
                },
            };
        case AllocatorActions.Retake:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    history: [],
                    currentQuestion:
                        state.questions.questions.find(
                            (x: QuestionNode) => x.isFirstQuestion
                        ) || state.questions.questions[0],
                    selectedAnswers: [],
                },
                profile: undefined,
            };
        case AllocatorActions.PreviousQuestion:
            const historyLength = state.questions.history.length;
            if (historyLength === 0) {
                return state;
            }
            const newState = state.questions.history[historyLength - 1];
            return {
                ...state,
                questions: {
                    ...state.questions,
                    history: state.questions.history.slice(0, -1),
                    currentQuestion: newState.currentQuestion,
                    selectedAnswers: newState.selectedAnswers,
                },
            };
        case AllocatorActions.NextQuestion:
            const oldState = {
                selectedAnswers: state.questions.selectedAnswers,
                currentQuestion: state.questions.currentQuestion,
            };
            return {
                ...state,
                questions: {
                    ...state.questions,
                    history: state.questions.history.concat([oldState]),
                    selectedAnswers: [],
                    currentQuestion: action.payload,
                },
            };
        case AllocatorActions.LoadLevels:
            return {
                ...state,
                levels: action.payload.levels.sort((a, b) => getLevelIndex(a.level) - getLevelIndex(b.level)),
            };
        case AllocatorActions.LoadTalentpaths:
            return {
                ...state,
                talentPaths: action.payload.talentpaths,
            };
        default:
            return state;
    }
};

export default allocatorReducer;
