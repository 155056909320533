import theme from "./theme";

//TODO: migrate this to backend.

export const getLevelIndex = (level: string) => {
    return [
        'p4',
        'p3',
        'p2',
        'p1',
        '12',
        '11',
        '10',
        '9',
        '8',
        '7',
        '6',
        '5',
        '4',
        '3',
        '2',
        '1b',
        '1a',
    ].indexOf(level?.toLowerCase());
}

export const getLevels = (whitelist: string[]) => {
    return [
        'p4',
        'p3',
        'p2',
        'p1',
        '12',
        '11',
        '10',
        '9',
        '8',
        '7',
        '6',
        '5',
        '4',
        '3',
        '2',
        '1b',
        '1a',
    ].filter((s) => whitelist && whitelist.length > 0 ? whitelist.includes(s) : true);
}

export const constructGroupsFromLevels = (availableLevels: string[]) => {
    return [
        ['p4', 'p3', 'p2', 'p1'],
        ['12', '11', '10', '9'],
        ['8', '7', '6'],
        ['5', '4', '3'],
        ['2', '1b', '1a']
    ].map((group) => group.filter(l => availableLevels.includes(l)))
}

const groups = [
    ['p4', 'p3', 'p2', 'p1'],
    ['12', '11', '10', '9'],
    ['8', '7', '6'],
    ['5', '4', '3'],
    ['2', '1b', '1a'],
];

export const getLevelColorByString = (level: string) => {
    for (let i = 0; i < groups.length; i++) {
        if (groups[i].includes(level.toLowerCase())) {
            return groupToColor(i);
        }
    }
    return '#D0CABE';
};

export const groupToColor = (index: number) => {
    if (index === 0) {
        return theme.colorCodePicker('purple');
    }
    if (index === 1) {
        return theme.colorCodePicker('yellow');
    }
    if (index === 2) {
        return theme.colorCodePicker('orange');
    }
    if (index === 3) {
        return theme.colorCodePicker('blue');
    }
    if (index === 4) {
        return theme.colorCodePicker('green');
    }
    return '#000';
};

export const turquesLevels = ['default', '17', '18', 'T1', 'T2'];
export const purpleLevels = ['default', '13', '14', '15', '16', 'P1', 'P2', 'P3', 'P4'];
export const yellowLevels = ['default', '9', '10', '11', '12'];
export const orangeLevels = ['default', '6', '7', '8',];
export const blueLevels = ['default', '3', '4', '5'];
export const greenLevels = ['default', '1A', '1B', '2'];
