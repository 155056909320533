import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import { PromiseDownloadMatrixProps } from 'helpers/convertMatrixToFile';
import {
    LocalJobMatrixData,
    RemoteJobMatrixData,
} from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.types';
import { Permissions } from 'store/user/user.types';
import { Language } from 'types/MultiLingualString';
import { Organisation } from 'types/Organisation';
import { Result } from 'types/Result';

export default interface MatrixHeaderProps {
    isFullscreen?: boolean;
    currentOrganisation?: Organisation;
    language?: Language;
    cLanguage: Language;
    currentView: MatrixViews;
    visible?: boolean;
    localData: LocalJobMatrixData;
    remoteData: RemoteJobMatrixData;
    levels: string[];
    changeVisibilityLevel: (level: string) => void;
    changeVisibilityColumn: (uuid: string, newValue?: boolean) => void;
    changeVisibilityTalentpath: (uuid: string) => void;
    changeFilterTag: (tag: string) => void;
    permissions: Permissions;
    jobs: Result[];
    isShareLink: boolean;
    handleDownloadMatrix: (type: DownloadTypes, matrixView: MatrixViews) => void;
}

export interface RenderUnitsOrTalentpathProps {
    currentLanguage: Language;
    currentView: MatrixViews;
    changeVisibilityOrganisationUnit: (organisationUnit: string) => void;
    changeVisibilityColumn: (uuid: string, newValue?: boolean) => void;
    changeVisibilityTalentpath: (uuid: string) => void;
    getTalentPathOptions: () => {
        uuid: string;
        name: any;
        shown: boolean;
    }[];
    getAvailableOrganisation: () => {
        uuid: string;
        name: string;
        shown: boolean;
    }[];
    getAvailableOrganisationUnits: () => {
        uuid: string;
        name: string;
        shown: boolean;
    }[];
}

export enum DownloadTypes {
    PDF = 'pdf',
    Image = 'png',
    Versioning = 'versioning',
}
