import Button from 'components/Button/Button';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { FullArrowDownIcon } from 'components/Icons/arrowDown';
import { FullArrowUpIcon } from 'components/Icons/arrowUp';
import { HeaderCheckBox, State } from 'components/TableElements/TableElements';
import Routes from 'routes/Routes.types';
import { Family } from 'types/Result';
import {
    TableBody,
    TableItemContainer,
    SortButtonContainer,
    SortButtonAdditionalText,
    Row,
} from '../ListOfJobFamilies.components';
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'helpers/useNavigation';
import {
    updateJobFamilySortOrder,
} from 'store/families/jobfamily.actions';
import ReduxStore from 'store/store.type';
import { connect } from 'react-redux';
import getLanguageObject from 'helpers/language';
import { JobFamilyTableBodyProps } from '../types';
import { debounce } from 'lodash';

export const JobFamilyTableBody: FC<JobFamilyTableBodyProps> = ({
    jobFamilies,
    filters,
    isShareLink,
    permissions,
    updateJobFamilySortOrder,
    currentLanguage,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const { navigate } = useNavigate();
    const {
        changeSorting,
        state,
        statusFilter,
        selectedItems,
        handleSelectedItems,
    } = filters;

    const [sortedFamilies, setSortedFamilies] = useState<Family[]>(jobFamilies);

    const countItems = (value: any[] | null): number => {
        if (!value || value === null) return 0;

        return value.length;
    };

    let filteredFamilies = jobFamilies
        .filter(x => {
            if (!statusFilter) {
                return true;
            }
            return x.status?.toLowerCase() === statusFilter;
        })
        .filter(x => {
            if (!isShareLink) {
                return true;
            }
            return x.status?.toLowerCase() === 'active';
        });


    useEffect(() => {
        filteredFamilies = jobFamilies
            .filter(x => {
                if (!statusFilter) {
                    return true;
                }
                return x.status?.toLowerCase() === statusFilter;
            })
            .filter(x => {
                if (!isShareLink) {
                    return true;
                }
                return x.status?.toLowerCase() === 'active';
            });
    }, [statusFilter, jobFamilies]);

    useEffect(() => {
        setSortedFamilies(filteredFamilies.sort(
            (a, b) => a.sortOrder - b.sortOrder
        ));
    }, [filteredFamilies]);

    const debounced = React.useCallback(debounce(updateJobFamilySortOrder, 1000), []);

    const handleMove = (index: number, direction: 'up' | 'down') => {
        let localSortedFamilies = [...filteredFamilies].sort(
            (a, b) => a.sortOrder - b.sortOrder
        );

        if (index === 0 && direction === 'up') return;
        if (index === localSortedFamilies.length - 1 && direction === 'down') return;

        const newIndex = direction === 'up' ? index - 1 : index + 1;

        if (!localSortedFamilies[index] || !localSortedFamilies[newIndex]) return;

        const tempSortOrder = localSortedFamilies[index].sortOrder;
        localSortedFamilies[index].sortOrder = localSortedFamilies[newIndex].sortOrder;
        localSortedFamilies[newIndex].sortOrder = tempSortOrder;

        localSortedFamilies = [...localSortedFamilies].sort(
            (a, b) => a.sortOrder - b.sortOrder
        );

        debounced(localSortedFamilies);
        setSortedFamilies(localSortedFamilies);

        if (state.sorting !== 'sortOrder') changeSorting('sortOrder');
    };

    return (
        <TableBody>
            {sortedFamilies.map((family: Family, index: number) => {
                const allowToView =
                    countItems(family.jobs) === 0 ? true : false;
                const checkStatus =
                    family.status === null ? 'Concept' : family.status;

                const renderSortButtons = () => {
                    if (index === 0) {
                        return (
                            <IconButton
                                color={'success'}
                                IconSVG={FullArrowDownIcon}
                                onClick={() => handleMove(index, 'down')}
                            />
                        );
                    }

                    if (index === sortedFamilies.length - 1) {
                        return (
                            <IconButton
                                color={'success'}
                                IconSVG={FullArrowUpIcon}
                                onClick={() => handleMove(index, 'up')}
                            />
                        );
                    }

                    return (
                        <>
                            <IconButton
                                color={'success'}
                                IconSVG={FullArrowUpIcon}
                                onClick={() => handleMove(index, 'up')}
                            />
                            <IconButton
                                color={'success'}
                                IconSVG={FullArrowDownIcon}
                                onClick={() => handleMove(index, 'down')}
                            />
                        </>
                    );
                };

                return (
                    <TableItemContainer key={family.id}>
                        {permissions.canManageOrganisation ? (
                            <HeaderCheckBox
                                activeItem={selectedItems.includes(family.id)}
                                onClick={() => {
                                    handleSelectedItems(family.id);
                                }}
                            />
                        ) : (
                            <div />
                        )}
                        <SortButtonContainer>
                            <div>{renderSortButtons()}</div>
                            <SortButtonAdditionalText
                                hasTwoButtons={
                                    family.sortOrder > 0 &&
                                    family.sortOrder < sortedFamilies.length - 1
                                }
                            >
                                {state.sorting === 'sortOrder' &&
                                    family.sortOrder + 1}
                            </SortButtonAdditionalText>
                        </SortButtonContainer>

                        <div>
                            {family.name
                                .replace(/<[^>]*>?/gm, '')
                                .replace('&amp;', '&')}
                        </div>
                        <div>{countItems(family.jobs)}</div>
                        <div>
                            {moment
                                .utc(family.lastModified)
                                .local()
                                .format('DD MMM YYYY HH:mm')}
                        </div>
                        <State status={checkStatus} />
                        <Row>
                            {(permissions.canManageOrganisation ||
                                family.status?.toLowerCase() === 'concept') && (
                                    <Button
                                        text={lang.manage}
                                        priority="primary"
                                        onClick={() =>
                                            navigate(
                                                Routes.JobFamilyEdit + family.id
                                            )
                                        }
                                        size="small"
                                    />
                                )}
                            <Button
                                text={lang.view}
                                priority="tertiary"
                                onClick={() =>
                                    navigate(Routes.JobFamilyView + family.id)
                                }
                                disabled={allowToView}
                                size="small"
                            />
                        </Row>
                    </TableItemContainer>
                );
            })}
        </TableBody>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    permissions: state.user.permissions,
    isShareLink: state.user.isShareLink,
});
const mapDispatchToProps = {
    updateJobFamilySortOrder,
};
export const ConnectedJobFamilyTableBody = connect(
    mapStateToProps,
    mapDispatchToProps
)(JobFamilyTableBody);

