import { Result } from 'types/Result';
import ResultsActions from './results.constants';
import ResultsState, { ResultsDispatches } from './results.types';

export const initialState: ResultsState = {
    errors: [],
    results: [],
};

const resultsReducer = (
    state: ResultsState = initialState,
    action: ResultsDispatches
) => {
    switch (action.type) {
        case ResultsActions.AddError:
            return {
                ...state,
                errors: [...state.errors, action.payload.error],
            };
        case ResultsActions.ClearErrors:
            return {
                ...state,
                errors: [],
            };
        case ResultsActions.UpdateResult:
            const newResult = action.payload.result;

            const oldState = state.results;
            const resultAlreadyExists = oldState.find(
                x => x.id === newResult.id
            );

            if (resultAlreadyExists) {
                const newState = oldState.map(oldResult => {
                    if (oldResult.id === newResult.id) {
                        return {
                            ...resultAlreadyExists,
                            ...newResult,
                        };
                    }
                    return oldResult;
                });
                return {
                    ...state,
                    results: newState,
                };
            }
            return {
                ...state,
                results: oldState.concat([newResult]),
            };
        case ResultsActions.UpdateResultExtraCompetence:
            const newState = {
                ...state,
                results: state.results.map(result => {
                    if (result.id === action.payload.resultId) {
                        const newResult = {
                            ...result,
                            jobCompetences: action.payload.jobCompetences,
                        } as Result;
                        return newResult
                    }
                    return result;
                })
            };
            return newState
        case ResultsActions.RemoveExtraCompetence:
            const newResults = {
                ...state,
                results: state.results.map(result => {
                    if (result.id === action.payload.resultId) {
                        const newResult = {
                            ...result,
                            jobCompetences: result.jobCompetences?.filter((_, index) => index != action.payload.position),
                        } as Result;
                        return newResult
                    }
                    return result;
                })
            };
            return newResults
        case ResultsActions.UpdateResults:
            return {
                ...state,
                results: action.payload.results.map(x => {
                    // Used to keep single-result data when updating from GET results
                    const oldResult = state.results.find(y => y.id === x.id);
                    return {
                        ...oldResult,
                        ...x,
                    };
                }),
            };
        default:
            return state;
    }
};

export default resultsReducer;
