import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "index";
import { loginRequest } from "msal.config";
import ReduxStore from "./store.type";
import { Dispatch } from "react";
import { userLoginMicrosoft } from "./user/user.actions";
import { AnyAction } from "redux";

// Acquire a token silently or interactively depending on the refresh token being valid or not.
const acquireToken = async (account: AccountInfo) => {
  const request = {
    account: account,
    scopes: loginRequest.scopes,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Handle interaction required error
      // This might involve redirecting to the login page or showing a popup
      return (await msalInstance.acquireTokenPopup(request)).idToken;
    } else {
      throw error;
    }
  }
};

// Get the token from the store or acquire a new one if it expired.
export const getToken = async (state: ReduxStore, dispatch: Dispatch<AnyAction>) => {
    if(state.user.isShareLink) {
      if(!state.user.jwt) {
        throw new Error("No account found");
      }

      return state.user.jwt;
    }

    const account = msalInstance.getAllAccounts()[0]
    if (!account) {
      throw new Error("No account found");
    }
    const newAccessToken = await acquireToken(account);

    const oldAccessToken = state.user.jwt;
    if (oldAccessToken === newAccessToken) {
      return newAccessToken;
    }

    // Update the token in the store
    //eslint-disable-next-line
    //@ts-ignore
    userLoginMicrosoft(newAccessToken)(dispatch);

    return newAccessToken;
  };
