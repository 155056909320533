import { levelToColor } from "helpers/colors"
import React from "react"
import styled from "styled-components"

const Container = styled.div<{level: string; size?: number;}>`
    width: auto;
    min-width: 60px;
    padding: 3px;
    height: ${props => props.size ? props.size : 48}px;
    background-color: ${props => props.level};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
`

const NumberTag: React.FC<{level: string; size?: number; alternativeLevel?: string;}> = ({level, size, alternativeLevel}) => (
    <Container level={levelToColor(level.toLowerCase())} size={size}>
        {alternativeLevel || "Styr " + level.toLocaleUpperCase()}
    </Container>
)

export default NumberTag
