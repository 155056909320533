import React from 'react';

const CalendarTime: React.FC = () => {
    return (
        <svg
            width="20"
            height="19"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 0.5C3.30469 0.5 3.5625 0.757812 3.5625 1.0625V2H6.9375V1.0625C6.9375 0.757812 7.17188 0.5 7.5 0.5C7.80469 0.5 8.0625 0.757812 8.0625 1.0625V2H9C9.82031 2 10.5 2.67969 10.5 3.5V3.875V5H10.125H9.375H6.75H1.125V11C1.125 11.2109 1.28906 11.375 1.5 11.375H6.65625C6.9375 11.8203 7.3125 12.2188 7.73438 12.5H1.5C0.65625 12.5 0 11.8438 0 11V5V3.875V3.5C0 2.67969 0.65625 2 1.5 2H2.4375V1.0625C2.4375 0.757812 2.67188 0.5 3 0.5ZM6.75 9.125C6.75 7.92969 7.38281 6.82812 8.4375 6.21875C9.46875 5.60938 10.7578 5.60938 11.8125 6.21875C12.8438 6.82812 13.5 7.92969 13.5 9.125C13.5 10.3438 12.8438 11.4453 11.8125 12.0547C10.7578 12.6641 9.46875 12.6641 8.4375 12.0547C7.38281 11.4453 6.75 10.3438 6.75 9.125ZM10.125 7.25C9.91406 7.25 9.75 7.4375 9.75 7.625V9.125C9.75 9.33594 9.91406 9.5 10.125 9.5H11.25C11.4375 9.5 11.625 9.33594 11.625 9.125C11.625 8.9375 11.4375 8.75 11.25 8.75H10.5V7.625C10.5 7.4375 10.3125 7.25 10.125 7.25Z"
                fill="black"
            />
        </svg>
    );
}

export default CalendarTime;