
import Button from 'components/Button/Button';
import { H2 } from 'components/Typography/Typography';
import InputField from 'containers/InputField/InputField';
import { InputFieldErrorMessages, SuccesMessages } from 'containers/InputField/InputField.components';
import { emailValidation } from 'containers/InputField/InputField.valid';
import { onKeyDown } from 'helpers/form';
import getLanguageObject from 'helpers/language';
import React, { ChangeEvent, useState } from 'react';
import { Role } from 'types/Role';
import {
    Container, DropdownSelect, Icon,
    InviteExistingUserDropdown, InviteNewUserContainer, Label
} from './InviteNewUser.components';
import styled from 'styled-components';
import { ExistingUserProps, InviteUserProps, NewUserProps } from './InviteNewUser.types';
import ArrowDownIcon from 'components/Icons/arrowDown';

const InviteUser: React.FC<InviteUserProps> = ({
    inviteUser,
    currentLanguage,
    users,
    potentialUsers,
    permissions,
    popup,
    setPopup,
}) => (
    <Container>
        <PopupContainer>
            {popup === 'existing-user' && (
                <ExistingUser potentialUsers={potentialUsers} inviteUser={inviteUser} users={users} currentLanguage={currentLanguage} permissions={permissions} setPopup={setPopup} />
            )}
            {popup === 'new-user' && (
                <NewUser inviteUser={inviteUser} users={users} currentLanguage={currentLanguage} permissions={permissions} setPopup={setPopup} />
            )}
        </PopupContainer>
    </Container >
);

export default InviteUser;


const PopupContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
`;

const ControllerButtons = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;


const NewUser: React.FC<NewUserProps> = ({
    inviteUser,
    currentLanguage,
    users,
    permissions,
    setPopup
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [user, setUser] = useState({
        name: '',
        email: ''
    });


    const [userRole, setUserRole] = useState<Role>('ClientUser');
    const [error, setError] = useState({
        new: {
            exist: false,
            value: false,
            valid: false,
        }
    });

    const [userInvited, setUserInvited] = useState({
        new: false,
        existing: false
    });

    const timer = () => {
        setTimeout(() => {
            setUserInvited({ new: false, existing: false });
        }, 3000);
    };

    const inviteUserSuccessMessage = (newUser: boolean) => {
        if (newUser) {
            setUserInvited({
                ...userInvited,
                new: true,
                existing: false
            }); timer();
        }

        return null;
    }


    const submit = () => {
        const emailExist = users?.find(x => x.emailAdress === user.email);
        if (emailExist) return setError({ ...error, new: { exist: true, value: false, valid: false } })
        if (!user.email) return setError({ ...error, new: { exist: false, value: true, valid: false } })
        if (!emailValidation(user.email)) return setError({ ...error, new: { exist: false, value: false, valid: true } })

        setError({ ...error, new: { exist: false, value: false, valid: false } })
        inviteUser(user.email, user.name, userRole);
        setUser({ ...user, name: '', email: '' });
        inviteUserSuccessMessage(true);
        setTimeout(() => {
            setPopup(null);
        }, 3000);
    };

    const MaybeRenderNewError = () => {
        if (error.new.exist) return <InputFieldErrorMessages text="Email exists" />
        if (error.new.value) return <InputFieldErrorMessages text="Please enter email" />
        if (error.new.valid) return <InputFieldErrorMessages text="Please enter valid email" />

        return null;
    }

    //TODO: Create types for this
    const onChangeRole = (event: ChangeEvent<HTMLSelectElement>) => {
        if (
            event.target.value === 'ClientUser' ||
            event.target.value === 'ClientExpert' ||
            event.target.value === 'PartnerUser' ||
            event.target.value === 'PartnerExpert'
        ) {
            setUserRole(event.target.value);
        }
    };

    return (
        <InviteNewUserContainer>
            <H2>{lang.createUser}</H2>
            <InputField
                //TODO: translate
                placeholder="Full name"
                type="text"
                value={user.name}
                onChange={event => setUser({ ...user, name: event.target.value })}

                onKeyDown={event => onKeyDown(event, submit)}
                errorMessage={<MaybeRenderNewError />} fullWidth />
            <InputField
                //TODO: translate
                placeholder="Email"
                type="email"
                value={user.email}
                onChange={event => setUser({ ...user, email: event.target.value })}
                onKeyDown={event => onKeyDown(event, submit)}
                errorMessage={<MaybeRenderNewError />} fullWidth />
            <Label>
                <DropdownSelect value={userRole} onChange={onChangeRole}>
                    <option value="">{lang.chooseUserRole}</option>
                    <option value="ClientUser">Client User</option>
                    {(permissions.canInviteExpert || permissions.canInvitePartnerUser) && <option value="ClientExpert">Client Expert</option>}
                    {permissions.canInvitePartnerUser && <option value="PartnerUser">Partner User</option>}
                    {permissions.canInvitePartnerExpert && <option value="PartnerExpert">Partner Expert</option>}
                </DropdownSelect>
                <Icon>
                    <ArrowDownIcon color="#000" />
                </Icon>
            </Label>
            <MaybeRenderNewError />
            {
                userInvited.new &&
                (<SuccesMessages text={lang.userInvited} />)
            }
            <ControllerButtons>
                <Button text={lang.cancel} onClick={() => setPopup(null)} priority="secondary" />
                <Button text={lang.createUser} onClick={submit} priority="primary" />
            </ControllerButtons>
        </InviteNewUserContainer>
    )
};


const ExistingUser: React.FC<ExistingUserProps> = ({
    inviteUser,
    currentLanguage,
    potentialUsers,
    setPopup,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [existingUser, setExistingUser] = useState({
        name: '',
        email: ''
    });

    const [error, setError] = useState({
        new: {
            exist: false,
            value: false,
            valid: false,
        },
        existing: {
            exist: false,
            value: false,
            valid: false,
        },
    });

    const [userInvited, setUserInvited] = useState({
        new: false,
        existing: false
    });

    const timer = () => {
        setTimeout(() => {
            setUserInvited({ new: false, existing: false });
        }, 3000);
    };

    const inviteUserSuccessMessage = (existingUser: boolean) => {
        if (existingUser) {
            setUserInvited({
                ...userInvited,
                new: false,
                existing: true
            }); timer();
        }

        return null;
    }

    const submitPotentialUsers = () => {
        if (!existingUser.email) return setError({ ...error, existing: { exist: false, value: true, valid: false } })
        if (!emailValidation(existingUser.email)) return setError({ ...error, existing: { exist: false, value: false, valid: true } })

        setError({ ...error, existing: { exist: false, value: false, valid: false } })
        inviteUser(existingUser.email, existingUser.name, 'ClientUser');
        setExistingUser({ ...existingUser, name: existingUser.name, email: '' });
        inviteUserSuccessMessage(true);
    }

    const MaybeRenderExistingError = () => {
        if (error.existing.exist) return <InputFieldErrorMessages text="Email exists" />
        if (error.existing.value) return <InputFieldErrorMessages text="Please enter email" />
        if (error.existing.valid) return <InputFieldErrorMessages text="Please enter valid email" />

        return null;
    }


    const onFindUser = (email: string) => {
        const findUser = potentialUsers?.find(x => x.emailAdress === email);
        setExistingUser({ ...existingUser, name: findUser?.firstName + " " + findUser?.lastName || '', email: email });
    }

    if (!potentialUsers) return <InviteNewUserContainer>
        <H2>{lang.inviteExistingUser}</H2>
        <ControllerButtons>
            <Button text={lang.cancel} onClick={() => setPopup(null)} priority="secondary" />
        </ControllerButtons>
    </InviteNewUserContainer>;

    return (
        <InviteNewUserContainer>
            <H2>{lang.inviteExistingUser}</H2>
            <InviteExistingUserDropdown data={potentialUsers} selected={existingUser.email} select={onFindUser} />
            <MaybeRenderExistingError />
            {
                userInvited.existing && (<SuccesMessages text={lang.userInvited} />)
            }
            <ControllerButtons>
                <Button text={lang.cancel} onClick={() => setPopup(null)} priority="secondary" />
                <Button text={lang.inviteExistingUser} onClick={submitPotentialUsers} priority="tertiary" />
            </ControllerButtons>
        </InviteNewUserContainer>
    );
};
