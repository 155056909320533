import React, { useEffect, useContext, useState } from 'react';

interface ScrollContextType {
    scrollToId: string | null;
    setScrollToId: (scrollToId: string | null) => void;
    containerRef: React.RefObject<HTMLDivElement>;
    childRef: React.RefObject<HTMLDivElement>;
}

export const ScrollContext = React.createContext<ScrollContextType>({
    scrollToId: null,
    setScrollToId: () => {},
    containerRef: { current: null },
    childRef: { current: null },
});

const useScrollToElement = () => {
    const { containerRef, childRef, setScrollToId } = useContext(ScrollContext);
    const [hasScrolled, setHasScrolled] = useState(false);

    const position = childRef.current?.getBoundingClientRect();

    useEffect(() => {
        if (position && position.x !== 0 && position.y !== 0 && !hasScrolled) {
            const node = containerRef.current;
            const container = containerRef.current?.getBoundingClientRect();

            if (node && node instanceof Element && container) {
                const scrollTimeout = setTimeout(() => {
                    setHasScrolled(true);

                    const scrollTop = position.top - (container.height / 2) + (position.height / 2);
                    const scrollLeft = position.left - (container.width / 2) + (position.width / 2);

                    node.scrollTo({
                        top: scrollTop,
                        left: scrollLeft,
                        behavior: 'smooth',
                    });
                }, 100); // Little delay to make sure the element is rendered before scrolling

                return () => clearTimeout(scrollTimeout);
            }
        }
    }, [containerRef, hasScrolled, position]);

    return { containerRef, childRef, setScrollToId };
};

export default useScrollToElement;

export const useScrollChild = () => {
    const { childRef, scrollToId } = useContext(ScrollContext);
    return { childRef, scrollToId };
};