
import Button from "components/Button/Button";
import InputField, { MultiSelectField, SelectField } from "containers/InputField/InputField";
import getLanguageObject from "helpers/language";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LoadValidatedProfiles, loadValidatedProfiles } from "store/allocator/allocator.actions";
import { getStringFromCurrentLanguage } from "store/language/language.actions";
import { resultChangeResultDetails, ResultChangeResultDetails, resultsGetAllTags } from "store/results/results.actions";
import ReduxStore from "store/store.type";
import { Organisation } from "types/Organisation";
import styled from "styled-components";
import { Language } from "types/MultiLingualString";
import { ValidatedProfile, Result } from "types/Result";
import { getLevelIndex } from "utils/levels";
import { talentPathNames, transformTalentPathKey } from "utils/talentPaths";

interface EditJobFormProps {
    validatedProfiles: ValidatedProfile[];
    loadValidatedProfiles: LoadValidatedProfiles;
    resultChangeResultDetails: ResultChangeResultDetails;
    job: Result;
    currentOrganisation?: Organisation;
    currentLanguage: Language;
    externalHandler: (id: string, state: false) => void;
}

const EditJobForm: React.FC<EditJobFormProps> = ({ resultChangeResultDetails, validatedProfiles, loadValidatedProfiles, job, currentOrganisation, currentLanguage, externalHandler }) => {
    const lang = getLanguageObject(currentLanguage);
    const [updatedJob, setUpdatedJob] = useState<Result>(job)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        loadValidatedProfiles(true);
    }, [loadValidatedProfiles])

    useEffect(() => {
        setUpdatedJob({
            ...job,
            type: job.validatedProfile.talentPath.key,
            validatedProfile: {
                ...job.validatedProfile,
                id: job.validatedProfileId,
            }
        })
    }, [job])

    const onChange = (key: keyof Result, value: string) => {
        if (key === 'type') {
            const oldLevel = updatedJob.validatedProfile.styrLevel;
            const newValidatedProfiles = validatedProfiles?.filter((x) => x.talentPath.key === value)
            const newProfile = newValidatedProfiles?.find((x) => x.styrLevel.level === oldLevel.level);

            setUpdatedJob({
                ...updatedJob,
                [key]: value,
                validatedProfile: {
                    ...updatedJob.validatedProfile,
                    ...newProfile
                }
            })
            return;
        }
        setUpdatedJob({
            ...updatedJob,
            [key]: value
        })
    }

    const close = () => {
        externalHandler("", false);
    }

    const save = () => {
        if (job.talentPathKey !== updatedJob.type && job.validatedProfile.id === updatedJob.validatedProfile.id) {
            setErrorMessage(lang.selectLevelAfterTalentpathChange);
            return;
        }

        setErrorMessage("");

        resultChangeResultDetails(
            updatedJob.id,
            updatedJob.name,
            updatedJob.organisationUnitId,
            updatedJob.unitId,
            updatedJob.validatedProfile.id,
            updatedJob.tags?.map((x) => x.id),
        )
        close();
    }

    const getAllTags = () => {
        return resultsGetAllTags(currentLanguage)
    }

    if (!currentOrganisation) return (<div />)


    const units = currentOrganisation.organisationUnits.find(orgUnit => orgUnit.id === updatedJob.organisationUnitId)?.units || currentOrganisation?.organisationUnits[0].units || [];
    const filteredValidatedProfiles = validatedProfiles?.filter((x) => x.talentPath.key === updatedJob.type) || [];

    return (
        <Container>
            <InputField
                label={lang.name}
                type="text"
                value={updatedJob.name}
                onChange={e => {
                    onChange('name', e.target.value);
                }}
                errorMessage={null}
                fullWidth
            />
            <SelectField
                fullWidth
                label={lang.organisation}
                value={updatedJob.organisationUnitId}
                onChange={e => {
                    onChange('organisationUnitId', e.target.value);
                }}
                options={
                    <>
                        <option>{lang.selectOrganisationUnit}</option>
                        {currentOrganisation.organisationUnits.map((e, index) => (
                            <option key={`${e.id} + ${index}`} value={e.id}>{getStringFromCurrentLanguage(e.name, currentLanguage)}</option>
                        ))}
                    </>
                }
            />
            <SelectField
                fullWidth
                label={lang.unit}
                onChange={e => {
                    onChange('unitId', e.target.value);
                }}
                options={
                    <>
                        <option>{lang.selectUnit}</option>
                        {units.map((e, index) => (
                            <option key={`${e.id} + ${index}`} value={e.id}>{getStringFromCurrentLanguage(e.name, currentLanguage).replace(/(<([^>]+)>)/ig, '')}</option>
                        ))}
                    </>
                }
                value={updatedJob.unitId}
                disabled={!updatedJob.organisationUnitId}
            />
            <SelectField
                fullWidth
                label={lang.tagsHeader}
                onChange={e => {
                    setErrorMessage("");
                    const newUpdatedJob = { ...updatedJob, tags: [{
                        id: e.target.value,
                        name: getAllTags().find((x) => x.id === e.target.value)?.name || ""
                    }] }
                    setUpdatedJob(newUpdatedJob)
                }}
                value={updatedJob.tags?.[0]?.id || ""}
                options={
                    <>
                        {getAllTags().map((e) => (
                            <option
                                key={e.id}
                                value={e.id}
                            >{e.name}</option>
                        ))}
                    </>
                }
            />
            <SelectField
                fullWidth
                label={lang.talentpath}
                onChange={e => {
                    setErrorMessage("");
                    onChange('type', e.target.value)
                }}
                options={
                    <>
                        <option>{lang.selectTalentPath}</option>
                        {talentPathNames.map((e, index) => (
                            <option key={`${e.key} + ${index}`} value={e.key}>{transformTalentPathKey(e.key, currentLanguage)}</option>
                        ))}
                    </>
                }
                value={updatedJob.type}
                disabled={!updatedJob.organisationUnitId}
            />
            <SelectField
                fullWidth
                label={lang.level}
                onChange={e => {
                    setErrorMessage("");
                    setUpdatedJob({ ...updatedJob, validatedProfile: { ...updatedJob.validatedProfile, id: e.target.value } })
                }}
                options={
                    <>
                        <option value={0}>{lang.selectLevel}</option>
                        {filteredValidatedProfiles.sort((a, b) => getLevelIndex(a.styrLevel.level.toLowerCase()) - getLevelIndex(b.styrLevel.level.toLowerCase())).map((e) => (
                            <option key={e.id} value={e.id}>{e.styrLevel.level}</option>
                        ))}
                    </>

                }
                value={updatedJob.validatedProfile.id}
                disabled={!updatedJob.type}
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <Row>
                <Button text={lang.cancel} onClick={close} priority="secondary" size="small" />
                <Button text={lang.save} onClick={save} priority="tertiary" />
            </Row>
        </Container>
    )
}

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    validatedProfiles: state.allocator.validatedProfiles || [],
});
const mapDispatchToProps = { loadValidatedProfiles, resultChangeResultDetails };
const ConnectedEditJobForm = connect(mapStateToProps, mapDispatchToProps)(EditJobForm);

export default ConnectedEditJobForm;

export const Container = styled.div`
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    overflow: auto;
    max-height: 450px;
`

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: space-between;
    grid-gap: 10px;
    width: 100%;
`
const ErrorMessage = styled.p`
    color: black;
    font-size: 1em;
    font-weight: 600;
    margin: 0;
    padding: 0;
    width: 100%;
`
