import { convertUserLang, isLanguage } from 'types/MultiLingualString';
import LanguageActions from './language.constants';
import LanguageState, { LanguageDispatches } from './language.types';

const getUserLang = () => {
    const userLang = navigator.language;
    const convertedUserLang = convertUserLang(userLang);
    return isLanguage(convertedUserLang) ? convertedUserLang : 'en-EN';
}

export const initialState: LanguageState = {
    errors: [],
    //@ts-ignore
    currentLanguage: getUserLang(),
    remoteLanguages: []
};

const languageReducer = (
    state: LanguageState = initialState,
    action: LanguageDispatches
) => {
    switch (action.type) {
        case LanguageActions.AddError:
            return {
                ...state,
                errors: [...state.errors, action.payload.error],
            };
        case LanguageActions.SetCurrentLanguage:
            return {
                ...state,
                currentLanguage: action.payload.currentLanguage,
            };
        case LanguageActions.UpdateRemoteLanguages:
            return {
                ...state,
                remoteLanguages: action.payload.data
            }
        default:
            return state;
    }
};

export default languageReducer;
