import { emptyMultiLingualString } from 'types/MultiLingualString';
import QuestionNode from 'types/QuestionNode';


const fallback: QuestionNode[] = [
    {
        id: "869",
        title: "",
        question: emptyMultiLingualString,
        answers: [
            {
                id: "0",
                text: emptyMultiLingualString,
                nextQuestionId: "869",
                info: {
                    title: emptyMultiLingualString,
                    text: emptyMultiLingualString,
                    icon: 'Administrative',
                },
                prefix: {
                    color: 'Green',
                    text: '',
                },
            },
        ],
        description: emptyMultiLingualString,
    },
];

export default fallback;
