import { OrganisationUnit } from 'types/Organisation';

export const moveArray = (arr: any[], from: number, to: number) => {
    const clone = [...arr];
    Array.prototype.splice.call(clone, to, 0,
      Array.prototype.splice.call(clone, from, 1)[0]
    );
    return clone.map((item, index) => {
        return {
            ...item,
            sortOrder: index
        }
    });
};

export const getOrganisationUnitAndUnitWithOrderByIndex = (organisationUnits: OrganisationUnit[]) => {
    const result = organisationUnits.map((organisationUnit, index) => ({
        ...organisationUnit,
        units: organisationUnit.units.map((unit, dIndex) => ({
            ...unit,
            sortOrder: dIndex
        })),
        sortOrder: index
    }));
    return result;
}