import { useState, useEffect } from 'react';
import { Organisation } from 'types/Organisation';
import {
    postFamilyMatrixImage,
    getFamilyMatrixImageById,
    getFamilyMatrixImages,
} from 'utils/getFamilyMatrixImages';
import { DownloadTypes } from './MatrixBar.types';
import { convertMatrixToFile } from 'helpers/convertMatrixToFile';
import { Family } from 'types/Result';
import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';

export const useManageMatrixImage = ({
    currentOrganisation,
    ref,
    jobfamily,
}: ManageMatrixImageProps) => {
    const [loadingFamilyMatrixImages, setLoadingFamilyMatrixImages] = useState<
        boolean
    >(false);
    const [familyMatrixImages, setFamilyMatrixImages] = useState<
        FamilyMatrixImagesStateProps
    >({
        imageVersions: [],
        imagePopupState: false,
        uploadDetails: {
            description: '',
        },
    });
    const [printing, setPrinting] = useState<boolean>(false);

    const handleFamilyMatrixImagesPopup = () =>
        setFamilyMatrixImages(prev => ({
            ...prev,
            imagePopupState: !prev.imagePopupState,
        }));

    const handleCreateNewVersion = async () => {
        try {
            setLoadingFamilyMatrixImages(true);
            setPrinting(true);

            if (!jobfamily) return;
            const response = await convertMatrixToFile(
                ref,
                DownloadTypes.Image,
                false
            );
            if (response !== undefined && response !== null) {
                const { fileName, blob } = response;
                const file = {
                    fileName,
                    blob,
                    description: familyMatrixImages.uploadDetails.description,
                };
                await postFamilyMatrixImage(jobfamily?.id, file);

                await fetchExistingImageVersions();

                setFamilyMatrixImages(prev => ({
                    ...prev,
                    uploadDetails: {
                        description: '',
                    },
                }));
            }
        } catch (error) {
            console.error('Error creating new image version:', error);
        } finally {
            setLoadingFamilyMatrixImages(false);
            setPrinting(false);
        }
    };

    const handleImageDescription = (description: string) => {
        setFamilyMatrixImages(prev => ({
            ...prev,
            uploadDetails: {
                description,
            },
        }));
    };

    const handleDownloadMatrix = async (type: DownloadTypes, matrixView?: MatrixViews) => {
        setPrinting(true);
        try {
            await convertMatrixToFile(ref, type, true, matrixView);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        } finally {
            setPrinting(false);
        }
    };

    const handleDownloadImageVersion = (image: JobMatrixImageProps) => {
        if (!jobfamily) return;
        return getFamilyMatrixImageById(jobfamily, image);
    };

    const fetchExistingImageVersions = async () => {
        if (!currentOrganisation?.id) return;
        if (!jobfamily) return;

        setLoadingFamilyMatrixImages(true);
        try {
            const response = await getFamilyMatrixImages(jobfamily.id);
            if (response !== undefined && response !== null) {
                setFamilyMatrixImages(prev => ({
                    ...prev,
                    imageVersions: response,
                }));
            }
        } catch (error) {
            console.error('Error getting image versions:', error);
        } finally {
            setLoadingFamilyMatrixImages(false);
        }
    };

    useEffect(() => {
        fetchExistingImageVersions();
    }, [currentOrganisation?.id, printing]);

    return {
        familyMatrixImages,
        printing,
        loadingFamilyMatrixImages,
        handleDownloadMatrix,
        handleFamilyMatrixImagesPopup,
        handleCreateNewVersion,
        handleDownloadImageVersion,
        handleImageDescription,
    };
};

export interface JobMatrixImageProps {
    id: string;
    organisationId: string;
    jobFamilyId: string;
    version: string;
    description: string;
    createdDate: string;
}

interface ManageMatrixImageProps {
    currentOrganisation?: Organisation;
    ref: React.RefObject<HTMLDivElement>;
    jobfamily?: Family;
}

interface FamilyMatrixImagesStateProps {
    imageVersions: JobMatrixImageProps[];
    imagePopupState: boolean;
    uploadDetails: {
        description: string;
    };
}
