export const defaultLevelOptions =
{
    "levels": {
        "level_1a": {
            "problem_solving_focus": {
                "en-EN": "Process & support <br> according to rules",
                "nl-NL": "Uitvoeren & ondersteunen <br> volgens regels",
                "nl-BE": "Uitvoeren & ondersteunen <br> volgens regels",
                "fr-BE": "Exécuter & soutenir <br> selon les règles ",
                "de-DE": "Vorschriftsgemäße <br> Ausführung & Unterstützung ",
                "fr-FR": "Exécuter & soutenir <br> selon les règles ",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Starter / experienced <br> Primary education level (0+)",
                "nl-NL": "Starter / ervaren <br> Basisonderwijs (0+)",
                "nl-BE": "Geen voorkennis / opleiding nodig",
                "fr-BE": "Aucune connaissance/formation préalable n'est requise",
                "de-DE": "Erfahren oder Anfänger <br> Grundschule (0+)",
                "fr-FR": "Aucune connaissance/formation préalable n'est requise",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Performs operational and supportive activities according to a set of strict rules. There is continuous supervision on progress and results.",
                "nl-NL": "Voert operationele en ondersteunende werkzaamheden uit, volgens een set van strikte regels. Er is continue toezicht op voortgang en resultaten.",
                "nl-BE": "Voert operationele en ondersteunende werkzaamheden uit, volgens een set van strikte regels. Er is continue toezicht op voortgang en resultaten.",
                "fr-BE": "Exécute des activités opérationnelles et de soutien, selon un ensemble de règles strictes. A une supervision continue sur les progrès et les résultats.",
                "de-DE": "Führt anhand strenger Vorschriften operative und unterstützende Tätigkeiten aus. Wird ständig im Hinblick auf Fortschritt und Resultate überprüft.",
                "fr-FR": "Exécute des activités opérationnelles et de soutien, selon un ensemble de règles strictes. A une supervision continue sur les progrès et les résultats.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Per assignment ",
                "nl-NL": "Per opdracht",
                "nl-BE": "Per opdracht",
                "fr-BE": "Par mission",
                "de-DE": "Pro Auftrag",
                "fr-FR": "Par mission",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_1b": {
            "problem_solving_focus": {
                "en-EN": "Process & support <br> according to rules",
                "nl-NL": "Uitvoeren & ondersteunen <br> volgens regels",
                "nl-BE": "Uitvoeren & ondersteunen <br> volgens regels",
                "fr-BE": "Exécuter & soutenir <br> selon les règles",
                "de-DE": "Vorschriftsgemäße <br> Ausführung & Unterstützung ",
                "fr-FR": "Exécuter & soutenir <br> selon les règles",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Starter / experienced  <br> Lower Vocational level (0+)",
                "nl-NL": "Starter / ervaren <br> MBO 1 - 2 (0+)",
                "nl-BE": "Starter / ervaren <br> Basiskennis - organisatiespecifieke opleiding nodig",
                "fr-BE": "Débutant / expérimenté <br> Connaissances de base - formation spécifique à l'entreprise",
                "de-DE": "Erfahren / Anfänger <br> FOS 1 - 2 (0+)",
                "fr-FR": "Débutant / expérimenté <br> Connaissances de base - formation spécifique à l'entreprise",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Performs operational and supportive activities according to a set of rules. There is direct supervision on progress and results.",
                "nl-NL": "Voert operationele en ondersteunende werkzaamheden uit, volgens een set van regels. Er is direct toezicht op voortgang en resultaten.",
                "nl-BE": "Voert operationele en ondersteunende werkzaamheden uit, volgens een set van regels. Er is direct toezicht op voortgang en resultaten.",
                "fr-BE": "Exécute des activités opérationnelles et de soutien, selon un ensemble de règles. A une supervision directe sur les progrès et les résultats.",
                "de-DE": "Führt anhand von Vorschriften operative und unterstützende Tätigkeiten aus. Wird direkt im Hinblick auf Fortschritt und Resultate überprüft.",
                "fr-FR": "Exécute des activités opérationnelles et de soutien, selon un ensemble de règles. A une supervision directe sur les progrès et les résultats.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Per assignment ",
                "nl-NL": "Per opdracht",
                "nl-BE": "Per opdracht",
                "fr-BE": "Par mission",
                "de-DE": "Pro Auftrag",
                "fr-FR": "Par mission",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_2": {
            "problem_solving_focus": {
                "en-EN": "Process & support <br> according to rules",
                "nl-NL": "Uitvoeren & ondersteunen <br> volgens regels",
                "nl-BE": "Uitvoeren & ondersteunen <br> volgens regels",
                "fr-BE": "Exécuter & soutenir <br> selon les règles",
                "de-DE": "Vorschriftsgemäße <br> Ausführung & Unterstützung ",
                "fr-FR": "Exécuter & soutenir <br> selon les règles",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Experienced -<br> reliable & quality focus <br> Lower Vocational (4+) <br>Trainee (Intermediate Vocational level)",
                "nl-NL": "Ervaren - <br> betrouwbaar & kwaliteitsfocus <br> MBO 1-2 (4+) <br>Trainee BBL3 (nog geen diploma)",
                "nl-BE": "Ervaren - <br> betrouwbaar & kwaliteitsfocus <br> Vakkennis noodzakelijk",
                "fr-BE": "Expérimenté - <br> fiabilité et qualité <br> Connaissances professionnelles",
                "de-DE": "Erfahren - <br> Zuverlässigkeit und Qualitätsdenken <br> FOS 1-2 (4+) <br>Trainee (noch kein Diplom)",
                "fr-FR": "Expérimenté - <br> fiabilité et qualité <br> Connaissances professionnelles",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Performs operational and supportive activities according to a set of rules. Understands the importance of working according to a set of rules and demonstrates this in reliability and quality. Progress and results are checked regularly.",
                "nl-NL": "Voert operationele en ondersteunende werkzaamheden uit, volgens een set van regels. Begrijpt het belang van werken volgens regels en laat dit zien in betrouwbaarheid en kwaliteitsfocus. Voortgang en resultaten worden op regelmatige basis gecheckt.",
                "nl-BE": "Voert operationele en ondersteunende werkzaamheden uit, volgens een set van regels. Begrijpt het belang van werken volgens regels en laat dit zien in betrouwbaarheid en kwaliteitsfocus. Voortgang en resultaten worden op regelmatige basis gecontroleerd.",
                "fr-BE": "Exécute des activités opérationnelles et de soutien, selon un ensemble de règles. Comprend l’importance de travailler selon les règles et le démontre par la fiabilité et une concentration sur la qualité.",
                "de-DE": "Führt anhand von Vorschriften operative und unterstützende Tätigkeiten aus. Weiß, wie wichtig vorschriftsmäßiges Arbeiten ist, und beweist das durch Zuverlässigkeit und Qualitätsdenken.",
                "fr-FR": "Exécute des activités opérationnelles et de soutien, selon un ensemble de règles. Comprend l’importance de travailler selon les règles et le démontre par la fiabilité et une concentration sur la qualité.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Per assignment ",
                "nl-NL": "Per opdracht",
                "nl-BE": "Per opdracht",
                "fr-BE": "Par mission",
                "de-DE": "Pro Auftrag",
                "fr-FR": "Par mission",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_3": {
            "problem_solving_focus": {
                "en-EN": "Process, support & organise <br> according to procedures ",
                "nl-NL": "Uitvoeren, ondersteunen & organiseren <br> volgens procedures",
                "nl-BE": "Uitvoeren, ondersteunen & organiseren <br> volgens procedures",
                "fr-BE": "Exécuter, soutenir & organiser <br> selon les procédures",
                "de-DE": "Ausführung, Unterstützung & Organisation <br> gemäß Verfahren",
                "fr-FR": "Exécuter, soutenir & organiser <br> selon les procédures",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Experienced <br> Lower Vocational level (8+) <br> Starter <br> Intermediate Vocational level (0+)",
                "nl-NL": "Ervaren <br> MBO 1-2 (8+) <br> Starter <br> MBO 3 (0+)",
                "nl-BE": "Ervaren <br> Vakkennis noodzakelijk (8+ jaar ervaring) <br> Starter <br> Technische vakkennis",
                "fr-BE": "Expérimenté <br> Connaissances professionelles (8+ ans d'expérience) <br> Débutante <br> Connaissances techniques",
                "de-DE": "Erfahren <br>  FOS 1-2 (8+) <br> Anfänger <br> FOS 3 (0+)",
                "fr-FR": "Expérimenté <br> Connaissances professionelles (8+ ans d'expérience) <br> Débutante <br> Connaissances techniques",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Performs operational, supportive and organising activities, according to procedures.",
                "nl-NL": "Voert operationele, ondersteunende en organiserende werkzaamheden uit, volgens procedures.",
                "nl-BE": "Voert operationele, ondersteunende en organiserende werkzaamheden uit, volgens procedures.",
                "fr-BE": "Exécute des activités opérationnelles, de soutien et d’organisation, selon les procédures.",
                "de-DE": "Führt operative, unterstützende und organisatorische Tätigkeiten gemäß den Verfahren aus.",
                "fr-FR": "Exécute des activités opérationnelles, de soutien et d’organisation, selon les procédures.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Per assignment / daily ",
                "nl-NL": "Per opdracht / dagelijks",
                "nl-BE": "Per opdracht / dagelijks",
                "fr-BE": "Par mission / quotidien",
                "de-DE": "Pro Auftrag / täglich ",
                "fr-FR": "Par mission / quotidien",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_4": {
            "problem_solving_focus": {
                "en-EN": "Process, support & organise <br> according to procedures ",
                "nl-NL": "Uitvoeren, ondersteunen & organiseren <br> volgens procedures",
                "nl-BE": "Uitvoeren, ondersteunen & organiseren <br> volgens procedures",
                "fr-BE": "Exécuter, soutenir & organiser <br> selon les procédures",
                "de-DE": "Ausführung, Unterstützung & Organisation <br> gemäß Verfahren",
                "fr-FR": "Exécuter, soutenir & organiser <br> selon les procédures",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Experienced <br> Intermediate Vocational level (4+) <br> Starter <br>Advanced Vocational level (0+)",
                "nl-NL": "Ervaren <br> MBO 3 (4+) <br> Starter <br> MBO 4 (0+)",
                "nl-BE": "Ervaren <br> Technische vakkennis (4+ jaar ervaring)",
                "fr-BE": "Expérimenté <br> Connaissances techniques (4+ ans d'expérience)",
                "de-DE": "Erfahren <br>  FOS 3 (4+) <br> Anfänger <br> FOS 4 (0+)",
                "fr-FR": "Expérimenté <br> Connaissances techniques (4+ ans d'expérience)",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Performs operational, supporting and organising activities according to established procedures. Chooses the proper alternative within bandwidth of procedures, in case of deviating circumstances.",
                "nl-NL": "Voert operationele, ondersteunende en organiserende werkzaamheden uit, volgens procedures. Kiest het juiste alternatief binnen de bandbreedte van de procedure, bij afwijkende omstandigheden.",
                "nl-BE": "Voert operationele, ondersteunende en organiserende werkzaamheden uit, volgens procedures. Kiest bij afwijkende omstandigheden het juiste alternatief binnen de bandbreedte van de procedure.",
                "fr-BE": "Exécute des activités opérationnelles, de soutien et d’organisation, selon les procédures. Choisit la bonne alternative dans les limites de la procédure, en cas de circonstances différentes.",
                "de-DE": "Führt operative, unterstützende und organisatorische Tätigkeiten gemäß den Verfahren aus. Wählt die richtige Alternative innerhalb der Rahmenbedingungen des Verfahrens unter abweichenden Bedingungen aus.",
                "fr-FR": "Exécute des activités opérationnelles, de soutien et d’organisation, selon les procédures. Choisit la bonne alternative dans les limites de la procédure, en cas de circonstances différentes.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Daily ",
                "nl-NL": "Dagelijks",
                "nl-BE": "Dagelijks",
                "fr-BE": "Quotidien",
                "de-DE": "Täglich",
                "fr-FR": "Quotidien",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_5": {
            "problem_solving_focus": {
                "en-EN": "Process, support & organise <br> according to procedures ",
                "nl-NL": "Uitvoeren, ondersteunen & organiseren <br>volgens procedures",
                "nl-BE": "Uitvoeren, ondersteunen & organiseren <br>volgens procedures",
                "fr-BE": "Exécuter, soutenir & organiser <br> selon les procédures",
                "de-DE": "Ausführung, Unterstützung & Organisation <br> gemäß Verfahren",
                "fr-FR": "Exécuter, soutenir & organiser <br> selon les procédures",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Allrounder <br> (Wikipedia of the team)<br> Intermediate Vocational level (8+) <br> Advanced Vocational level (4+)",
                "nl-NL": "Allrounder <br> (Wikipedia of the team) <br>MBO 3 (8+)  <br> MBO 4 (4+)",
                "nl-BE": "Allrounder <br> Technische vakkennis (8+ jaar ervaring - \"Wikipedia\")",
                "fr-BE": "Polyvalent <br> (Wikipédia de l’équipe) <br> Connaissances techniques (8+ ans d'expérience - \"Wikipedia\")",
                "de-DE": "Allrounder <br> (Wikipedia des Teams) <br>FOS 3 (8+)  <br> FOS 4 (4+)",
                "fr-FR": "Polyvalent <br> (Wikipédia de l’équipe) <br> Connaissances techniques (8+ ans d'expérience - \"Wikipedia\")",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Performs less well-defined practical activities that require the application of existing knowledge. Understands the intended result of procedures and knows when to act outside the bandwidth of the procedure. Act as the 'Wikipedia' for the team.",
                "nl-NL": "Voert minder duidelijk omschreven, praktische werkzaamheden uit, waarvoor toepassing van bestaande kennis nodig is. Begrijpt de bedoeling van procedures en weet wanneer buiten de bandbreedte van de procedure moet worden gehandeld. Is de Wikipedia van het team.",
                "nl-BE": "Voert minder duidelijk omschreven, praktische werkzaamheden uit, waarvoor toepassing van bestaande kennis nodig is. Begrijpt de bedoeling van procedures en weet wanneer buiten de bandbreedte van de procedure moet worden gehandeld. Is de Wikipedia van het team.",
                "fr-BE": "Exécute des activités pratiques moins bien décrites, qui nécessitent la mise en application des connaissances existantes. Comprend l’objectif des procédures et sait à quel moment il convient d'agir en dehors des limites de la procédure. Est le/la Wikipédia de l’équipe.",
                "de-DE": "Führt suboptimal beschriebene, praktische Tätigkeiten aus, die die Anwendung vorhandener Kenntnisse nötig machen. Kennt die Ziele von Verfahren und weiß, wann ein Handeln jenseits der Rahmenbedingungen der Verfahren notwendig ist. Ist die Wikipedia des Teams.",
                "fr-FR": "Exécute des activités pratiques moins bien décrites, qui nécessitent la mise en application des connaissances existantes. Comprend l’objectif des procédures et sait à quel moment il convient d'agir en dehors des limites de la procédure. Est le/la Wikipédia de l’équipe.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Weekly / monthly ",
                "nl-NL": "Wekelijks / maandelijks",
                "nl-BE": "Wekelijks / maandelijks",
                "fr-BE": "Hebdomadaire / mensuel",
                "de-DE": "Wöchentlich / monatlich",
                "fr-FR": "Hebdomadaire / mensuel",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_6": {
            "problem_solving_focus": {
                "en-EN": "Optimise, set up & realise",
                "nl-NL": "Optimaliseren, inrichten <br> & realiseren",
                "nl-BE": "Optimaliseren, inrichten <br> & realiseren",
                "fr-BE": "Optimiser, aménager & réaliser",
                "de-DE": "Optimierung, Einrichtung <br> & Umsetzung ",
                "fr-FR": "Optimiser, aménager & réaliser",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Specialist <br>Advanced Vocational level(8+) <br> Starter <br> University of Applied  Sciences level / <br> Research University level (0+)",
                "nl-NL": "Specialist <br> MBO 4 (8+)  <br> Starter <br> HBO / WO (0+)",
                "nl-BE": "Specialist <br> Technische vakkennis (8+ jaar ervaring)  <br> Starter <br> Bachelor / Master",
                "fr-BE": "Specialiste <br> Connaissances techniques (8+ ans d'expérience) <br> Débutant <br> Bachelier / Master",
                "de-DE": "Fachkraft <br> FOS 4 (8+)  <br> Anfänger <br> FH (Bachelor)/HS (0+)",
                "fr-FR": "Specialiste <br> Connaissances techniques (8+ ans d'expérience) <br> Débutant <br> Bachelier / Master",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Resolves less well-defined, practical issues that require overview, analysis and application of existing knowledge are necessary. Can resolve problems that are outside the bandwidth of the procedure (pragmatic creativity).",
                "nl-NL": "Lost minder duidelijk omschreven, praktische vraagstukken op, waarvoor overzicht, analyse en toepassing van bestaande kennis nodig zijn. Kan problemen oplossen die buiten de bandbreedte van de procedure vallen (pragmatische creativiteit).",
                "nl-BE": "Lost minder duidelijk omschreven, praktische vraagstukken op, waarvoor overzicht, analyse en toepassing van bestaande kennis nodig zijn. Kan problemen oplossen die buiten de bandbreedte van de procedure vallen (pragmatische creativiteit).",
                "fr-BE": "Résout des questions pratiques moins bien décrites, qui nécessitent vue d'ensemble, analyse et mise en application des connaissances existantes. Peut résoudre des problèmes qui se situent en dehors des limites de la procédure (créativité pragmatique).",
                "de-DE": "Löst suboptimal beschriebene, praktische Probleme, die Übersicht, Analyse und Anwendung vorhandener Kenntnisse nötig machen. Kann Probleme lösen, die über die Rahmenbedingungen des Verfahrens hinausgehen (pragmatische Kreativität).",
                "fr-FR": "Résout des questions pratiques moins bien décrites, qui nécessitent vue d'ensemble, analyse et mise en application des connaissances existantes. Peut résoudre des problèmes qui se situent en dehors des limites de la procédure (créativité pragmatique).",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Monthly / quarterly",
                "nl-NL": "Maandelijks / per kwartaal",
                "nl-BE": "Maandelijks / per kwartaal",
                "fr-BE": "Mensuel / trimestriel",
                "de-DE": "Monatlich/pro Quartal",
                "fr-FR": "Mensuel / trimestriel",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_7": {
            "problem_solving_focus": {
                "en-EN": "Optimise, set up & realise",
                "nl-NL": "Optimaliseren, inrichten <br> & realiseren",
                "nl-BE": "Optimaliseren, inrichten <br> & realiseren",
                "fr-BE": "Optimiser, aménager & réaliser",
                "de-DE": "Optimierung, Einrichtung <br> & Umsetzung ",
                "fr-FR": "Optimiser, aménager & réaliser",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Professional <br> University of Applied Sciences level / <br> Research University level (4+)",
                "nl-NL": "Professional <br> HBO / WO (4+) ",
                "nl-BE": "Professional <br> Bachelor / Master (4+ jaar ervaring) ",
                "fr-BE": "Professionnel <br> Bachelier / Master (4+ ans d'expérience)",
                "de-DE": "Profi <br> FH (Bachelor)/HS (4+)",
                "fr-FR": "Professionnel <br> Bachelier / Master (4+ ans d'expérience)",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Resolves (unique) less well-defined, practical issues, that require specialised knowledge, analysis and overview. Oversees how decision-making and actions impact other job positions and departments. Is capable to set up structures and systems.",
                "nl-NL": "Lost (unieke) minder duidelijk omschreven, praktische vraagstukken op, waarvoor specialistische kennis, analyse en overzicht nodig zijn. Ziet gevolgen van de besluitvorming en acties voor andere functies en afdelingen. Is in staat om structuren en systemen op te zetten.",
                "nl-BE": "Lost (unieke) minder duidelijk omschreven, praktische vraagstukken op, waarvoor specialistische kennis, analyse en overzicht nodig zijn. Ziet gevolgen van de besluitvorming en acties voor andere functies en afdelingen. Is in staat om structuren en systemen op te zetten.",
                "fr-BE": "Résout des questions pratiques (uniques) moins bien décrites, qui nécessitent connaissances spécialisées, analyse et vue d’ensemble.  Voit les conséquences de la prise de décisions et des actions pour d'autres fonctions et départements. Est en mesure d’élaborer des structures et systèmes.",
                "de-DE": "Löst (individuelle) suboptimal beschriebene, praktische Probleme, die Fachwissen, Analyse und Übersicht benötigen.  Erkennt die Folgen der getroffenen Entscheidungen und Maßnahmen für andere Positionen und Abteilungen. Ist in der Lage, Strukturen und Systeme einzurichten.",
                "fr-FR": "Résout des questions pratiques (uniques) moins bien décrites, qui nécessitent connaissances spécialisées, analyse et vue d’ensemble.  Voit les conséquences de la prise de décisions et des actions pour d'autres fonctions et départements. Est en mesure d’élaborer des structures et systèmes.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Quarterly / yearly",
                "nl-NL": "Per kwartaal / jaarlijks",
                "nl-BE": "Per kwartaal / jaarlijks",
                "fr-BE": "Trimestriel / annuel",
                "de-DE": "Pro Quartal/jährlich",
                "fr-FR": "Trimestriel / annuel",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_8": {
            "problem_solving_focus": {
                "en-EN": "Optimise, set up & realise",
                "nl-NL": "Optimaliseren, inrichten <br> & realiseren",
                "nl-BE": "Optimaliseren, inrichten <br> & realiseren",
                "fr-BE": "Optimiser, aménager & réaliser",
                "de-DE": "Optimierung, Einrichtung <br> & Umsetzung",
                "fr-FR": "Optimiser, aménager & réaliser",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Expert <br> University of Applied Sciences level / <br>  Research University level (8+)",
                "nl-NL": "Expert <br> HBO / WO (8+)",
                "nl-BE": "Expert <br> Bachelor / Master (8+ jaar ervaring)",
                "fr-BE": "Expert <br> Bachelier / Master (8+ ans d'expérience)",
                "de-DE": "Experte <br> FH (Bachelor) / HS (8+)",
                "fr-FR": "Expert <br> Bachelier / Master (8+ ans d'expérience)",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Resolves a complex whole of practical issues that require  knowledge, analysis and overview. For instance: policy, processes, project plan, business case and/or implementation. Provides Advice on potential alternatives. Operates independently. ",
                "nl-NL": "Lost een complex geheel van praktische vraagstukken op, waarvoor expert kennis, analyse en overzicht nodig zijn, b.v. beleid, processen, projectplan, business case, implementatie. Adviseert over alternatieven. Opereert zelfstandig.",
                "nl-BE": "Lost een complex geheel van praktische vraagstukken op, waarvoor expert kennis, analyse en overzicht nodig zijn, b.v. beleid, processen, projectplan, business case, implementatie. Adviseert over alternatieven. Opereert zelfstandig.",
                "fr-BE": "Résout un ensemble complexe de questions pratiques, qui nécessite analyse et expertise professionnelle, p. ex. politique, processus, plan de projet, étude d’opportunité, mise en œuvre. Conseille sur des alternatives. Opère de manière indépendante.",
                "de-DE": "Löst komplexe, praktische Probleme, für die Analyse und fachliche Kompetenz benötigt werden, z. B. Richtlinien, Prozesse, Projektplan, Businesscase, Implementierung. Berät zu Alternativen. Handelt eigenständig.",
                "fr-FR": "Résout un ensemble complexe de questions pratiques, qui nécessite analyse et expertise professionnelle, p. ex. politique, processus, plan de projet, étude d’opportunité, mise en œuvre. Conseille sur des alternatives. Opère de manière indépendante.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "1 - 3 years",
                "nl-NL": "1 - 3 jaar",
                "nl-BE": "1 - 3 jaar",
                "fr-BE": "1 à 3 ans",
                "de-DE": "1 - 3 Jahre",
                "fr-FR": "1 à 3 ans",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_9": {
            "problem_solving_focus": {
                "en-EN": "Innovate, strategic translation <br> & change ",
                "nl-NL": "Innoveren, strategische vertaalslag <br> & veranderen",
                "nl-BE": "Innoveren, strategische vertaalslag <br> & veranderen",
                "fr-BE": "Innovation, traduction stratégique <br> & changement",
                "de-DE": "Innovation, strategische Umsetzung <br> & Veränderung",
                "fr-FR": "Innovation, traduction stratégique <br> & changement",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Context thinker -<br> developer with helicopter view <br> University of Applied Sciences level / <br> Research University level (10+)  ",
                "nl-NL": "Context denker -<br> ontwikkelaar met helicopterview <br> HBO / WO (10+)",
                "nl-BE": "Context denker -<br> ontwikkelaar met helicopterview <br> Bachelor / Master (10+ jaar ervaring))",
                "fr-BE": "Penseur contextuel - <br> développeur avec vue panoramique <br> Bachelier / Master (10+ ans d'expérience))",
                "de-DE": "Kontextdenker -<br> entwickler mit Helikopterperspektive <br> FH (Bachelor)/HS (10+)",
                "fr-FR": "Penseur contextuel - <br> développeur avec vue panoramique <br> Bachelier / Master (10+ ans d'expérience))",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Resolves complex, abstract issues that require a helicopter view and contextual thinking. or <br> Resolves complex issues that require a helicopter view, in-depth expertise and a solid understanding of the business (context).",
                "nl-NL": "Lost complexe, abstracte vraagstukken op, waarvoor helicopterview en context denken nodig zijn, of  <br> Lost complexe vraagstukken op waarvoor helicopterview, diepgaande expertise en goed begrip van de business (context) nodig zijn. ",
                "nl-BE": "Lost complexe, abstracte vraagstukken op, waarvoor helicopterview en context denken nodig zijn, of  <br> Lost complexe vraagstukken op waarvoor helicopterview, diepgaande expertise en goed begrip van de business (context) nodig zijn. ",
                "fr-BE": "Résout des questions complexes et abstraites qui nécessitent une vue d'hélicoptère et une réflexion contextuelle. ou <br>Résout des questions complexes qui nécessitent une vue d'hélicoptère, une expertise approfondie et une solide compréhension de l'entreprise (contexte).",
                "de-DE": "Löst komplexe, abstrakte Probleme, die eine Helikopterperspektive und kontextbezogenes Denken erfordern. oder <br> Löst komplexe Probleme, die eine Helikopterperspektive, tiefgreifendes Fachwissen und ein solides Verständnis des Unternehmens (Kontext) erfordern.",
                "fr-FR": "Résout des questions complexes et abstraites qui nécessitent une vue d'hélicoptère et une réflexion contextuelle. ou <br>Résout des questions complexes qui nécessitent une vue d'hélicoptère, une expertise approfondie et une solide compréhension de l'entreprise (contexte).",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "1 - 3 years",
                "nl-NL": "1 - 3 jaar",
                "nl-BE": "1 - 3 jaar",
                "de-DE": "1 - 3 Jahre",
                "fr-FR": "1 à 3 ans",
                "fr-BE": "1 à 3 ans",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_10": {
            "problem_solving_focus": {
                "en-EN": "Innovate, strategic translation <br>& change ",
                "nl-NL": "Innoveren, strategische vertaalslag <br>& veranderen",
                "nl-BE": "Innoveren, strategische vertaalslag <br>& veranderen",
                "fr-BE": "Innovation, traduction stratégique <br>& changement",
                "de-DE": "Innovation, strategische Umsetzung <br>& Veränderung",
                "fr-FR": "Innovation, traduction stratégique <br>& changement",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Context thinker -<br>developer met vision <br>  Research University level (10+)",
                "nl-NL": "Context denker -<br> ontwikkelaar met visie <br> HBO+ / WO (10+)",
                "nl-BE": "Context denker -<br> ontwikkelaar met visie <br> Master (10+ jaar ervaring)",
                "fr-BE": "Penseur contextuel - <br> développeur avec vision <br> Master (10+ ans d'expérience)",
                "de-DE": "Kontextdenker -<br> entwickler mit einer Vision  <br> HS (10+)",
                "fr-FR": "Penseur contextuel - <br> développeur avec vision <br> Master (10+ ans d'expérience)",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Resolves complex, abstract issues that require contextual thinking and vision to address. Is an autonomous thinker and pro-actively present vision and solutions to (senior) management (push).",
                "nl-NL": "Lost complexe, abstracte vraagstukken op, waarvoor context denken en visie nodig zijn. Is een autonome denker en brengt proactief visie en oplossingen naar (senior) management (push).",
                "nl-BE": "Lost complexe, abstracte vraagstukken op, waarvoor context denken en visie nodig zijn. Is een autonome denker en brengt proactief visie en oplossingen naar (senior) management (push).",
                "fr-BE": "Résout des questions complexes, abstraites qui nécessitent une bonne compréhension du contexte commercial (du client) et une vision. Est un penseur/une penseuse autonome qui apporte une vision proactive et des solutions aux clients (stratégiques) et/ou à la direction (push).",
                "de-DE": "Löst komplexe, abstrakte Probleme, für die ein angemessenes Verständnis des Unternehmenskontextes und der Vision (des Kunden) erforderlich sind. Denkt autonom und präsentiert (strategischen) Kunden und/oder Management (Push) proaktiv Visionen und Lösungen.",
                "fr-FR": "Résout des questions complexes, abstraites qui nécessitent une bonne compréhension du contexte commercial (du client) et une vision. Est un penseur/une penseuse autonome qui apporte une vision proactive et des solutions aux clients (stratégiques) et/ou à la direction (push).",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "1 - 3 years",
                "nl-NL": "1 - 3 jaar",
                "nl-BE": "1 - 3 jaar",
                "fr-BE": "1 à 3 ans",
                "de-DE": "1 - 3 Jahre",
                "fr-FR": "1 à 3 ans",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_11": {
            "problem_solving_focus": {
                "en-EN": "Innovate, strategic translation <br>& change ",
                "nl-NL": "Innoveren, strategische vertaalslag <br>& veranderen",
                "nl-BE": "Innoveren, strategische vertaalslag <br>& veranderen",
                "fr-BE": "Innovation, traduction stratégique <br>& changement",
                "de-DE": "Innovation, strategische Umsetzung <br>& Veränderung",
                "fr-FR": "Innovation, traduction stratégique <br>& changement",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Change Driver - <br> own focus area <br> Research University level",
                "nl-NL": "Change driver - <br> eigen aandachtsgebied <br> HBO+ / WO",
                "nl-BE": "Change driver - <br> eigen expertisedomein <br> Master ",
                "fr-BE": "Pilote du changement - <br> epropre domaine d'intérêt <br> Master ",
                "de-DE": "Veränderer - <br>  im eigenen Schwerpunktbereich <br> HS",
                "fr-FR": "Pilote du changement - <br> epropre domaine d'intérêt <br> Master ",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Resolves complex, abstract issues that require contextual thinking and vision to address. Is a driving force - in a socially complex environment -  behind innovation and change in own focus area.",
                "nl-NL": "Lost complexe, abstracte vraagstukken op, waarvoor context denken en visie nodig zijn. Is een drijvende kracht  - in een sociaal complexe omgeving - achter innovatie en veranderingen in zijn of haar eigen aandachtsgebied.",
                "nl-BE": "Lost complexe, abstracte vraagstukken op, waarvoor context denken en visie nodig zijn. Is een drijvende kracht  - in een sociaal complexe omgeving - achter innovatie en veranderingen in zijn of haar eigen expertisedomein.",
                "fr-BE": "Résoudre des questions complexes, abstraits qui nécessitent une bonne réflexion et une vision contextuelles. Est un élément moteur (dans un environnement socialement complexe) derrière l’innovation ou le changement au sein du propre domaine d’intervention.",
                "de-DE": "Löst komplexe, abstrakte Probleme, für die ein angemessenes Verständnis des Unternehmenskontextes und der Vision (des Kunden) erforderlich sind. Ist – in einer sozial komplexen Umgebung – eine treibende Kraft für Innovation oder Veränderung im eigenen Schwerpunktbereich.",
                "fr-FR": "Résoudre des questions complexes, abstraits qui nécessitent une bonne réflexion et une vision contextuelles. Est un élément moteur (dans un environnement socialement complexe) derrière l’innovation ou le changement au sein du propre domaine d’intervention.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "1 - 3 years",
                "nl-NL": "1 - 3 jaar",
                "nl-BE": "1 - 3 jaar",
                "fr-BE": "1 à 3 ans",
                "de-DE": "1 - 3 Jahre",
                "fr-FR": "1 à 3 ans",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_12": {
            "problem_solving_focus": {
                "en-EN": "Innovate, strategic translation <br> & change ",
                "nl-NL": "Innoveren, strategische vertaalslag <br> & veranderen",
                "nl-BE": "Innoveren, strategische vertaalslag <br> & veranderen",
                "fr-BE": "Innovation, traduction stratégique <br> & changement",
                "de-DE": "Innovation, strategische Umsetzung <br> & Veränderung",
                "fr-FR": "Innovation, traduction stratégique <br> & changement",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Change driver -<br> organisation-wide <br> Research University level",
                "nl-NL": "Change driver - <br> organisatie breed <br> HBO+ / WO ",
                "nl-BE": "Change driver - <br> organisatie breed <br> Master ",
                "fr-BE": "Pilote du changement - <br> à l’échelle de l’organisation <br> Master ",
                "de-DE": "Veränderer - <br>  organisationsweit <br> HS",
                "fr-FR": "Pilote du changement - <br> à l’échelle de l’organisation <br> Bachelor+ / Master ",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Resolves complex, abstract issues that require contextual thinking and vision to address. Is a driving force - in a socially complex environment -  behind organisation-wide innovation.",
                "nl-NL": "Lost complexe, abstracte vraagstukken op, waarvoor context denken en visie nodig zijn. Is een drijvende kracht - in een sociaal complexe omgeving - achter veranderingen organisatie-breed.",
                "nl-BE": "Lost complexe, abstracte vraagstukken op, waarvoor context denken en visie nodig zijn. Is een drijvende kracht - in een sociaal complexe omgeving - achter veranderingen organisatie-breed.",
                "fr-BE": "Résoudre des questions complexes, abstraits qui nécessitent une bonne réflexion et une vision contextuelles. Est un élément moteur (dans un environnement socialement complexe) derrière l’innovation ou les changements à l’échelle de l’organisation.",
                "de-DE": "Löst komplexe, abstrakte Probleme, für die ein angemessenes Verständnis des Unternehmenskontextes und der Vision (des Kunden) erforderlich sind. Ist – in einer sozial komplexen Umgebung – eine treibende Kraft für organisationsweite Innovation oder Veränderung.",
                "fr-FR": "Résoudre des questions complexes, abstraits qui nécessitent une bonne réflexion et une vision contextuelles. Est un élément moteur (dans un environnement socialement complexe) derrière l’innovation ou les changements à l’échelle de l’organisation.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "1 - 3 years",
                "nl-NL": "1 - 3 jaar",
                "nl-BE": "1 - 3 jaar",
                "fr-BE": "1 à 3 ans",
                "de-DE": "1 - 3 Jahre",
                "fr-FR": "1 à 3 ans",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_p1": {
            "problem_solving_focus": {
                "en-EN": "Strategic direction <br>& integration",
                "nl-NL": "Strategische richting <br>& integratie",
                "nl-BE": "Strategische richting <br>& integratie",
                "fr-BE": "Orientation stratégique <br>& intégration",
                "de-DE": "Strategische Ausrichtung <br> & Integration",
                "fr-FR": "Orientation stratégique <br>& intégration",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Strategic direction & implementation",
                "nl-NL": "Strategische richting & implementatie",
                "nl-BE": "Strategische richting & implementatie",
                "fr-BE": "Orientation stratégique & mise en œuvre",
                "de-DE": "Strategische Ausrichtung & Umsetzung",
                "fr-FR": "Orientation stratégique & mise en œuvre",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Leads the strategic implementation of an organisation unit. Builds and aligns capabilities, resources and stakeholders. Maintains focus on managing and improving the performance of the organisation unit.",
                "nl-NL": "Geeft richting aan de strategische implementatie van een organisatie-eenheid. Bouwt aan en stemt capaciteiten, middelen en stakeholders op elkaar af. Heeft focus op het beheren en verbeteren van de performance van de organisatie-eenheid.",
                "nl-BE": "Geeft richting aan de strategische implementatie van een organisatie-eenheid. Bouwt aan en stemt capaciteiten, middelen en stakeholders op elkaar af. Heeft focus op het beheren en verbeteren van de performance van de organisatie-eenheid.",
                "fr-BE": "Donne une orientation à la mise en œuvre stratégique d’une unité organisationnelle. Organise des capacités, moyens et parties prenantes et les coordonne mutuellement. Se concentre sur la gestion et l’amélioration des performances de l’unité organisationnelle.",
                "de-DE": "Richtet die strategische Umsetzung einer Organisationseinheit wie vorgesehen aus. Entwickelt Kapazitäten, Ressourcen und beteiligte Parteien und stimmt sie aufeinander ab. Konzentriert sich auf Verwaltung und Verbesserung der Leistung der Organisationseinheit.",
                "fr-FR": "Donne une orientation à la mise en œuvre stratégique d’une unité organisationnelle. Organise des capacités, moyens et parties prenantes et les coordonne mutuellement. Se concentre sur la gestion et l’amélioration des performances de l’unité organisationnelle.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "1 - 3 years",
                "nl-NL": "1 - 3 jaar",
                "nl-BE": "1 - 3 jaar",
                "fr-BE": "1 à 3 ans",
                "de-DE": "1 - 3 Jahre",
                "fr-FR": "1 à 3 ans",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_p2": {
            "problem_solving_focus": {
                "en-EN": "Strategic direction <br>& integration",
                "nl-NL": "Strategische richting <br>& integratie",
                "nl-BE": "Strategische richting <br>& integratie",
                "fr-BE": "Orientation stratégique <br>& intégration",
                "de-DE": "Strategische Ausrichtung <br> & Integration",
                "fr-FR": "Orientation stratégique <br>& intégration",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Strategic direction & implementation",
                "nl-NL": "Strategische richting & implementatie",
                "nl-BE": "Strategische richting & implementatie",
                "fr-BE": "Orientation stratégique & mise en œuvre",
                "de-DE": "Strategische Ausrichtung & Umsetzung",
                "fr-FR": "Orientation stratégique & mise en œuvre",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Leads the strategic implementation of an organisation unit. Has a clear impact on the strategic direction and organisational effectiveness. Builds and proactively aligns capabilities, resources and stakeholders (independent dilemma management).",
                "nl-NL": "Geeft richting aan de strategische implementatie van een organisatie-eenheid. Heeft een duidelijke impact op de strategische richting en organisatie-effectiviteit. Bouwt aan en stemt proactief capaciteiten, middelen en stakeholders op elkaar af (zelfstandig dilemma management).",
                "nl-BE": "Geeft richting aan de strategische implementatie van een organisatie-eenheid. Heeft een duidelijke impact op de strategische richting en organisatie-effectiviteit. Bouwt aan en stemt proactief capaciteiten, middelen en stakeholders op elkaar af (zelfstandig dilemma management).",
                "fr-BE": "Donne une orientation à la mise en œuvre stratégique d’une unité organisationnelle. A un impact clair sur l’orientation stratégique et l’efficacité organisationnelle. Organise de manière proactive des capacités, moyens et parties prenantes et les coordonne mutuellement (gestion indépendante des dilemmes).",
                "de-DE": "Richtet die strategische Umsetzung einer Organisationseinheit wie vorgesehen aus. Hat einen eindeutigen Impact auf die strategische Ausrichtung und Effektivität der Organisation. Entwickelt Kapazitäten, Ressourcen und beteiligte Parteien und stimmt sie proaktiv aufeinander ab (eigenständiges Dilemma-Management).",
                "fr-FR": "Donne une orientation à la mise en œuvre stratégique d’une unité organisationnelle. A un impact clair sur l’orientation stratégique et l’efficacité organisationnelle. Organise de manière proactive des capacités, moyens et parties prenantes et les coordonne mutuellement (gestion indépendante des dilemmes).",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Long term scenarios",
                "nl-NL": "Lange termijn scenarios",
                "nl-BE": "Lange termijn scenarios",
                "fr-BE": "Scénarios à long terme",
                "de-DE": "Langfristige Szenarien",
                "fr-FR": "Scénarios à long terme",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_p3": {
            "problem_solving_focus": {
                "en-EN": "Strategic direction <br>& integration",
                "nl-NL": "Strategische richting <br>& integratie",
                "nl-BE": "Strategische richting <br>& integratie",
                "fr-BE": "Orientation stratégique <br>& intégration",
                "de-DE": "Strategische Ausrichtung <br> & Integration",
                "fr-FR": "Orientation stratégique <br>& intégration",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Strategic direction, integration & transformation",
                "nl-NL": "Strategische richting, integratie & transformatie",
                "nl-BE": "Strategische richting, integratie & transformatie",
                "fr-BE": "Orientation stratégique, intégration & transformation",
                "de-DE": "Strategische Ausrichtung, Integration & Wandel",
                "fr-FR": "Orientation stratégique, intégration & transformation",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Positions one or multiple organisation units for future success. Is an autonomous strategic thinker. Ensures that the strategic transformation actually occurs, both through hard controls (structure, processes, systems, policy) and soft controls (commitment, right people in the right places, leadership, stakeholders, working environment, etc.). Is autonomous in strategic partnering.",
                "nl-NL": "Positioneert een of meerdere organisatie-eenheden voor toekomstig succes. Is een autonome strategische denker. Zorgt dat de strategische transformatie ook daadwerkelijk plaatsvindt, zowel in hard controls (structuur, processen, systemen, beleid) als in soft controls (commitment, juiste mensen op de juiste plek, leiderschap, stakeholders, werkklimaat, etc.). Is autonoom in strategische partnering.",
                "nl-BE": "Positioneert een of meerdere organisatie-eenheden voor toekomstig succes. Is een autonome strategische denker. Zorgt dat de strategische transformatie ook daadwerkelijk plaatsvindt, zowel in hard controls (structuur, processen, systemen, beleid) als in soft controls (commitment, juiste mensen op de juiste plek, leiderschap, stakeholders, werkklimaat, etc.). Is autonoom in strategische partnering.",
                "fr-BE": "Positionne une ou plusieurs unités organisationnelles pour le succès futur. Agit avec une pensée stratégique autonome. Veille à ce que la transformation stratégique ait réellement lieu, aussi bien dans les contrôles formels (structure, processus, systèmes, politique) que dans les contrôles informels (engagement, les bonnes personnes au bon endroit, leadership, parties prenantes, climat de travail, etc.). Est autonome dans le partenariat stratégique.",
                "de-DE": "Positioniert eine oder mehrere Organisationseinheiten für zukünftigen Erfolg. Ist ein autonomer strategischer Denker. Sorgt dafür, dass der strategische Wandel auch tatsächlich stattfindet, sowohl bei Hard Controls (Struktur, Prozesse, Systeme, Unternehmenspolitik) als auch bei Soft Controls (Engagement, richtige Menschen am richtigen Ort, beteiligte Parteien, Arbeitsklima usw.) Autonome strategische Partnerschaften..",
                "fr-FR": "Positionne une ou plusieurs unités organisationnelles pour le succès futur. Agit avec une pensée stratégique autonome. Veille à ce que la transformation stratégique ait réellement lieu, aussi bien dans les contrôles formels (structure, processus, systèmes, politique) que dans les contrôles informels (engagement, les bonnes personnes au bon endroit, leadership, parties prenantes, climat de travail, etc.). Est autonome dans le partenariat stratégique.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Long term scenarios",
                "nl-NL": "Lange termijn scenarios",
                "nl-BE": "Lange termijn scenarios",
                "fr-BE": "Scénarios à long terme",
                "de-DE": "Langfristige Szenarien",
                "fr-FR": "Scénarios à long terme",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        },
        "level_p4": {
            "problem_solving_focus": {
                "en-EN": "Strategic direction <br>& integration",
                "nl-NL": "Strategische richting <br>& integratie",
                "nl-BE": "Strategische richting <br>& integratie",
                "fr-BE": "Orientation stratégique <br>& intégration",
                "de-DE": "Strategische Ausrichtung <br> & Integration",
                "fr-FR": "Orientation stratégique <br>& intégration",
                "pl-PL": "",
                "es-ES": ""
            },
            "education": {
                "en-EN": "Strategic direction, integration & transformation ",
                "nl-NL": "Strategische richting, integratie & transformatie ",
                "nl-BE": "Strategische richting, integratie & transformatie ",
                "fr-BE": "Orientation stratégique, intégration & transformation",
                "de-DE": "Strategische Ausrichtung, Integration & Wandel",
                "fr-FR": "Orientation stratégique, intégration & transformation",
                "pl-PL": "",
                "es-ES": ""
            },
            "problem_solving_capability": {
                "en-EN": "Positions a complex portfolio of organisation units with integration challenges for future success. Resolves broad strategic (and/or holistic) issues. Integrates information from a wide variety of sources and arrives at a substantiated vision with corresponding scenarios. Ensures that the strategic transformation actually occurs, both through hard and soft controls. Is autonomous in strategic partnering.",
                "nl-NL": "Positioneert een complex portfolio van organisatie-eenheden met integratie-uitdagingen voor de toekomst. Lost brede strategische (en/of holistische) vraagstukken op. Integreert informatie uit een grote diversiteit aan bronnen en komt tot zorgvuldig onderbouwde visie met bijbehorende scenario’s. Zorgt dat de strategische transformatie ook daadwerkelijk plaatsvindt zowel in hard controls als in soft controls. Is autonoom in strategische partnering.",
                "nl-BE": "Positioneert een complex portfolio van organisatie-eenheden met integratie-uitdagingen voor de toekomst. Lost brede strategische (en/of holistische) vraagstukken op. Integreert informatie uit een grote diversiteit aan bronnen en komt tot zorgvuldig onderbouwde visie met bijbehorende scenario’s. Zorgt dat de strategische transformatie ook daadwerkelijk plaatsvindt zowel in hard controls als in soft controls. Is autonoom in strategische partnering.",
                "fr-BE": "Positionne un portefeuille complexe d’unités organisationnelles avec des enjeux d’intégration pour le futur. Résout de vastes questions stratégiques (et/ou holistiques). Intègre des informations à partir d’une grande diversité de sources et arrive à une vision bien fondée avec scénarios correspondants. Veille à ce que la transformation stratégique ait réellement lieu aussi bien dans les contrôles formels que dans les contrôles informels. Est autonome dans le partenariat stratégique.",
                "de-DE": "Positioniert ein komplexes Portfolio aus Organisationseinheiten mit integrativen Herausforderungen für die Zukunft. Löst breite strategische (und/oder gemeinschaftliche) Probleme. Integriert Informationen aus vielen verschiedenen Quellen und entwickelt eine sorgfältig untermauerte Vision mit entsprechenden Szenarien. Sorgt dafür, dass der strategische Wandel bei den Hard Controls und Soft Controls auch tatsächlich stattfindet. Autonome strategische Partnerschaften.",
                "fr-FR": "Positionne un portefeuille complexe d’unités organisationnelles avec des enjeux d’intégration pour le futur. Résout de vastes questions stratégiques (et/ou holistiques). Intègre des informations à partir d’une grande diversité de sources et arrive à une vision bien fondée avec scénarios correspondants. Veille à ce que la transformation stratégique ait réellement lieu aussi bien dans les contrôles formels que dans les contrôles informels. Est autonome dans le partenariat stratégique.",
                "pl-PL": "",
                "es-ES": ""
            },
            "planning_horizon": {
                "en-EN": "Long term scenarios",
                "nl-NL": "Lange termijn scenarios",
                "nl-BE": "Lange termijn scenarios",
                "fr-BE": "Scénarios à long terme",
                "de-DE": "Langfristige Szenarien",
                "fr-FR": "Scénarios à long terme",
                "pl-PL": "",
                "es-ES": ""
            },
            "salary": {
                "en-EN": "0",
                "nl-NL": "0",
                "nl-BE": "0",
                "fr-BE": "0",
                "de-DE": "0",
                "fr-FR": "0",
                "pl-PL": "",
                "es-ES": ""
            }
        }
    }
}
