export const loadImage = (setImageDimensions: any, imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
        setImageDimensions({
            height: img.height,
            width: img.width
        });
    };
};


export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    //@ts-ignore
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const validateImage = (file: string) => {
    const result = new Promise((res) => {
        const img = new Image();
        img.src = file;
        img.onload = () => {
            if(img.width <= 1000){
                return res(true);
            }
            return res(false);
        }
    })
    return result;
}

export const getCurrentDateTime = (date?: string) => {
    const currentDate = date ? new Date(date) : new Date();
    const currentDateTime = currentDate.getDate() + '' + (currentDate.getMonth() + 1) + '' + currentDate.getFullYear() + 'T' + currentDate.getHours() + '' + currentDate.getMinutes()
    return currentDateTime
}
