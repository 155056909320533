import ArrowDownIcon from "components/Icons/arrowDown";
import { MatrixDataContext } from "containers/MatrixContextProvider/MatrixContextProvider";
import React, { useContext } from 'react';
import { connect } from "react-redux";
import ReduxStore from "store/store.type";
import { Organisation } from "types/Organisation";
import styled from "styled-components";

export const MatrixBoxContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;

`;

export const ContainerFullScreen = styled.div`
    height: 100%;
    justify-items: start;
    display: grid;
    grid-template-rows: 1fr;
    overflow-x: hidden;
`

export const ContentContainer = styled.div`
    width: 100%;
    overflow: scroll;
    box-sizing: border-box;
    position: relative;
`

export const TableIcon = styled.div`
    padding: 10px;
    color: inherit;
    cursor: pointer;

    &:first-of-type{
        border-radius: 8px;
    }

    &:hover{
        background-color: rgba(0,0,0, .08);
    }
`;

export const JobMatrixExpandButton = styled.div`
    margin: 0 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    margin-bottom: 0px;
    color: #333333;
    font-size: 14px;
    font-weight: 900;
    line-height: 22px;
    position: relative;

    display: inline-flex;
    flex-direction: row;
    align-items: center;

    background-color: #EFEDEE;
    border-radius: 8px;
`;

const DropdownSelect = styled.select`
    all: unset;
    padding: 0 10px;

    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 40px;
    width: 70px;
    border: 0;
`;

export const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
`;

export const Icon = styled.div`
    color: #000;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;

export const MatrixTableZoomController: React.FC<{ currentOrganisation?: Organisation }> = ({ currentOrganisation }) => {
    const { zoomTable, handleZoom } = useContext(MatrixDataContext)
    if (!currentOrganisation) return null

    return (
        <TableZoomController zoomTable={zoomTable} handleZoom={handleZoom} />
    )
}


export const TableZoomController: React.FC<{ zoomTable: number; handleZoom: (input: number) => void }> = ({ zoomTable, handleZoom }) => {
    const zoomOptions = [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150]

    const changeZoomLevel = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const zoom = parseInt(e.target.value)
        handleZoom(zoom)
    }

    return (
        <Container>
            <DropdownSelect value={zoomTable} onChange={changeZoomLevel}>
                {zoomOptions.map((option) => <option key={option} value={option}>{option}</option>)}
            </DropdownSelect>
            <Icon>
                <ArrowDownIcon color="#000" />
            </Icon>
        </Container>
    )
}

const mapMatrixViewTabsStateToProps = (state: ReduxStore) => ({
    currentOrganisation: state.user.currentOrganisation,
});
const mapMatrixViewTabsDispatchToProps = {};
export const ConnectedTableZoomController = connect(
    mapMatrixViewTabsStateToProps,
    mapMatrixViewTabsDispatchToProps
)(MatrixTableZoomController);
