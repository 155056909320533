import apiRequest from 'helpers/api';
import { Dispatch } from 'redux';
import Answer from 'types/Answer';
import Profile from 'types/Profile';
import QuestionNode, { UpdatedQuestion } from 'types/QuestionNode';
import AllocatorActions from './allocator.constants';
import store from 'store/store';
import { convertMultilingualStringToLanguageObject } from 'store/language/language.actions';
import { ValidatedProfile } from 'types/Result';
import { toast } from 'react-toastify';
import { handleApiError } from "../../helpers/errorHandler";
import { AllocatorErrorTypes } from "./allocator.errors";
import { Domain } from "../../types/Domain";

export const allocatorAddError = (error: string) => (dispatch: Dispatch) => {
    return dispatch({
        type: AllocatorActions.AddError,
        payload: {
            error,
        },
    });
};

export const allocatorRetake = () => (dispatch: Dispatch) => {
    return dispatch({
        type: AllocatorActions.Retake,
        payload: {},
    });
};

export const setProfile = (profile: Profile) => (dispatch: Dispatch) => {
    dispatch({
        type: AllocatorActions.SetProfile,
        payload: {
            profile,
        },
    });
};

export type LoadQuestions = (forceReload?: boolean) => void;
export const loadQuestions = (forceReload?: boolean) => (dispatch: Dispatch) => {
    if (!forceReload && store.getState().allocator.questions.questions.length > 0) {
        return
    }

    apiRequest('allocator/data', 'GET', store.getState().user.jwt, undefined, {
        currentOrganisation: store.getState().user.currentOrganisation?.id
    })
        .then(data => {
            dispatch({
                type: AllocatorActions.LoadQuestions,
                payload: data,
            });
        })
        .catch(error => {
            handleApiError(Domain.Allocator, AllocatorErrorTypes.loadQuestions, store.getState().language.currentLanguage)
            return dispatch({
                type: AllocatorActions.AddError,
                payload: {
                    error,
                },
            });
        });
};

export type LoadValidatedProfiles = (reload?: boolean) => Promise<ValidatedProfile[]>;
export const loadValidatedProfiles = (reload?: boolean) => (dispatch: Dispatch) => {
    const result = new Promise<ValidatedProfile[]>((resolve, reject) => {
        const state = store.getState()
        if (!store.getState().user.jwt) {
            return
        }

        if (!reload && state.allocator.validatedProfiles.length > 0) {
            resolve(state.allocator.validatedProfiles)
            return
        }

        if (!reload && store.getState().allocator.loadingValidatedProfiles) {
            apiRequest('allocator/validatedProfiles', 'GET', store.getState().user.jwt, undefined, {
                currentOrganisation: store.getState().user.currentOrganisation?.id
            }).then(data => {
                resolve(data)
            }).catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.loadValidatedProfiles, store.getState().language.currentLanguage)
                reject(error)
            })
            return
        }

        dispatch({
            type: AllocatorActions.StartLoadingValidatedProfiles,
        })

        apiRequest('allocator/validatedProfiles', 'GET', store.getState().user.jwt, undefined, {
            currentOrganisation: store.getState().user.currentOrganisation?.id
        })
            .then(data => {
                resolve(data)
                dispatch({
                    type: AllocatorActions.LoadValidatedProfiles,
                    payload: {
                        validatedProfiles: data,
                    }
                });
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.loadValidatedProfiles, store.getState().language.currentLanguage)
                reject(error)
            })
    })
    return result
}

export type LoadLevels = () => Promise<void>;
export const loadLevels = () => (dispatch: Dispatch) => {
    return apiRequest('styrlevel', 'GET', store.getState().user.jwt, undefined, {
        currentOrganisation: store.getState().user.currentOrganisation?.id
    })
        .then(data => {
            dispatch({
                type: AllocatorActions.LoadLevels,
                payload: {
                    levels: data,
                }
            });
        }).catch((error) => {
            handleApiError(Domain.Allocator, AllocatorErrorTypes.loadLevels, store.getState().language.currentLanguage)
        })
};

export type LoadTalentpaths = () => Promise<void>;
export const loadTalentpaths = () => (dispatch: Dispatch) => {
    return apiRequest('talentpath', 'GET', store.getState().user.jwt, undefined, {
        currentOrganisation: store.getState().user.currentOrganisation?.id
    })
        .then(data => {
            dispatch({
                type: AllocatorActions.LoadTalentpaths,
                payload: {
                    talentpaths: data,
                }
            });
        }).catch((error) => {
            handleApiError(Domain.Allocator, AllocatorErrorTypes.loadTalentpaths, store.getState().language.currentLanguage)
        })
};

export const setSelectedAnswers = (answer: Answer[]) => (
    dispatch: Dispatch
) => {
    dispatch({
        type: AllocatorActions.SetSelectedAnswers,
        payload: answer,
    });
};

export const previousQuestion = () => (dispatch: Dispatch) => {
    dispatch({
        type: AllocatorActions.PreviousQuestion,
    });
};

export const nextQuestion = (nextQuestion: QuestionNode) => (
    dispatch: Dispatch
) => {
    dispatch({
        type: AllocatorActions.NextQuestion,
        payload: nextQuestion,
    });
};


// ADMIN UPDATE CONTENT
export type CreateQuestion = () => Promise<number>;
export const createQuestion = () => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<number>((resolve, reject) => {
        apiRequest('questions/', 'POST', state.user.jwt, {
            "question": [],
            "description": [],
            "title": "New question",
        }, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then((data) => {
                toast.success("Question created")
                //@ts-ignore
                dispatch(loadQuestions(true))
                resolve(data.id);
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.createQuestion, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type UpdateQuestion = (updatedQuestion: UpdatedQuestion) => Promise<void>;
export const updateQuestion = (updatedQuestion: UpdatedQuestion) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<void>((resolve, reject) => {
        apiRequest('questions/' + updatedQuestion.id, 'PUT', state.user.jwt, {
            id: updatedQuestion.id,
            "title": updatedQuestion.title,
            "question": convertMultilingualStringToLanguageObject(state.language, updatedQuestion.question),
            "description": convertMultilingualStringToLanguageObject(state.language, updatedQuestion.description),
            isFirstQuestion: updatedQuestion.isFirstQuestion,
        }, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then(() => {
                toast.success("Question updated")
                //@ts-ignore
                dispatch(loadQuestions(true))
                resolve();
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.updateQuestion, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type DeleteQuestion = (id: string) => Promise<void>;
export const deleteQuestion = (id: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<void>((resolve, reject) => {
        apiRequest('questions/' + id, 'DELETE', state.user.jwt, undefined, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then(() => {
                toast.success("Question deleted")
                //@ts-ignore
                dispatch(loadQuestions(true))
                resolve();
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.deleteQuestion, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type CreateAnswer = (questionId: string) => Promise<{ id: string }>;
export const createAnswer = (questionId: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<{ id: string }>((resolve, reject) => {
        apiRequest('answers/' + questionId, 'POST', state.user.jwt, {
            questionId,
            text: [],
            nextQuestionId: null,
            validatedProfileId: null,
            weight: 0,
            info: {
                title: [],
                text: []
            },
            prefix: {
                text: "",
                color: "",
                icon: "",
            },
            isGoBack: false,
        }, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then((res) => {
                toast.success("Answer created")
                //@ts-ignore
                dispatch(loadQuestions(true))
                resolve(res);
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.createAnswer, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type UpdateAnswer = (updatedAnswer: Answer) => Promise<void>;
export const updateAnswer = (updatedAnswer: Answer) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<void>((resolve, reject) => {
        apiRequest('answers/' + updatedAnswer.questionId + "/" + updatedAnswer.id, 'PUT', state.user.jwt, {
            ...updatedAnswer,
            text: convertMultilingualStringToLanguageObject(state.language, updatedAnswer.text),
            info: {
                ...updatedAnswer.info,
                title: convertMultilingualStringToLanguageObject(state.language, updatedAnswer.info?.title),
                text: convertMultilingualStringToLanguageObject(state.language, updatedAnswer.info?.text)
            }
        }, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then(() => {
                toast.success("Answer updated")
                //@ts-ignore
                dispatch(loadQuestions(true))
                resolve();
            })
            .catch(reject);
    })
};

export type DeleteAnswer = (id: string) => Promise<void>;
export const deleteAnswer = (id: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<void>((resolve, reject) => {
        apiRequest('answers/' + id, 'DELETE', state.user.jwt, undefined, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then(() => {
                toast.success("Answer deleted")
                //@ts-ignore
                dispatch(loadQuestions(true))
                resolve();
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.deleteAnswer, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type CreateValidatedProfile = () => Promise<number>;
export const createValidatedProfile = () => (dispatch: Dispatch) => {
    const state = store.getState();

    const talentPath = state.allocator.talentPaths[0] || state.allocator.validatedProfiles[0].talentPath
    return new Promise<number>((resolve, reject) => {
        apiRequest('validatedprofile/', 'POST', state.user.jwt, {
            title: "New Validated Profile",
            color: "Green",
            tmaCode: "123",
            autonomy: [],
            communicationSkills: [],
            continuousImprovement: [],
            description: [],
            educationLevel: [],
            expertiseLevel: [],
            managementSkills: [],
            planningHorizon: [],
            problemSolvingCapability: [],
            projectManagementSkills: [],
            styrGroup: [],
            competences: [],
            talentPathId: talentPath.id,
            styrLevelId: state.allocator.levels[0]?.id || state.allocator.validatedProfiles[0]?.styrLevel.id
        }, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then((data) => {
                //@ts-ignore
                dispatch(loadValidatedProfiles(true))
                resolve(data.id);
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.createValidatedProfile, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};


export type UpdateValidatedProfile = (updatedValidatedProfile: ValidatedProfile) => Promise<void>;
export const updateValidatedProfile = (updatedValidatedProfile: ValidatedProfile) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<void>((resolve, reject) => {
        apiRequest('validatedprofile/' + updatedValidatedProfile.id, 'PUT', state.user.jwt, {
            ...updatedValidatedProfile,
            //TODO: add these fields to edit screen,
            "title": updatedValidatedProfile.title,
            "talentPathId": updatedValidatedProfile.talentPath.id,
            "styrLevelId": updatedValidatedProfile.styrLevel.id,
            "competences": updatedValidatedProfile.competences?.map(c => {
                return {
                    ...c,
                    translations: convertMultilingualStringToLanguageObject(state.language, c.translations)
                }
            }),
            "autonomy": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.autonomy),
            "communicationSkills": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.communicationSkills),
            "continuousImprovement": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.continuousImprovement),
            "description": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.description),
            "educationLevel": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.educationLevel),
            "expertiseLevel": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.expertiseLevel),
            "managementSkills": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.managementSkills),
            "planningHorizon": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.planningHorizon),
            "problemSolvingCapability": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.problemSolvingCapability),
            "projectManagementSkills": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.projectManagementSkills),
            "styrGroup": convertMultilingualStringToLanguageObject(state.language, updatedValidatedProfile.styrGroup)
        }, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then(() => {
                toast.success("Validated profile updated")
                //@ts-ignore
                dispatch(loadValidatedProfiles(true))
                resolve();
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.updateValidatedProfile, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type DeleteValidatedProfile = (id: string) => Promise<void>;
export const deleteValidatedProfile = (id: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<void>((resolve, reject) => {
        apiRequest('validatedProfiles/' + id, 'DELETE', state.user.jwt, undefined, {
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then(() => {
                //@ts-ignore
                dispatch(loadValidatedProfiles(true))
                resolve();
            })
            .catch((error) => {
                handleApiError(Domain.Allocator, AllocatorErrorTypes.deleteValidatedProfile, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};
