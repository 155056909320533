import { JobMatrixImageProps } from 'containers/MatrixBar/useManageMatrixImage';
import apiRequest from 'helpers/api';
import { handleImageDownload } from 'helpers/convertMatrixToFile';
import { formatJobFamilyImageFileName } from 'helpers/formatImageDetails';
import store from 'store/store';
import { Family } from 'types/Result';

type GetFamilyMatrixImages = (
    familyId: string
) => Promise<FamilyMatrixImagesResults>;

type FamilyMatrixImagesResults = JobMatrixImageProps[];

export const getFamilyMatrixImages: GetFamilyMatrixImages = async familyId => {
    const state = store.getState();
    const endpoint = `organisations/${state.user.currentOrganisation?.id}/family/${familyId}/versions`;

    try {
        const response = await apiRequest(
            endpoint,
            'GET',
            state.user.jwt,
            undefined,
            undefined
        );

        return response;
    } catch (error) {
        console.error('Error fetching family matrix images:', error);
        return Promise.reject(error);
    }
};

export const getFamilyMatrixImageById = async (
    family: Family,
    image: JobMatrixImageProps
) => {
    const { id, createdDate } = image;
    const state = store.getState();
    const endpoint = `organisations/${state.user.currentOrganisation?.id}/family/${family.id}/versionimage/${id}`;

    try {
        const response = await apiRequest(
            endpoint,
            'GET',
            state.user.jwt,
            undefined,
            {
                currentOrganisation: undefined,
                responseType: 'blob',
            }
        );
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const fileName = formatJobFamilyImageFileName('png', createdDate);
        await handleImageDownload(url, fileName);
        return true;
    } catch (error) {
        console.error('Error fetching family matrix image by ID:', error);
        return false;
    }
};
export const postFamilyMatrixImage = async (
    familyId: string,
    file: {
        blob: Blob;
        fileName: string;
        description: string;
    }
) => {
    const state = store.getState();
    const endpoint = `organisations/${state.user.currentOrganisation?.id}/family/${familyId}/versions`;

    if (!state.user.currentOrganisation?.id) {
        console.error('No current organisation ID found');
        return false;
    }

    const formData = new FormData();
    formData.append('id', state.user.currentOrganisation?.id);
    formData.append('jobfamilyId', familyId);
    formData.append('description', file.description);
    formData.append('file', file.blob, file.fileName);

    try {
        await apiRequest(endpoint, 'POST', state.user.jwt, formData, undefined);
        return true;
    } catch (error) {
        console.error('Error posting family matrix image:', error);
        return false;
    }
};
