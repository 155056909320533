const theme = {
    boxShadow: '0 2px 8px 0 rgba(167, 178, 195, 0.32)',
    colorCodePicker: (color?: string, opacity = 1) => {
        if (!color) {
            return `rgba(255, 255, 255, ${opacity})`;
        }

        switch (color?.toLowerCase()) {
            case 'yellow':
                return `rgba(255, 215, 65, ${opacity})`;
            case 'orange':
                return `rgba(237, 139, 55, ${opacity})`;
            case 'blue':
                return `rgba(54, 136, 198, ${opacity})`;
            case 'green':
                return `rgba(161, 196, 55, ${opacity})`;
            case 'purple':
                return `rgba(183, 86, 152, ${opacity})`;
            case 'turqouise':
                return `rgba(77, 180, 184, ${opacity})`;
            default:
                return `rgba(255, 255, 255, ${opacity})`;
        }
    },
    jobMatrix: {
        thead: '#786D64',
        theadJob: '#3b4b63',
        cellColor: '#f2f2f2',
        cellBorderColor: '#000',
        primary: 'rgb(118,178,174)',
        backgroundColor: '#eff4f9'
    },
    colors: {
        light: '#fff',
        dark: '#333',
        red: 'rgb(227,106,106)',
        green: '#66caba',
        salmonRed: '#f06e64',
        darkGrey: "#3b4b63",
        beige: '#786D64',
        lightBlue: "#afc8e1"
    },
    shadows: {
        green: '0 2px 8px 0 rgb(117 179 174 / 32%)',
        orange: '0 2px 8px 0 rgb(237 131 25 / 32%)'
    },
    background: {
        app: '#eff4f9',
        overlay: 'rgba(0,0,0, .24)'
    }
};

export default theme;
