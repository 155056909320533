import { MultiLingualString } from "../../types/MultiLingualString";
import {en} from "../../helpers/language/en";
import {nl} from "../../helpers/language/nl";
import {de} from "../../helpers/language/de";
import {be_nl} from "../../helpers/language/be_nl";
import {be_fr} from "../../helpers/language/be_fr";
import {fr} from "../../helpers/language/fr";
import {pl} from "../../helpers/language/pl";
import {es} from "../../helpers/language/es";

export const UpdatesErrorTypes = {
    updatesCreateUpdate: 'updatesCreateUpdate',
    updatesGetUpdates: 'updatesGetUpdates',
    updatesChangeUpdate: 'updatesChangeUpdate',
    updatesDeleteUpdate: 'updatesDeleteUpdate',
} as const

export type UpdatesErrorTypeKeys = keyof typeof UpdatesErrorTypes;

export const UpdatesErrors: { [key in UpdatesErrorTypeKeys]: MultiLingualString } = {
    updatesCreateUpdate: {
        'en-EN': en.updatesCreateUpdate,
        'nl-NL': nl.updatesCreateUpdate,
        'de-DE': de.updatesCreateUpdate,
        'nl-BE': be_nl.updatesCreateUpdate,
        'fr-FR': fr.updatesCreateUpdate,
        'fr-BE': be_fr.updatesCreateUpdate,
        'pl-PL': pl.updatesCreateUpdate,
        'es-ES': es.updatesCreateUpdate,
    },
    updatesGetUpdates: {
        'en-EN': en.updatesGetUpdates,
        'nl-NL': nl.updatesGetUpdates,
        'de-DE': de.updatesGetUpdates,
        'nl-BE': be_nl.updatesGetUpdates,
        'fr-FR': fr.updatesGetUpdates,
        'fr-BE': be_fr.updatesGetUpdates,
        'pl-PL': pl.updatesGetUpdates,
        'es-ES': es.updatesGetUpdates,
    },
    updatesChangeUpdate: {
        'en-EN': en.updatesChangeUpdate,
        'nl-NL': nl.updatesChangeUpdate,
        'de-DE': de.updatesChangeUpdate,
        'nl-BE': be_nl.updatesChangeUpdate,
        'fr-FR': fr.updatesChangeUpdate,
        'fr-BE': be_fr.updatesChangeUpdate,
        'pl-PL': pl.updatesChangeUpdate,
        'es-ES': es.updatesChangeUpdate,
    },
    updatesDeleteUpdate: {
        'en-EN': en.updatesDeleteUpdate,
        'nl-NL': nl.updatesDeleteUpdate,
        'de-DE': de.updatesDeleteUpdate,
        'nl-BE': be_nl.updatesDeleteUpdate,
        'fr-FR': fr.updatesDeleteUpdate,
        'fr-BE': be_fr.updatesDeleteUpdate,
        'pl-PL': pl.updatesDeleteUpdate,
        'es-ES': es.updatesDeleteUpdate,
    },
};

export const getUpdatesErrorMessage = (errorName: UpdatesErrorTypeKeys,  language: keyof MultiLingualString) =>  {
    const message = UpdatesErrors[errorName];
    return message[language] || message['en-EN'];
}
