import { ACCOUNT_API_URL, API_URL } from 'config';
import Routes from 'routes/Routes.types';
import { ResponseType, Method } from 'types/Api';

const apiRequest = (
    endpoint: string,
    method: Method = 'GET',
    auth: string | undefined,
    data: any,
    options: {
        isAccountApi?: boolean;
        isShareLink?: boolean;
        currentOrganisation?: string;
        responseType?: ResponseType;
    } = {}
) => {

    if (method !== 'POST' && method !== 'PUT' && method !== 'DELETE' && data) {
        throw new Error(
            'Unexpected API Call with data when method does not support data.'
        );
    }

    const accountUrl =
        options.isAccountApi &&
        (process.env.REACT_APP_ACCOUNT_API_URL || ACCOUNT_API_URL);
    const apiURL = process.env.REACT_APP_API_URL || API_URL;
    const url = (accountUrl || apiURL) + endpoint;

    const headers = {
        ...(auth && { Authorization: `Bearer ${auth}` }),
        ...(options?.currentOrganisation && {
            'X-currentOrganisationId': options.currentOrganisation,
        }),
        ...(data instanceof FormData
            ? {}
            : { 'content-type': 'application/json' }),
    };

    const requestBody = {
        method,
        headers,
        ...(data && {
            body: data instanceof FormData ? data : JSON.stringify(data),
        }),
    };

    return fetch(url, requestBody)
        .then(response => {
            if (!response.ok) {
                throw response;
            }

            if (options.responseType === 'blob') {
                return response
            } else {
                return response.json().catch(() => null);
            }
        })
        .catch(error => {
            if (error.status === 401) {
                window.location.href = Routes.LoginRoute;
            }
            return Promise.reject(error);
        });
};

export default apiRequest;
