import React, { useEffect, useState } from 'react';
import { Container, SelectWrapper, TextField, Fieldset, Select, FieldLabel, SearchField, Icon } from './InputField.components';
import InputFieldProps, { MultiSelectFieldProps, SelectFieldProps } from './InputField.types';
import { emailValidation } from './InputField.valid';
import SearchIcon from 'components/Icons/search';
import { MultiSelect } from 'react-multi-select-component';
import ArrowDownIcon from 'components/Icons/arrowDown';
import { ArrowUpIcon } from 'components/Icons/arrowUp';
import getLanguageObject from 'helpers/language';

const InputField: React.FC<InputFieldProps> = ({
    label,
    placeholder,
    type,
    value,
    onChange,
    onKeyDown,
    errorMessage,
    fullWidth,
    width,
    disableAutocomplete,
    name,
    language
}) => {
    return (
        <Container>
            <Fieldset fullWidth={fullWidth} width={width}>
                {label && <FieldLabel label={label} />}
                <TextField
                    name={name ? name : ''}
                    valid={true}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    autoComplete={disableAutocomplete ? "off" : undefined}

                />
                {errorMessage}
            </Fieldset>
        </Container>
    )
}

export default InputField
export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({ currentLanguage, label, value, onChange, options, errorMessage, fullWidth, width, searchable }) => {
    const ArrowRenderer = ({ expanded }: { expanded: boolean }) => <>{expanded ? <ArrowUpIcon color="#000" /> : <ArrowDownIcon color="#000" />}</>;
    const lang = getLanguageObject(currentLanguage);

    return (<Container>
        <Fieldset fullWidth={fullWidth} width={width}>
            <FieldLabel label={label} />
            <MultiSelect
                hasSelectAll={false}
                options={options}
                value={value}
                disableSearch={searchable ? false : true}
                ClearSelectedIcon={null}
                onChange={onChange}
                ArrowRenderer={ArrowRenderer}
                overrideStrings={{
                    "allItemsAreSelected": lang.multiselectAllItemsAreSelected,
                    "clearSearch": lang.multiselectClearSearch,
                    "clearSelected": lang.multiselectClearSelected,
                    "noOptions": lang.multiselectNoOptions,
                    "search": lang.multiselectSearch,
                    "selectAll": lang.multiselectSelectAll,
                    "selectAllFiltered": lang.multiselectSelectAllFiltered,
                    "selectSomeItems": lang.multiselectSelectSomeItems,
                    "create": lang.multiselectCreate,
                }}
                labelledBy={lang.mulitselectSelect} />
            {errorMessage}
        </Fieldset>
    </Container>)
}

export const SelectField: React.FC<SelectFieldProps> = ({ label, value, onChange, options, errorMessage, disabled, fullWidth, width }) => {

    const renderOptions = () => {
        if (disabled) return null

        return options
    }

    return (<Container>
        <Fieldset fullWidth={fullWidth} width={width}>
            <FieldLabel label={label} />
            <SelectWrapper disabled={disabled}>
                <Select
                    value={value}
                    onChange={onChange}
                >
                    {renderOptions()}
                </Select>
            </SelectWrapper>
            {errorMessage}
        </Fieldset>
    </Container>)
}

export const InputSearch: React.FC<InputFieldProps> = ({
    label,
    placeholder,
    type,
    value,
    onChange,
    onKeyDown,
    errorMessage,
    fullWidth,
    width
}) => {
    const [valid, setValid] = useState<boolean>(true);

    useEffect(() => {
        checkInputValid();
    })

    const checkInputValid = () => {

        if (!value) return setValid(true);

        if (type === "email") {
            if (emailValidation(value)) return setValid(true);

            return setValid(false);
        }
    }

    return (
        <Container>
            <Fieldset fullWidth={fullWidth} width={width}>
                {label && <FieldLabel label={label} />}
                <Icon>
                    <SearchIcon />
                </Icon>
                <SearchField
                    valid={valid}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onBlur={checkInputValid}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
                {errorMessage}
            </Fieldset>
        </Container>
    )
}
