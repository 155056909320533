import Button from 'components/Button/Button';
import Paper from 'components/Paper/Paper';
import { UnderlinedTitle } from 'components/Typography/Typography';
import InputField, { SelectField } from 'containers/InputField/InputField';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';
import { onKeyDown } from 'helpers/form';
import getLanguageObject, { LangObject } from 'helpers/language';
import { useNavigate } from 'helpers/useNavigation';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import {
    loadQuestions,
    LoadQuestions, setProfile
} from 'store/allocator/allocator.actions';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import ReduxStore from 'store/store.type';
import { Organisation } from 'types/Organisation';
import { Language } from 'types/MultiLingualString';
import useLocalStorageState from "use-local-storage-state";
import VersionNumbers from "../../../utils/versionNumbers";
import TagsCheckboxes, { FormContainer, Header, SetupContainer, SetupImage, StyledForm, Wrapper } from './AllocatorSetup.components';
import { TagTypes } from 'store/results/results.constants';
import { resultsGetAllTags } from 'store/results/results.actions';

export const AllocatorSetupBase: React.FC<AllocatorSetupProps> = ({
    setProfile,
    currentLanguage,
    currentOrganisation
}) => {
    const { navigate } = useNavigate();

    const [profileError, setProfileError] = useState({
        exist: false,
        value: false,
    });

    const [organisationUnitError, setOrganisationUnitError] = useState({
        organisationUnitName: '',
        hasNoChildren: false,
    });

    const location = useLocation<{
        profile: {
            name: string;
            organisationUnit: string;
            unit: string;
            tags: string[];
        };
    }>();

    const [profile, setProfileState] = useLocalStorageState<Profile>(currentOrganisation ? `${currentOrganisation.name}-${VersionNumbers.Profile}` : VersionNumbers.Profile, initialProfile);

    useEffect(() => {
        if (currentOrganisation && currentOrganisation.organisationUnits) {
            const locationValues = location?.state?.profile;
            const locationOrganisationUnit = currentOrganisation.organisationUnits.find((x) => {
                return x.id === locationValues?.organisationUnit;
            });

            let locationUnit;
            if (locationOrganisationUnit) {
                locationUnit = locationOrganisationUnit.units.find((x) => {
                    return x.id === locationValues?.unit;
                });
            }

            const localProfileData = localStorage.getItem(`${currentOrganisation.name}-${VersionNumbers.Profile}`);

            const isValidStateProfile = Boolean(locationValues && locationOrganisationUnit && locationUnit);
            const isValidInitialProfileData = currentOrganisation.organisationUnits[0]?.id && currentOrganisation.organisationUnits[0]?.units.length > 0;
            const isValidLocalProfileOrganisationUnit = currentOrganisation && currentOrganisation.organisationUnits.find(x => x.id === profile.organisationUnit)
            const isValidLocalProfile = isValidLocalProfileOrganisationUnit && isValidLocalProfileOrganisationUnit.units.find(x => x.id === profile.unit)

            const shouldShowLocalProfile = !isValidStateProfile //state profile is preferred over local profile.

            if (isValidInitialProfileData) {
                setProfileState((shouldShowLocalProfile && isValidLocalProfile && localProfileData !== null) ? JSON.parse(localProfileData) : {
                    name: locationValues?.name || '',
                    organisationUnit: locationOrganisationUnit?.id || currentOrganisation?.organisationUnits[0]?.id || '',
                    unit: locationUnit?.id || currentOrganisation?.organisationUnits[0]?.units[0]?.id || '',
                    tags: [TagTypes.tagsCurrentState]
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganisation]);

    const handleChangeUnit = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setProfileState({
            ...profile,
            unit: event.currentTarget.value,
        });
    };

    const handleChangeOrganisationUnit = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const organisationUnit = event.currentTarget.value;
        const maybeUnits = currentOrganisation?.organisationUnits.find((x) => {
            return x.id === organisationUnit
        });
        const maybeUnit = maybeUnits?.units[0]

        if (maybeUnit) {
            setProfileState({
                ...profile,
                organisationUnit: organisationUnit,
                unit: maybeUnit.id,
            });
        } else {
            const organisationUnitName = maybeUnits?.name;

            setOrganisationUnitError({
                organisationUnitName: getStringFromCurrentLanguage(organisationUnitName, currentLanguage),
                hasNoChildren: true,
            })
        }
    }

    const handleSubmit = () => {
        if (!profile.name) return setProfileError({
            exist: false,
            value: true,
        });

        setProfile(profile);
    };

    const lang: LangObject = getLanguageObject(currentLanguage);


    const MabyRenderOrganisationUnitError = () => {
        if (organisationUnitError.hasNoChildren) return <InputFieldErrorMessages text={lang.organisationUnitHasNoUnits(organisationUnitError.organisationUnitName)} />

        return null;
    }

    const MaybeRenderError = () => {
        if (profileError.exist) return <InputFieldErrorMessages text={lang.jobAlreadyExist} />
        if (profileError.value) return <InputFieldErrorMessages text={lang.noNameEntered} />

        return null;
    }

    if (!currentOrganisation || !profile.organisationUnit || !profile.unit) {
        return <Paper>
            <br />
            <UnderlinedTitle>{lang.styrAllocator}</UnderlinedTitle>
            <p>{lang.allocatorWarningOrganisation}</p>
            <Button onClick={() => navigate(Routes.ManageJobMatrix)} text={lang.goToManagement} priority="tertiary" />
        </Paper>;
    }

    const getAllTags = () => {
        return resultsGetAllTags(currentLanguage);
    }

    const units = currentOrganisation.organisationUnits?.find(ent => ent.id === profile.organisationUnit)?.units || currentOrganisation.organisationUnits?.[0]?.units || [];

    return (
        <Wrapper>
            <SetupContainer>
                <SetupImage style={{
                    backgroundColor: '#fff',
                    backgroundImage: `url(${require('assets/images/allocator_image.svg').default})`,
                }} />
                <FormContainer>
                    <Header>
                        <strong>{lang.createNewProfile}</strong>
                        <UnderlinedTitle>{lang.styrAllocator}</UnderlinedTitle>
                    </Header>
                    <StyledForm>
                        <InputField
                            label={lang.question}
                            type="text"
                            value={profile.name}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => setProfileState({
                                ...profile,
                                name: event.currentTarget.value,
                            })}
                            onKeyDown={event => onKeyDown(event, handleSubmit)}
                            errorMessage={<MaybeRenderError />}
                        />
                        <SelectField
                            label={lang.organisationUnit}
                            value={profile.organisationUnit}
                            onChange={handleChangeOrganisationUnit}
                            options={
                                currentOrganisation.organisationUnits?.map((e) => (
                                    <option key={e.id} value={e.id}>{getStringFromCurrentLanguage(e.name, currentLanguage)}</option>
                                )) || []
                            }
                            errorMessage={<MabyRenderOrganisationUnitError/>}
                        />
                        <SelectField
                            label={lang.unit}
                            value={profile.unit}
                            onChange={handleChangeUnit}
                            options={
                                units.map((e) => (
                                    <option key={e.id} value={e.id}>{getStringFromCurrentLanguage(e.name, currentLanguage).replace(/<[^>]*>?/gm, '')}</option>
                                ))
                            }
                        />
                        <TagsCheckboxes
                            profile={profile}
                            setProfile={setProfileState}
                            getAllTags={getAllTags}
                            lang={getLanguageObject(currentLanguage)}
                        />
                        <Button
                            onClick={handleSubmit}
                            text={lang.next}
                            priority="primary"
                        />
                    </StyledForm>
                </FormContainer>
            </SetupContainer>
        </Wrapper>
    );
};

interface AllocatorSetupProps {
    setProfile: Function;
    currentLanguage: Language;
    currentOrganisation?: Organisation;
    loadQuestions: LoadQuestions;
}

export interface Profile {
    name: string;
    organisationUnit: string;
    unit: string;
    tags: string[];
}

const initialProfile: Profile = {
    name: '',
    organisationUnit: '',
    unit: '',
    tags: [TagTypes.tagsCurrentState],
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});
const mapDispatchToProps = {
    setProfile,
    loadQuestions,
};
const connectedAllocatorSetup = connect(
    mapStateToProps,
    mapDispatchToProps
)(AllocatorSetupBase);

export default connectedAllocatorSetup;
