import { MultiLingualString } from "../../types/MultiLingualString";
import {en} from "../../helpers/language/en";
import {nl} from "../../helpers/language/nl";
import {de} from "../../helpers/language/de";
import {be_nl} from "../../helpers/language/be_nl";
import {be_fr} from "../../helpers/language/be_fr";
import {fr} from "../../helpers/language/fr";
import {pl} from "../../helpers/language/pl";
import {es} from "../../helpers/language/es";

export const JobFamilyErrorTypes = {
    createJobfamily: 'createJobfamily',
    updateJobfamily: 'updateJobfamily',
    deleteJobfamily: 'deleteJobfamily',
    updateDifferentiatingFactor: 'updateDifferentiatingFactor',
    updateJobFamilyByField: 'updateJobFamilyByField',
} as const

export type JobFamilyErrorTypeKeys = keyof typeof JobFamilyErrorTypes;

export const JobFamilyErrors: { [key in JobFamilyErrorTypeKeys]: MultiLingualString } = {
    createJobfamily: {
        'en-EN': en.createJobfamily,
        'nl-NL': nl.createJobfamily,
        'de-DE': de.createJobfamily,
        'nl-BE': be_nl.createJobfamily,
        'fr-BE': be_fr.createJobfamily,
        'fr-FR': fr.createJobfamily,
        'pl-PL': pl.createJobfamily,
        'es-ES': es.createJobfamily,
    },
    updateJobfamily: {
        'en-EN': en.updateJobfamily,
        'nl-NL': nl.updateJobfamily,
        'de-DE': de.updateJobfamily,
        'nl-BE': be_nl.updateJobfamily,
        'fr-BE': be_fr.updateJobfamily,
        'fr-FR': fr.updateJobfamily,
        'pl-PL': pl.updateJobfamily,
        'es-ES': es.updateJobfamily,
    },
    deleteJobfamily: {
        'en-EN': en.deleteJobfamily,
        'nl-NL': nl.deleteJobfamily,
        'de-DE': de.deleteJobfamily,
        'nl-BE': be_nl.deleteJobfamily,
        'fr-BE': be_fr.deleteJobfamily,
        'fr-FR': fr.deleteJobfamily,
        'pl-PL': pl.deleteJobfamily,
        'es-ES': es.deleteJobfamily,
    },
    updateDifferentiatingFactor: {
        'en-EN': en.updateDifferentiatingFactor,
        'nl-NL': nl.updateDifferentiatingFactor,
        'de-DE': de.updateDifferentiatingFactor,
        'nl-BE': be_nl.updateDifferentiatingFactor,
        'fr-BE': be_fr.updateDifferentiatingFactor,
        'fr-FR': fr.updateDifferentiatingFactor,
        'pl-PL': pl.updateDifferentiatingFactor,
        'es-ES': es.updateDifferentiatingFactor,
    },
    updateJobFamilyByField: {
        'en-EN': en.updateJobFamilyByField,
        'nl-NL': nl.updateJobFamilyByField,
        'de-DE': de.updateJobFamilyByField,
        'nl-BE': be_nl.updateJobFamilyByField,
        'fr-BE': be_fr.updateJobFamilyByField,
        'fr-FR': fr.updateJobFamilyByField,
        'pl-PL': pl.updateJobFamilyByField,
        'es-ES': es.updateJobFamilyByField,
    },
};

export const getJobFamilyErrorMessage = (errorName: JobFamilyErrorTypeKeys,  language: keyof MultiLingualString) =>  {
    const message = JobFamilyErrors[errorName];
    return message[language] || message['en-EN'];
}
