import React, { useEffect } from 'react';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import ReduxStore from 'store/store.type';
import { userLoginMicrosoft } from 'store/user/user.actions';
import { connect } from 'react-redux';
import { Permissions } from 'store/user/user.types';
import AwaitLoading from 'containers/AwaitLoading/AwaitLoading';
import MainLayoutNoSidebar from 'layouts/MainLayoutNoSidebar/MainLayoutNoSidebar';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { Language } from 'types/MultiLingualString';
import getLanguageObject from 'helpers/language';

const RequireAuth: React.FC<{
    children: React.ReactNode,
    userLoginMicrosoft: Function,
    permissions: Permissions,
    isShareLink?: boolean,
    requiredPermissions?: string[],
    currentLanguage: Language

}> = ({ children, userLoginMicrosoft, permissions, isShareLink, requiredPermissions, currentLanguage }) => {
    const lang = getLanguageObject(currentLanguage);

    if (isShareLink) return children;

    if (requiredPermissions && requiredPermissions.length > 0) {
        const hasRequiredPermissions = requiredPermissions.every(permission => {
            // Check if the permission is in the permissions object by using the permission as a key
            return permissions[permission as keyof Permissions];
        });
        if (!hasRequiredPermissions) return (
            <MainLayout>
                <div>
                    <h1>{lang.noPermissionHeader}</h1>
                    <p>{lang.noPermissionText}</p>
                </div>
            </MainLayout>
        );
    }

    return (
        <RequireAuthAD userLoginMicrosoft={userLoginMicrosoft} isShareLink={isShareLink}>
            {children}
        </RequireAuthAD>
    )
}

const RequireAuthAD: React.FC<{ children: React.ReactNode, userLoginMicrosoft: Function, isShareLink?: boolean }> = ({ children, userLoginMicrosoft, isShareLink }) => {
    const { accounts } = useMsal();

    const tokenRequest = {
        account: accounts[0],
        scopes: [],
        forceRefresh: true,
    }

    const {
        login,
        acquireToken,
        result,
        error
    } = useMsalAuthentication(InteractionType.Redirect, tokenRequest);

    useEffect(() => {
        if (accounts.length === 0) {
            login();
            return
        };
        let recheckTokenTimeInMs = 60000;

        const refreshToken = () => {
            console.log('Checking if token needs to be refreshed', result && (result?.idTokenClaims as any), result && (result?.idTokenClaims as any) && (result?.idTokenClaims as any).exp, result && (result?.idTokenClaims as any) && (result?.idTokenClaims as any).exp && (result?.idTokenClaims as any).exp - 300 > Date.now() / 1000);
            // If we have a valid token, and it expires in more than 5 minutes, return
            if (result && (result?.idTokenClaims as any) && (result?.idTokenClaims as any).exp && (result?.idTokenClaims as any).exp - 300 > Date.now() / 1000) {
                //log the expire time as a date time notation in the console
                console.log('Token expires at: ' + new Date((result?.idTokenClaims as any).exp * 1000).toLocaleString());
                return;
            }

            console.log('Refreshing token');

            // Acquire an access token
            acquireToken().then((response) => {
                if (!response) return;
                userLoginMicrosoft(response.idToken)
            }).catch(async (e) => {
                // Catch interaction_required errors and call interactive method to resolve
                if (e instanceof InteractionRequiredAuthError) {
                    login();
                }
                throw e;
            });
        }

        const refreshTokenInterval = setInterval(() => {
            // If we have a valid token, and it expires in more than 5 minutes, return
            if (result && (result?.idTokenClaims as any) && (result?.idTokenClaims as any).exp && (result?.idTokenClaims as any).exp - 300 > Date.now() / 1000) {
                //log the expire time as a date time notation in the console
                console.log('Token expires at: ' + new Date((result?.idTokenClaims as any).exp * 1000).toLocaleString());
                return;
            }

            refreshToken();
        }, recheckTokenTimeInMs);

        refreshToken();

        return () => {
            clearInterval(refreshTokenInterval);
        };
    }, [userLoginMicrosoft]);

    const isAuthenticated = error == null && result != null;

    if (!isAuthenticated) return (
        <MainLayoutNoSidebar>
            <AwaitLoading />
        </MainLayoutNoSidebar>
    );

    return <>{children}</>
};

const mapStateToProps = (state: ReduxStore) => ({
    isShareLink: state.user.isShareLink,
    permissions: state.user.permissions,
    currentLanguage: state.language.currentLanguage,
});

const mapDispatchToProps = { userLoginMicrosoft };
const ConnectedRequireAuth = connect(
    mapStateToProps,
    mapDispatchToProps
)(RequireAuth);

export default ConnectedRequireAuth;


const RequireAdminComponent: React.FC<{ children: React.ReactElement, permissions: Permissions }> = ({ children, permissions }) => {
    if (!permissions.canDuplicateOrganisation) return null;

    return children;
}

export const RequireAdmin = connect(
    (state: ReduxStore) => ({ permissions: state.user.permissions }),
    {}
)(RequireAdminComponent);
