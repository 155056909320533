enum UserActions {
    AddError = 'AddError',
    Login = 'Login',
    Logout = 'Logout',
    SetCurrentOrganisation = 'SetCurrentOrganisation',
    SetUsersForCurrentOrganisation = 'SetUsersForCurrentOrganisation',
    ClearErrors = 'ClearErrors',
    AddManageOrgError = 'AddManageOrgError',
    ClearManageOrgError = 'ClearManageOrgError',
    AddOrganisation = 'AddOrganisation',
    UpdateOrganisation = 'UpdateOrganisation',
    UpdateOrganisations = 'UpdateOrganisations',
    UpdateUnitOrder = 'UpdateUnitOrder',
    UpdateOrganisationUnitOrder = 'UpdateOrganisationUnitOrder',
    DeleteOrganisation = "DeleteOrganisation",
    UpdatePermissions = "UpdatePermissions",
    UpdateOrganisationUsers = "UpdateOrganisationUsers",
    UpdateRole = "UpdateRole",
    UpdateAllUsers = "UpdateAllUsers",
    UpdateSelectedUserOrganisation = "UpdateSelectedUserOrganisation",
    UpdateAllOrganisations = "UpdateAllOrganisations",
    UpdateUser = "UpdateUser",
}

export default UserActions;
