import { MultiLingualString } from "../../types/MultiLingualString";
import {en} from "../../helpers/language/en";
import {nl} from "../../helpers/language/nl";
import {de} from "../../helpers/language/de";
import {be_nl} from "../../helpers/language/be_nl";
import {be_fr} from "../../helpers/language/be_fr";
import {fr} from "../../helpers/language/fr";
import {pl} from "../../helpers/language/pl";
import {es} from "../../helpers/language/es";

export const LanguageErrorTypes = {
    getRemoteLanguages: 'getRemoteLanguages',
} as const

export type LanguageErrorTypeKeys = keyof typeof LanguageErrorTypes;

export const LanguageErrors: { [key in LanguageErrorTypeKeys]: MultiLingualString } = {
    getRemoteLanguages: {
        'en-EN': en.getRemoteLanguages,
        'nl-NL': nl.getRemoteLanguages,
        'de-DE': de.getRemoteLanguages,
        'nl-BE': be_nl.getRemoteLanguages,
        'fr-BE': be_fr.getRemoteLanguages,
        'fr-FR': fr.getRemoteLanguages,
        'pl-PL': pl.getRemoteLanguages,
        'es-ES': es.getRemoteLanguages,
    }
};

export const getLanguageErrorMessage = (errorName: LanguageErrorTypeKeys,  language: keyof MultiLingualString) =>  {
    const message = LanguageErrors[errorName];
    return message[language] || message['en-EN'];
}
