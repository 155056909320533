import { MultiLingualString } from "../../types/MultiLingualString";
import {en} from "../../helpers/language/en";
import {nl} from "../../helpers/language/nl";
import {de} from "../../helpers/language/de";
import {be_nl} from "../../helpers/language/be_nl";
import {be_fr} from "../../helpers/language/be_fr";
import {fr} from "../../helpers/language/fr";
import {pl} from "../../helpers/language/pl";
import {es} from "../../helpers/language/es";

export const AllocatorErrorTypes = {
    loadQuestions: 'loadQuestions',
    loadValidatedProfiles: 'loadValidatedProfiles',
    loadLevels: 'loadLevels',
    loadTalentpaths: 'loadTalentpaths',
    createQuestion: 'createQuestion',
    updateQuestion: 'updateQuestion',
    deleteQuestion: 'deleteQuestion',
    createAnswer: 'createAnswer',
    updateAnswer: 'updateAnswer',
    deleteAnswer: 'deleteAnswer',
    createValidatedProfile: 'createValidatedProfile',
    updateValidatedProfile: 'updateValidatedProfile',
    deleteValidatedProfile: 'deleteValidatedProfile',
} as const

export type AllocatorErrorTypeKeys = keyof typeof AllocatorErrorTypes;

export const AllocatorErrors: { [key in AllocatorErrorTypeKeys]: MultiLingualString } = {
    loadQuestions: {
        'en-EN': en.loadQuestions,
        'nl-NL': nl.loadQuestions,
        'de-DE': de.loadQuestions,
        'nl-BE': be_nl.loadQuestions,
        'fr-BE': be_fr.loadQuestions,
        'fr-FR': fr.loadQuestions,
        'pl-PL': pl.loadQuestions,
        'es-ES': es.loadQuestions,
    },
    loadValidatedProfiles: {
        'en-EN': en.loadValidatedProfiles,
        'nl-NL': nl.loadValidatedProfiles,
        'de-DE': de.loadValidatedProfiles,
        'nl-BE': be_nl.loadValidatedProfiles,
        'fr-BE': be_fr.loadValidatedProfiles,
        'fr-FR': fr.loadValidatedProfiles,
        'pl-PL': pl.loadValidatedProfiles,
        'es-ES': es.loadValidatedProfiles,
    },
    loadLevels: {
        'en-EN': en.loadLevels,
        'nl-NL': nl.loadLevels,
        'de-DE': de.loadLevels,
        'nl-BE': be_nl.loadLevels,
        'fr-BE': be_fr.loadLevels,
        'fr-FR': fr.loadLevels,
        'pl-PL': pl.loadLevels,
        'es-ES': es.loadLevels,
    },
    loadTalentpaths: {
        'en-EN': en.loadTalentpaths,
        'nl-NL': nl.loadTalentpaths,
        'de-DE': de.loadTalentpaths,
        'nl-BE': be_nl.loadTalentpaths,
        'fr-BE': be_fr.loadTalentpaths,
        'fr-FR': fr.loadTalentpaths,
        'pl-PL': pl.loadTalentpaths,
        'es-ES': es.loadTalentpaths,
    },
    createQuestion: {
        'en-EN': en.createQuestion,
        'nl-NL': nl.createQuestion,
        'de-DE': de.createQuestion,
        'nl-BE': be_nl.createQuestion,
        'fr-BE': be_fr.createQuestion,
        'fr-FR': fr.createQuestion,
        'pl-PL': pl.createQuestion,
        'es-ES': es.createQuestion,
    },
    updateQuestion: {
        'en-EN': en.updateQuestion,
        'nl-NL': nl.updateQuestion,
        'de-DE': de.updateQuestion,
        'nl-BE': be_nl.updateQuestion,
        'fr-BE': be_fr.updateQuestion,
        'fr-FR': fr.updateQuestion,
        'pl-PL': pl.updateQuestion,
        'es-ES': es.updateQuestion,
    },
    deleteQuestion: {
        'en-EN': en.deleteQuestion,
        'nl-NL': nl.deleteQuestion,
        'de-DE': de.deleteQuestion,
        'nl-BE': be_nl.deleteQuestion,
        'fr-BE': be_fr.deleteQuestion,
        'fr-FR': fr.deleteQuestion,
        'pl-PL': pl.deleteQuestion,
        'es-ES': es.deleteQuestion,
    },
    createAnswer: {
        'en-EN': en.createAnswer,
        'nl-NL': nl.createAnswer,
        'de-DE': de.createAnswer,
        'nl-BE': be_nl.createAnswer,
        'fr-BE': be_fr.createAnswer,
        'fr-FR': fr.createAnswer,
        'pl-PL': pl.createAnswer,
        'es-ES': es.createAnswer,
    },
    updateAnswer: {
        'en-EN': en.updateAnswer,
        'nl-NL': nl.updateAnswer,
        'de-DE': de.updateAnswer,
        'nl-BE': be_nl.updateAnswer,
        'fr-BE': be_fr.updateAnswer,
        'fr-FR': fr.updateAnswer,
        'pl-PL': pl.updateAnswer,
        'es-ES': es.updateAnswer,
    },
    deleteAnswer: {
        'en-EN': en.deleteAnswer,
        'nl-NL': nl.deleteAnswer,
        'de-DE': de.deleteAnswer,
        'nl-BE': be_nl.deleteAnswer,
        'fr-BE': be_fr.deleteAnswer,
        'fr-FR': fr.deleteAnswer,
        'pl-PL': pl.deleteAnswer,
        'es-ES': es.deleteAnswer,
    },
    createValidatedProfile: {
        'en-EN': en.createValidatedProfile,
        'nl-NL': nl.createValidatedProfile,
        'de-DE': de.createValidatedProfile,
        'nl-BE': be_nl.createValidatedProfile,
        'fr-BE': be_fr.createValidatedProfile,
        'fr-FR': fr.createValidatedProfile,
        'pl-PL': pl.createValidatedProfile,
        'es-ES': es.createValidatedProfile,
    },
    updateValidatedProfile: {
        'en-EN': en.updateValidatedProfile,
        'nl-NL': nl.updateValidatedProfile,
        'de-DE': de.updateValidatedProfile,
        'nl-BE': be_nl.updateValidatedProfile,
        'fr-BE': be_fr.updateValidatedProfile,
        'fr-FR': fr.updateValidatedProfile,
        'pl-PL': pl.updateValidatedProfile,
        'es-ES': es.updateValidatedProfile,
    },
    deleteValidatedProfile: {
        'en-EN': en.deleteValidatedProfile,
        'nl-NL': nl.deleteValidatedProfile,
        'de-DE': de.deleteValidatedProfile,
        'nl-BE': be_nl.deleteValidatedProfile,
        'fr-BE': be_fr.deleteValidatedProfile,
        'fr-FR': fr.deleteValidatedProfile,
        'pl-PL': pl.deleteValidatedProfile,
        'es-ES': es.deleteValidatedProfile,
    },
};

export const getAllocatorErrorMessage = (errorName: AllocatorErrorTypeKeys,  language: keyof MultiLingualString) =>  {
    const message = AllocatorErrors[errorName];
    return message[language] || message['en-EN'];
}
