import React from 'react';
import { IconProps } from './icon.types';

const FileCheck: React.FC<IconProps> = () => (
    <svg
        width="15"
        height="21"
        viewBox="0 0 15 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.5 18.625H12.5C12.8125 18.625 13.125 18.3516 13.125 18V6.75H10C9.29688 6.75 8.75 6.20312 8.75 5.5V2.375H2.5C2.14844 2.375 1.875 2.6875 1.875 3V18C1.875 18.3516 2.14844 18.625 2.5 18.625ZM2.5 0.5H8.94531C9.60938 0.5 10.2344 0.773438 10.7031 1.24219L14.2578 4.79688C14.7266 5.26562 15 5.89062 15 6.55469V18C15 19.4062 13.8672 20.5 12.5 20.5H2.5C1.09375 20.5 0 19.4062 0 18V3C0 1.63281 1.09375 0.5 2.5 0.5ZM11.2891 10.9688L7.10938 15.1484C6.75781 15.5 6.13281 15.5 5.78125 15.1484L3.71094 13.0391C3.32031 12.6875 3.32031 12.1016 3.71094 11.7109C4.0625 11.3594 4.64844 11.3594 5.03906 11.7109L6.44531 13.1562L9.96094 9.64062C10.3125 9.28906 10.8984 9.28906 11.2891 9.64062C11.6406 9.99219 11.6406 10.6172 11.2891 10.9688Z"
            fill="black"
        />
    </svg>
);

export default FileCheck;
