import { MultiLingualString } from 'types/MultiLingualString';
import UserActions from './user.constants';
import { MatrixLevelNotesObject, Organisation, OrganisationUser } from 'types/Organisation';

export const levelNotesFallback: MatrixLevelNotesObject = {
    "1a": "",
    "1b": "",
    "2": "",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "",
    "9": "",
    "10": "",
    "11": "",
    "12": "",
    "p1": "",
    "p2": "",
    "p3": "",
    "p4": "",
    "title": ""
}

export interface Permissions {
    canUpdateAlternativeValuationMethod: boolean;
    canEditJobValidatedProfile: boolean;
    canProvideStyrCompetencesAccess: boolean;
    canEditOrganisationUnits: boolean;
    canEditDifferentiatingFactors: boolean;
    canDeleteResult: boolean;
    canUpdateStatusResult: boolean;
    canEditJobMatrix: boolean;
    canManageOrganisation: boolean;
    canInviteUser: boolean;
    canInviteExpert: boolean;
    canInvitePartnerUser: boolean;
    canInvitePartnerExpert: boolean;
    canInviteAdministrator: boolean;
    canRemoveUserFromOrganisation: boolean;
    canViewAllUsers: boolean;
    canCreateOrganisation: boolean;
    canDeleteOrganisation: boolean;
    canDuplicateOrganisation: boolean;
    canNotAccessLevels: string[];
    canOnlyAccessOrganisationUnit: string;
    canToggleStyrTmaNormedCompetencies: boolean;
    canViewExtraFields: {
        extraField1: boolean;
        extraField2: boolean;
        extraField3: boolean;
    }
}

export interface OrganisationOptions {
    tmaLink?: string;
    jobFamilies?: boolean;
}

export default interface UserState {
    errors: string[];
    manageOrganisationError: string;
    jwt?: string;
    isShareLink?: boolean;
    share_link_settings?: {
        show_styr_profile: boolean;
    }
    user?: string;
    role?: string;
    organisations: Organisation[];
    currentOrganisation?: Organisation;
    permissions: Permissions;
    options: {
        levels: LevelsOptionsArray;
    }
    passwordEmailSent: boolean;
    passwordHasReset: boolean;
    allUsers: OrganisationUser[];
    allUsersTotalCount: number;
    selectedOrganisationUser?: OrganisationUser;
    allOrganisations: Organisation[];
}

interface UserDispatch<ActionType, PayloadType> {
    type: ActionType;
    payload: PayloadType;
}

type UserAddError = UserDispatch<UserActions.AddError, { error: string }>;

type UserPermissionsUpdate = UserDispatch<UserActions.UpdatePermissions, { permissions: Permissions }>;

type UserLogin = UserDispatch<UserActions.Login, LoginPayload>;
type UserSetCurrentOrganisation = UserDispatch<
    UserActions.SetCurrentOrganisation,
    { id: string }
>;
type UserSetUsersForCurrentOrganisation = UserDispatch<
    UserActions.SetUsersForCurrentOrganisation,
    { users: OrganisationUser[] }
>;
type AddManageOrgErrorState = UserDispatch<
    UserActions.AddManageOrgError,
    { error: string }
>;
type ClearManageOrgErrorState = UserDispatch<
    UserActions.ClearManageOrgError,
    {}
>;
type UserLogout = UserDispatch<UserActions.Logout, {}>;
type UserClearErrors = UserDispatch<UserActions.ClearErrors, {}>;

type UserAddOrganisation = UserDispatch<
    UserActions.AddOrganisation,
    { organisation: Organisation }
>;
type UserUpdateOrganisation = UserDispatch<
    UserActions.UpdateOrganisation,
    { organisation: Organisation }
>;
type UserUpdateOrganisations = UserDispatch<
    UserActions.UpdateOrganisations,
    { organisations: Organisation[], isSharedLink: boolean }
>;
type UpdateUnitOrder = UserDispatch<
    UserActions.UpdateUnitOrder,
    {
        organisationUnit: string;
        oldIndex: number;
        newIndex: number;
    }
>;
type UpdateOrganisationUnitOrder = UserDispatch<
    UserActions.UpdateOrganisationUnitOrder,
    {
        oldIndex: number;
        newIndex: number;
    }
>;
type DeleteOrganisation = UserDispatch<
    UserActions.DeleteOrganisation,
    { organisationId: string }
>;

type UpdateRole = UserDispatch<
    UserActions.UpdateRole,
    { role: string }
>;

type UpdateAllUsers = UserDispatch<
    UserActions.UpdateAllUsers,
    {
        allUsers: OrganisationUser[];
        allUsersTotalCount: number
    }
>;

type UpdateAllOrganisations = UserDispatch<
    UserActions.UpdateAllOrganisations,
    { organisations: Organisation[] }
>;

type UpdateSelectedUserOrganisation = UserDispatch<
    UserActions.UpdateSelectedUserOrganisation,
    { selectedOrganisationUser: OrganisationUser }
>
export type UserDispatches =
    | UserAddError
    | UserPermissionsUpdate
    | UserAddOrganisation
    | AddManageOrgErrorState
    | ClearManageOrgErrorState
    | UserLogin
    | UserLogout
    | UserSetCurrentOrganisation
    | UserSetUsersForCurrentOrganisation
    | UserClearErrors
    | UserUpdateOrganisation
    | UserUpdateOrganisations
    | UpdateUnitOrder
    | DeleteOrganisation
    | UpdateOrganisationUnitOrder
    | UpdateRole
    | UpdateAllUsers
    | UpdateSelectedUserOrganisation
    | UpdateAllOrganisations;

export interface ShareLinkSettings {
    show_styr_profile: boolean;
}

interface LoginPayload {
    [x: string]: any;
    jwt: string;
    isShareLink: boolean;
    user: string;
    role: string;
    organisations: Organisation[];
    permissions: Permissions;
    options: {
        levels: LevelsOptionsArray;
    }
}

export interface LoginShareLinkResponse {
    "name": string;
    "url": string;
    "organisationId": string;
    "organisationUnitId": string;
    "showStyrProfile": boolean;
    "showExtraColumn1": boolean;
    "showExtraColumn2": boolean;
    "showExtraColumn3": boolean;
    "token": string;
    "shareLinkStyrLevels": string[]
}

export interface LevelsOptionsArray {
    "level_1a": LevelOptions,
    "level_1b": LevelOptions,
    "level_2": LevelOptions,
    "level_3": LevelOptions,
    "level_4": LevelOptions,
    "level_5": LevelOptions,
    "level_6": LevelOptions,
    "level_7": LevelOptions,
    "level_8": LevelOptions,
    "level_9": LevelOptions,
    "level_10": LevelOptions,
    "level_11": LevelOptions,
    "level_12": LevelOptions,
    "level_p1": LevelOptions,
    "level_p2": LevelOptions,
    "level_p3": LevelOptions,
    "level_p4": LevelOptions,
}

export interface LevelOptions {
    "problem_solving_focus": MultiLingualString,
    "education": MultiLingualString,
    "problem_solving_capability": MultiLingualString,
    "planning_horizon": MultiLingualString,
    "salary": MultiLingualString,
}
