import styled from 'styled-components';

export const AdminUserManagementSingleContainer = styled.div`
    margin-top: 50px;
    background-color: white;
`
export const AdminUserManagementContainer = styled.div`
    background-color: white;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 300px);
    overflow-x: auto;
`

export const SingleAdminUserManagementContainer = styled.div`
    margin-top: 30px;
    padding: 30px;
    background: white;
    overflow: auto;
    height: calc(100vh - 280px);
    padding-left: 4em;
`


export const SingleAdminUserManagementInnerContainer = styled.div`
    display: flex;
`

export const SingleAdminUserManagementInnerContainerItem = styled.div`
    width: 50%;
`

export const SingleAdminUserOrganisationContainer = styled.div`
    max-height: 500px;
    overflow-y: auto;
`

export const SingleAdminUserOrganisationRow = styled.div`
    display: grid;
    grid-template-columns: minmax(auto, 1fr);
    grid-gap: 15px;
    /* display: flex; */
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 0.5em;

    &:hover {
        background-color: #F5F5F5;
    }
`

export const Column = styled.div<{ header?: boolean }>`
    flex: 1;
    height: 35px;
    line-height:35px;

    ${(props) => props.header && `
        &:hover {
        text-decoration: bold;
        font-weight: bold;
    }
    `}
`
