import { combineReducers } from 'redux';
import allocator from './allocator/allocator.reducer';
import user from './user/user.reducer';
import results from './results/results.reducer';
import navbar from './navbar/navbar.reducer';
import language from './language/language.reducer';
import updates from './updates/updates.reducer';

export default combineReducers({
    allocator,
    user,
    results,
    navbar,
    language,
    updates,
});
