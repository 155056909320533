import { getLevelIndex } from 'utils/levels';
import { Family, Result } from '../types/Result';
import { OrganisationUser, ShareLink } from 'types/Organisation';
import { Language } from 'types/MultiLingualString';
import { getLocalizedValue } from './getLocalizedValue';

const parseDate = (input: string) => {
    return new Date(input);
};

export const compareResults = (
    a: Result,
    b: Result,
    key: keyof Result,
    currentLanguage: Language
) => {
    const language = currentLanguage || 'en-EN'
    if (key === 'createdOn') {
        const dateA = parseDate(a[key]);
        const dateB = parseDate(b[key]);
        if (dateA.getTime() < dateB.getTime()) return -1;
        if (dateA.getTime() > dateB.getTime()) return 1;
        return 0;
    }

    if (key === 'validatedProfile') {
        const x = a[key].talentPath.key;
        const y = b[key].talentPath.key;
        if (!x) {
            return -1;
        }
        if (!y) {
            return 1;
        }
        return x < y ? 1 : -1;
    }

    if (key === 'organisationUnit' || key === 'unit') {
        const x = getLocalizedValue(a[key], language);
        const y = getLocalizedValue(b[key], language);

        if (!x) return -1;
        if (!y) return 1;
        return x.localeCompare(y);
    }

    if (key === 'jobInformations') {
        const x = a[key];
        const y = b[key];
        if (!x) {
            return -1;
        }
        if (!y) {
            return 1;
        }
        return x < y ? 1 : -1;
    }

    if (key === 'level') {
        return (
            getLevelIndex(a.level.toLowerCase()) -
            getLevelIndex(b.level.toLowerCase())
        );
    }

    if (key === 'jobCompetences') {
        return 0;
    }

    if (key === 'alternativeLevel') {
        return 0;
    }

    if (key === 'tags') {
        return 0;
    }

    const x = a[key];
    const y = b[key];

    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
};

export const compareFamily = (a: Family, b: Family, key: keyof Family) => {
    if (key === 'lastModified') {
        const dateA = parseDate(a.lastModified);
        const dateB = parseDate(b.lastModified);
        if (dateA.getTime() < dateB.getTime()) {
            return 1;
        }
        if (dateA.getTime() > dateB.getTime()) {
            return -1;
        }

        return 0;
    }

    if (key === 'sortOrder') {
        if (a.sortOrder < b.sortOrder) return -1;
        if (a.sortOrder > b.sortOrder) return 1;
        return 0;
    }

    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
};

export const compareUsers = (
    a: OrganisationUser,
    b: OrganisationUser,
    key: keyof OrganisationUser
) => {
    const x = a[key];
    const y = b[key];

    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
};

export const compareShareLinks = (
    a: ShareLink,
    b: ShareLink,
    key: 'name' | 'id'
) => {
    const x = a[key];
    const y = b[key];

    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
};
