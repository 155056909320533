import { OrganisationLogo } from 'containers/MatrixBar/MatrixBar.components';
import ConnectedMatrixBar from 'containers/MatrixBar/MatrixBar.connector';
import { ConnectedJobMatrixCellWithJobList } from 'containers/MatrixBoxWithJobs/MatrixBoxWithJobs';
import {
    Matrix,
    MatrixHeader,
    PrintingContainer,
    PrintingInnerContainer,
} from 'containers/MatrixContainer/MatrixContainer';
import ConnectedMatrixContent from 'containers/MatrixContent/MatrixContent.connector';
import { MatrixDataContext } from 'containers/MatrixContextProvider/MatrixContextProvider';
import ScrollContextProvider from 'containers/ScrollToJobContainer/ScrollToJobContainer';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { StyrLogo } from 'sections/JobFamilyMatrix/JobFamilyMatrix.styling';
import { AwaitIsPrinting } from './JobMatrixResults.components';
import { useJobMatrixHelper } from './JobMatrixResults.helpers';
import {
    Container,
    Cover,
    Footer,
    MatrixContainer,
} from './JobMatrixResults.styles';
import JobMatrixResultsProps from './JobMatrixResults.types';
import { API_URL } from 'config';
import { DownloadTypes } from 'containers/MatrixBar/MatrixBar.types';
import { useManageMatrixImage } from 'containers/MatrixBar/useManageMatrixImage';

const JobMatrixResults: React.FC<JobMatrixResultsProps> = ({
    resultsGetResults,
    currentOrganisation,
    fullScreen,
    remoteData,
    language,
    updateOrganisationRequest,
    loadValidatedProfiles,
    getRemoteLanguages,
    levels,
    isShareLink,
    matrixView,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    // This is a hook that is used to manage the image versions of the job matrix.
    // It is used to create new versions of the image and download existing versions.
    const {
        printing,
        handleDownloadMatrix
    } = useManageMatrixImage({
        currentOrganisation,
        ref,
    });

    useEffect(() => {
        loadValidatedProfiles();
    }, [loadValidatedProfiles]);

    useEffect(() => {
        getRemoteLanguages();
    }, []);

    useEffect(() => {
        updateOrganisationRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOrganisationRequest, currentOrganisation?.id]);

    const { localData, controllerHandlers } = useJobMatrixHelper();

    const { currentCellData, editMode } = useContext(MatrixDataContext);

    // Load the latest results from the server.
    useEffect(() => {
        resultsGetResults();
    }, [resultsGetResults, currentOrganisation]);

    if (!currentOrganisation) {
        return null;
    }

    const logo = currentOrganisation?.imageUrl
        ? API_URL + currentOrganisation?.imageUrl
        : require('assets/images/logo.png');

    const renderPrintMatrix = () => (
        <Cover>
            <PrintingContainer ref={ref} isPrinting={printing}>
                <PrintingInnerContainer isPrinting={printing}>
                    <Matrix isPrinting={printing}>
                        <MatrixHeader>
                            <OrganisationLogo
                                src={logo}
                                alt="Organisation logo"
                            />
                        </MatrixHeader>
                        <ConnectedMatrixContent
                            levels={levels}
                            editMode={editMode}
                            language={language}
                            printing={printing}
                            fullScreen={fullScreen}
                            matrixView={matrixView}
                            localData={localData}
                            remoteData={remoteData}
                            controllerHandlers={controllerHandlers}
                        />
                        {printing && (
                            <Footer>
                                Powered by{' '}
                                <StyrLogo
                                    src={require('assets/images/logo.png')}
                                />
                            </Footer>
                        )}
                    </Matrix>
                </PrintingInnerContainer>
            </PrintingContainer>
        </Cover>
    );

    return (
        <>
            {printing && <AwaitIsPrinting />}
            {renderPrintMatrix()}
            <Container isFullscreen={fullScreen}>
                <MatrixContainer isFullscreen={fullScreen}>
                    <MatrixHeader>
                        <OrganisationLogo
                            src={
                                currentOrganisation?.imageUrl
                                    ? API_URL + currentOrganisation?.imageUrl
                                    : require('assets/images/logo.png')
                            }
                            alt="Organisation logo"
                        />
                    </MatrixHeader>
                    <ScrollContextProvider>
                        <ConnectedMatrixContent
                            levels={levels}
                            editMode={editMode}
                            language={language}
                            fullScreen={fullScreen}
                            matrixView={matrixView}
                            localData={localData}
                            remoteData={remoteData}
                            controllerHandlers={controllerHandlers}
                        />
                    </ScrollContextProvider>
                </MatrixContainer>
                <ConnectedMatrixBar
                    isFullscreen={fullScreen}
                    isShareLink={Boolean(isShareLink)}
                    currentView={matrixView}
                    levels={levels}
                    language={language}
                    remoteData={remoteData}
                    localData={localData}
                    changeVisibilityTalentpath={
                        controllerHandlers.changeVisibilityTalentpath
                    }
                    changeVisibilityLevel={
                        controllerHandlers.changeVisibilityRow
                    }
                    changeVisibilityColumn={
                        controllerHandlers.changeVisibilityColumn
                    }
                    changeFilterTag={controllerHandlers.changeFilterTag}
                    handleDownloadMatrix={handleDownloadMatrix}
                />
            </Container>
            {currentCellData && (
                <ConnectedJobMatrixCellWithJobList
                    level={currentCellData.level}
                    handleEditJob={currentCellData.handleEditJob}
                    jobIDs={currentCellData.jobIDs}
                />
            )}
        </>
    );
};

export default JobMatrixResults;
