import ArrowRightIcon from 'components/Icons/arrowRight';
import SearchIcon from 'components/Icons/search';
import LevelLabel from 'components/LevelLabel/LevelLabel';
import { State } from 'components/TableElements/TableElements';
import { H3 } from 'components/Typography/Typography';
import { useNavigate } from 'helpers/useNavigation';
import moment from 'moment';
import React from 'react';
import Routes from 'routes/Routes.types';
import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
    background-color: white;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 275px);
    overflow-x: auto;

    box-sizing: border-box;
    position: relative;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: minmax(200px, 1fr) 78px 200px 200px 200px 100px 44px 24px;
    grid-gap: 15px;
    align-items: center;
    cursor: pointer;
`;

export const TableItemContainer = styled.div<{ canCheck?: boolean; sticky?: boolean }>`
    padding: 5px 15px;
    display: grid;
    grid-template-columns: ${props => props.canCheck && '20px'} 1fr;
    grid-gap: 15px;
    align-items: center;
    height: 54px;
    box-sizing: border-box;

    ${props => props.sticky ? css`
        padding: 0 15px;
        position: sticky;
        top: 0;
        z-index: 1;
    ` : `    &:hover {
        background-color: #f5f5f5;
    }`};
`;

const SingleTableItem = styled.div<{ onClick?: () => void }>`
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: ${props => props.onClick ? 'pointer' : 'default'};
`;
export const TableItemIcon = styled.div`
    width: 44px;
    height: 54px;
    display: flex;
    align-items: center;
    color: #333;
    cursor: pointer;
    background: white;
`;

interface TableItemProps {
    id: string;
    status: string;
    date: string;
    role: string;
    level: string;
    organisationUnit: string;
    unit: string;
    talentPath: string;
    isSelected?: boolean;
    showCheckbox?: boolean;
    onSelectItem: (id: string, status: string) => void;
}
export const TableItem: React.FC<TableItemProps> = ({
    id,
    status,
    date,
    role,
    level,
    organisationUnit,
    unit,
    talentPath,
    isSelected,
    onSelectItem,
    showCheckbox
}) => {
    const { navigate } = useNavigate();

    return (
        <TableItemContainer canCheck={showCheckbox}>
            {showCheckbox && <div><input type='checkbox' checked={isSelected} onChange={() => onSelectItem(id, status)} /></div>}
            <Row onClick={() => navigate(Routes.ResultRoute + id)}>
                <SingleTableItem title={unit}>{role}</SingleTableItem>
                <LevelLabel level={level} />
                <SingleTableItem title={organisationUnit}>{organisationUnit}</SingleTableItem>
                <SingleTableItem title={unit}>{unit}</SingleTableItem>
                <SingleTableItem title={talentPath}>{talentPath}</SingleTableItem>
                <SingleTableItem title={date}>{moment.utc(date).local().format("DD/MM/YY")}</SingleTableItem>
                <State status={status} />
                <TableItemIcon onClick={() => navigate(Routes.ResultRoute + id)}>
                    <ArrowRightIcon color="#000"/>
                </TableItemIcon>
            </Row>
        </TableItemContainer>
    );
};

const NoItemsFoundContainer = styled.div`
    margin: 2rem auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const NotFoundIcon = styled.div`
    margin-right: .5em;
`;


export const NoItemsFound = () => {
    return (
        <NoItemsFoundContainer>
            <NotFoundIcon>
                <SearchIcon />
            </NotFoundIcon>
            <H3> Nothing found </H3>
        </NoItemsFoundContainer>
    )
}
