import styled from "styled-components";

export const AdminUserManagementContainer = styled.div`
    background-color: white;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 290px);
    overflow-x: auto;

`

export const SingleAdminUserManagementContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 150px)) minmax(300px, auto) minmax(0, 400px) minmax(0, 125px) minmax(0, 50px);
    grid-gap: 15px;
    /* display: flex; */
    align-items: center;
    cursor: pointer;
    height: 45px;
    border-bottom: 1px solid #E5E5E5;
    padding-left: 4em;
    padding-bottom: 0.5em;

    &:hover {
        background-color: #F5F5F5;
    }
`

export const Column = styled.div<{header?: boolean}>`
    flex: 1;
    cursor: pointer;
    height: 35px;
    line-height:35px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${(props) => props.header && `
        &:hover {
        text-decoration: bold;
        font-weight: bold;
    }
    `}

`

export const PaginantionContainer = styled.div`
    display: flex;
    justify-content: center;
    /* background-color: #eff4f9; */
    max-width: 60%;
    margin: 20px auto 0;
`

export const PaginationButton = styled.div<{ currentPage?: boolean; disabled?: boolean; }>`
    width: 15px;
    text-align: center;
    margin: 5px 5px;
    border-radius: 15px;
    padding: 5px 10px;
    background-color: ${(props) => props.currentPage ? "#091e3c" : "#fff"};
    color: ${(props) => props.currentPage ? "#fff" : "#091e3c"};

    cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
    user-select: none;
`

export const AdminUserManagementListContainer = styled.div`
    margin-top: 50px;
    background-color: white;
`
