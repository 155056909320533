import styled from 'styled-components';
import theme from 'utils/theme';

export const Wrapper  = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, .32);
`

export const SwitchContainer = styled.div`
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    height: 50vh;
    position: fixed;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};

    display: grid;
    grid-template-rows: auto 1fr;
`;

export const SearchContainer = styled.div`
    padding: 1em 20px .25em;
    display: grid;
    grid-template-rows: auto 60px;
    gap: 10px;
    align-items: center;
    border-radius: 8px 8px 0 0;
    background-color: #fff;

    h2{
        margin-bottom: 0;
    }
`

export const OrganisationsList = styled.div`
    margin-bottom: 0;
    padding: 1em 0;
    overflow-y: scroll;
    box-shadow: inset 0px 3px 3px 0px rgb(0 0 0 / 8%);
`

export const Container = styled.div`
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #0a1e3c;
    padding: 0 8px;
    height: 70px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;
`

export const Small = styled.small`
    font-size: 10px;
    line-height: 12px;
`

export const AvatarImage = styled.figure`
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
`

export const Image = styled.img`
    padding: 2px;
    width: 100%;
    height: auto;
`
