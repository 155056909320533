import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { updateUser, deleteUser, inviteUser, loadAllOrganisations, loadSelectedUser, deleteUserFromApplication } from 'store/user/user.actions';
import { AdminUserManagementSingle } from 'sections/Admin/AdminUserManagementSingle/AdminUserManagementSingle';

const mapStateToProps = (state: ReduxStore) => {
    return {
        user: state.user.selectedOrganisationUser,
        allOrganisations: state.user.allOrganisations,
        currentLanguage: state.language.currentLanguage,
        permissions: state.user.permissions,
    };
};
const mapDispatchToProps = { loadSelectedUser, loadAllOrganisations, updateUser, deleteUser, deleteUserFromApplication, inviteUser };

const ConnectedAdminUserManagementSingle = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUserManagementSingle);

export default ConnectedAdminUserManagementSingle;
