import UpdatesActions from './updates.constants';
import UpdatesState, { UpdatesDispatches } from './updates.types';

export const initialState: UpdatesState = {
    errors: [],
    updates: [],
};

const updatesReducer = (
    state: UpdatesState = initialState,
    action: UpdatesDispatches
) => {
    switch (action.type) {
        case UpdatesActions.AddError:
            return {
                ...state,
                errors: [...state.errors, action.payload.error],
            };
        case UpdatesActions.GetUpdates:
            return {
                ...state,
                updates: action.payload.updates,
            };
        default:
            return state;
    }
};

export default updatesReducer;
