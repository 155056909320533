import { connect } from 'react-redux';
import { AdminUserManagementList } from 'sections/Admin/AdminUserManagementList/AdminUserManagementList';
import ReduxStore from 'store/store.type';
import { loadAllOrganisations, loadPagedUsers } from 'store/user/user.actions';

const mapStateToProps = (state: ReduxStore) => {
    return {
        allUsers: state.user.allUsers,
        allUsersTotalCount: state.user.allUsersTotalCount,
        allOrganisations: state.user.allOrganisations,
        currentLanguage: state.language.currentLanguage,
    };
};
const mapDispatchToProps = {
    loadPagedUsers,
    loadAllOrganisations
};
const ConnectedAdminUserManagementList = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUserManagementList);

export default ConnectedAdminUserManagementList;
