import styled from 'styled-components';
import { ActiveSidebarItemProps } from './SidebarItem.types';


export const SidebarItemContainer = styled.div<ActiveSidebarItemProps>`
    margin: 4px;
    padding: 6px;
    color: white;
    font-size: 1em;
    font-weight: 500;
    text-decoration: none;
    display: grid;
    grid-template-columns: ${props => props.sidebarOpen ? "24px 1fr" : "24px"};
    align-items: center;
    background-color: ${props => (props.active ? '#3b4b63' : 'transparent')};
    position: relative;

    margin-left: ${props => props.isSubitem && props.sidebarOpen? "40px" : "4px"};

    :hover {
        background-color: #3b4b63;
        cursor: pointer;
    }

    &:before {
        content: '';
        position: absolute;
        right: 0px;
        width: 3px;
        height: 100%;
        background-color: ${props => (props.active ? '#f06e64' : 'transparent')};
    }
`;

export const IconContainer = styled.div`
    overflow: visible;
    vertical-align: -0.225em;
    font-size: 1.25em;
    line-height: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;

    width: 24px;
    position: relative;
`;

export const TextContainer = styled.div`
    padding-left: .75em;
    min-height: 24px;
    font-size: 1em;
    display: flex;
    align-items: center;
`;

export const SidebarItemsDropdownContainer = styled(SidebarItemContainer)`
    height: 24px;
    border-color: transparent;
`;

export const SidebarDivider = styled.div`
    margin: 20px auto;
    height: 1px;
    width: calc(100% - 40px);

    background-color: #505050;
`;

export const UpdateFeedback = styled.div`
    width: 12px;
    height: 12px;

    border-radius: 24px;
    background-color: red;

    position: absolute;
    top: -4px;
    right: -4px;
`
