import NavbarActions from './navbar.constants';
import NavbarState, { NavbarDispatches } from './navbar.types';

export const initialState: NavbarState = {
    errors: [],
    activeNavbarItem: 'Dashboard',
    sidebarOpen: true,
};

const navbarReducer = (
    state: NavbarState = initialState,
    action: NavbarDispatches
) => {
    switch (action.type) {
        case NavbarActions.AddError:
            return {
                ...state,
                errors: [...state.errors, action.payload.error],
            };
        case NavbarActions.ToggleNavbar:
            return {
                ...state,
                sidebarOpen: !state.sidebarOpen,
            };
        case NavbarActions.SetActiveNavbarItem:
            return {
                ...state,
                activeNavbarItem: action.payload.activeNavbarItem,
            };
        default:
            return state;
    }
};

export default navbarReducer;
