import { MultiLingualString } from "../../types/MultiLingualString";
import {en} from "../../helpers/language/en";
import {nl} from "../../helpers/language/nl";
import {de} from "../../helpers/language/de";
import {be_nl} from "../../helpers/language/be_nl";
import {be_fr} from "../../helpers/language/be_fr";
import {fr} from "../../helpers/language/fr";
import {pl} from "../../helpers/language/pl";
import {es} from "../../helpers/language/es";

export const UserErrorTypes = {
    updateOrganisationRequest: 'updateOrganisationRequest',
    userSharedLinkLogin: 'userSharedLinkLogin',
    updateOrganisationUsers: 'updateOrganisationUsers',
    updateOrganisationsRequest: 'updateOrganisationsRequest',
    loadAllOrganisationsRequest: 'loadAllOrganisationsRequest',
    loadSelectedUserRequest: 'loadSelectedUserRequest',
    updateUserRequest: 'updateUserRequest',
    loadPagedUserRequest: 'loadPagedUserRequest',
    changeRole: 'changeRole',
    inviteUser: 'inviteUser',
    invitePartner: 'invitePartner',
    deleteUser: 'deleteUser',
    deleteOrganisation: 'deleteOrganisation',
    duplicateOrganisation: 'duplicateOrganisation',
    createNewOrganisation: 'createNewOrganisation',
    updateOrganisationValuation: 'updateOrganisationValuation',
    updateOrganisationTMALink: 'updateOrganisationTMALink',
    updateOrganisationJobFamilies: 'updateOrganisationJobFamilies',
    updateOrganisationMatrixes: 'updateOrganisationMatrixes',
    updateOrganisationName: 'updateOrganisationName',
    updateResultInFamily: 'updateResultInFamily',
    addOrganisationUnit: 'addOrganisationUnit',
    updateOrganisationUnit: 'updateOrganisationUnit',
    deleteOrganisationUnit: 'deleteOrganisationUnit',
    addUnit: 'addUnit',
    deleteUnit: 'deleteUnit',
    updateUnit: 'updateUnit',
    updateUnitsAndOrganisationUnitsOrder: 'updateUnitsAndOrganisationUnitsOrder',
    updateLevelNotes: 'updateLevelNotes',
    uploadOrganisationImage: 'uploadOrganisationImage',
    createShareLink: 'createShareLink',
    updateShareLink: 'updateShareLink',
    deleteShareLink: 'deleteShareLink',
    updatePermissions: 'updatePermissions',
    getRole: 'getRole',
} as const

export type UserErrorTypeKeys = keyof typeof UserErrorTypes;

export const UserErrors: { [key in UserErrorTypeKeys]: MultiLingualString } = {
    updateOrganisationRequest: {
        'en-EN': en.updateOrganisationRequest,
        'nl-NL': nl.updateOrganisationRequest,
        'de-DE': de.updateOrganisationRequest,
        'nl-BE': be_nl.updateOrganisationRequest,
        'fr-BE': be_fr.updateOrganisationRequest,
        'fr-FR': fr.updateOrganisationRequest,
        'pl-PL': pl.updateOrganisationRequest,
        'es-ES': es.updateOrganisationRequest,
    },
    userSharedLinkLogin: {
        'en-EN': en.userSharedLinkLogin,
        'nl-NL': nl.userSharedLinkLogin,
        'de-DE': de.userSharedLinkLogin,
        'nl-BE': be_nl.userSharedLinkLogin,
        'fr-BE': be_fr.userSharedLinkLogin,
        'fr-FR': fr.userSharedLinkLogin,
        'pl-PL': pl.userSharedLinkLogin,
        'es-ES': es.userSharedLinkLogin,
    },
    updateOrganisationUsers: {
        'en-EN': en.updateOrganisationUsers,
        'nl-NL': nl.updateOrganisationUsers,
        'de-DE': de.updateOrganisationUsers,
        'nl-BE': be_nl.updateOrganisationUsers,
        'fr-BE': be_fr.updateOrganisationUsers,
        'fr-FR': fr.updateOrganisationUsers,
        'pl-PL': pl.updateOrganisationUsers,
        'es-ES': es.updateOrganisationUsers,
    },
    updateOrganisationsRequest: {
        'en-EN': en.updateOrganisationsRequest,
        'nl-NL': nl.updateOrganisationsRequest,
        'de-DE': de.updateOrganisationsRequest,
        'nl-BE': be_nl.updateOrganisationsRequest,
        'fr-BE': be_fr.updateOrganisationsRequest,
        'fr-FR': fr.updateOrganisationsRequest,
        'pl-PL': pl.updateOrganisationsRequest,
        'es-ES': es.updateOrganisationsRequest,
    },
    changeRole: {
        'en-EN': en.changeRole,
        'nl-NL': nl.changeRole,
        'de-DE': de.changeRole,
        'nl-BE': be_nl.changeRole,
        'fr-BE': be_fr.changeRole,
        'fr-FR': fr.changeRole,
        'pl-PL': pl.changeRole,
        'es-ES': es.changeRole,
    },
    inviteUser: {
        'en-EN': en.inviteUser,
        'nl-NL': nl.inviteUser,
        'de-DE': de.inviteUser,
        'nl-BE': be_nl.inviteUser,
        'fr-BE': be_fr.inviteUser,
        'fr-FR': fr.inviteUser,
        'pl-PL': pl.inviteUser,
        'es-ES': es.inviteUser,
    },
    invitePartner: {
        'en-EN': en.invitePartner,
        'nl-NL': nl.invitePartner,
        'de-DE': de.invitePartner,
        'nl-BE': be_nl.invitePartner,
        'fr-BE': be_fr.invitePartner,
        'fr-FR': fr.invitePartner,
        'pl-PL': pl.invitePartner,
        'es-ES': es.invitePartner,
    },
    deleteUser: {
        'en-EN': en.deleteUser,
        'nl-NL': nl.deleteUser,
        'de-DE': de.deleteUser,
        'nl-BE': be_nl.deleteUser,
        'fr-BE': be_fr.deleteUser,
        'fr-FR': fr.deleteUser,
        'pl-PL': pl.deleteUser,
        'es-ES': es.deleteUser,
    },
    deleteOrganisation: {
        'en-EN': en.deleteOrganisationError,
        'nl-NL': nl.deleteOrganisationError,
        'de-DE': de.deleteOrganisationError,
        'nl-BE': be_nl.deleteOrganisationError,
        'fr-BE': be_fr.deleteOrganisationError,
        'fr-FR': fr.deleteOrganisationError,
        'pl-PL': pl.deleteOrganisationError,
        'es-ES': es.deleteOrganisationError,
    },
    duplicateOrganisation: {
        'en-EN': en.duplicateOrganisationError,
        'nl-NL': nl.duplicateOrganisationError,
        'de-DE': de.duplicateOrganisationError,
        'nl-BE': be_nl.duplicateOrganisationError,
        'fr-BE': be_fr.duplicateOrganisationError,
        'fr-FR': fr.duplicateOrganisationError,
        'pl-PL': pl.duplicateOrganisationError,
        'es-ES': es.duplicateOrganisationError,
    },
    createNewOrganisation: {
        'en-EN': en.createNewOrganisationError,
        'nl-NL': nl.createNewOrganisationError,
        'de-DE': de.createNewOrganisationError,
        'nl-BE': be_nl.createNewOrganisationError,
        'fr-BE': be_fr.createNewOrganisationError,
        'fr-FR': fr.createNewOrganisationError,
        'pl-PL': pl.createNewOrganisationError,
        'es-ES': es.createNewOrganisationError,
    },
    updateOrganisationValuation: {
        'en-EN': en.updateOrganisationValuation,
        'nl-NL': nl.updateOrganisationValuation,
        'de-DE': de.updateOrganisationValuation,
        'nl-BE': be_nl.updateOrganisationValuation,
        'fr-BE': be_fr.updateOrganisationValuation,
        'fr-FR': fr.updateOrganisationValuation,
        'pl-PL': pl.updateOrganisationValuation,
        'es-ES': es.updateOrganisationValuation,
    },
    updateOrganisationTMALink: {
        'en-EN': en.updateOrganisationTMALink,
        'nl-NL': nl.updateOrganisationTMALink,
        'de-DE': de.updateOrganisationTMALink,
        'nl-BE': be_nl.updateOrganisationTMALink,
        'fr-BE': be_fr.updateOrganisationTMALink,
        'fr-FR': fr.updateOrganisationTMALink,
        'pl-PL': pl.updateOrganisationTMALink,
        'es-ES': es.updateOrganisationTMALink,
    },
    updateOrganisationJobFamilies: {
        'en-EN': en.updateOrganisationJobFamilies,
        'nl-NL': nl.updateOrganisationJobFamilies,
        'de-DE': de.updateOrganisationJobFamilies,
        'nl-BE': be_nl.updateOrganisationJobFamilies,
        'fr-BE': be_fr.updateOrganisationJobFamilies,
        'fr-FR': fr.updateOrganisationJobFamilies,
        'pl-PL': pl.updateOrganisationJobFamilies,
        'es-ES': es.updateOrganisationJobFamilies,
    },
    updateOrganisationName: {
        'en-EN': en.updateOrganisationName,
        'nl-NL': nl.updateOrganisationName,
        'de-DE': de.updateOrganisationName,
        'nl-BE': be_nl.updateOrganisationName,
        'fr-BE': be_fr.updateOrganisationName,
        'fr-FR': fr.updateOrganisationName,
        'pl-PL': pl.updateOrganisationName,
        'es-ES': es.updateOrganisationName,
    },
    updateResultInFamily: {
        'en-EN': en.updateResultInFamily,
        'nl-NL': nl.updateResultInFamily,
        'de-DE': de.updateResultInFamily,
        'nl-BE': be_nl.updateResultInFamily,
        'fr-BE': be_fr.updateResultInFamily,
        'fr-FR': fr.updateResultInFamily,
        'pl-PL': pl.updateResultInFamily,
        'es-ES': es.updateResultInFamily,
    },
    addOrganisationUnit: {
        'en-EN': en.addOrganisationUnit,
        'nl-NL': nl.addOrganisationUnit,
        'de-DE': de.addOrganisationUnit,
        'nl-BE': be_nl.addOrganisationUnit,
        'fr-BE': be_fr.addOrganisationUnit,
        'fr-FR': fr.addOrganisationUnit,
        'pl-PL': pl.addOrganisationUnit,
        'es-ES': es.addOrganisationUnit,
    },
    updateOrganisationUnit: {
        'en-EN': en.updateOrganisationUnit,
        'nl-NL': nl.updateOrganisationUnit,
        'de-DE': de.updateOrganisationUnit,
        'nl-BE': be_nl.updateOrganisationUnit,
        'fr-BE': be_fr.updateOrganisationUnit,
        'fr-FR': fr.updateOrganisationUnit,
        'pl-PL': pl.updateOrganisationUnit,
        'es-ES': es.updateOrganisationUnit,
    },
    deleteOrganisationUnit: {
        'en-EN': en.deleteOrganisationUnit,
        'nl-NL': nl.deleteOrganisationUnit,
        'de-DE': de.deleteOrganisationUnit,
        'nl-BE': be_nl.deleteOrganisationUnit,
        'fr-BE': be_fr.deleteOrganisationUnit,
        'fr-FR': fr.deleteOrganisationUnit,
        'pl-PL': pl.deleteOrganisationUnit,
        'es-ES': es.deleteOrganisationUnit,
    },
    addUnit: {
        'en-EN': en.addUnit,
        'nl-NL': nl.addUnit,
        'de-DE': de.addUnit,
        'nl-BE': be_nl.addUnit,
        'fr-BE': be_fr.addUnit,
        'fr-FR': fr.addUnit,
        'pl-PL': pl.addUnit,
        'es-ES': es.addUnit,
    },
    deleteUnit: {
        'en-EN': en.deleteUnit,
        'nl-NL': nl.deleteUnit,
        'de-DE': de.deleteUnit,
        'nl-BE': be_nl.deleteUnit,
        'fr-BE': be_fr.deleteUnit,
        'fr-FR': fr.deleteUnit,
        'pl-PL': pl.deleteUnit,
        'es-ES': es.deleteUnit,
    },
    updateUnit: {
        'en-EN': en.updateUnit,
        'nl-NL': nl.updateUnit,
        'de-DE': de.updateUnit,
        'nl-BE': be_nl.updateUnit,
        'fr-BE': be_fr.updateUnit,
        'fr-FR': fr.updateUnit,
        'pl-PL': pl.updateUnit,
        'es-ES': es.updateUnit,
    },
    updateUnitsAndOrganisationUnitsOrder: {
        'en-EN': en.updateUnitsAndOrganisationUnitsOrder,
        'nl-NL': nl.updateUnitsAndOrganisationUnitsOrder,
        'de-DE': de.updateUnitsAndOrganisationUnitsOrder,
        'nl-BE': be_nl.updateUnitsAndOrganisationUnitsOrder,
        'fr-BE': be_fr.updateUnitsAndOrganisationUnitsOrder,
        'fr-FR': fr.updateUnitsAndOrganisationUnitsOrder,
        'pl-PL': pl.updateUnitsAndOrganisationUnitsOrder,
        'es-ES': es.updateUnitsAndOrganisationUnitsOrder,
    },
    updateLevelNotes: {
        'en-EN': en.updateLevelNotes,
        'nl-NL': nl.updateLevelNotes,
        'de-DE': de.updateLevelNotes,
        'nl-BE': be_nl.updateLevelNotes,
        'fr-BE': be_fr.updateLevelNotes,
        'fr-FR': fr.updateLevelNotes,
        'pl-PL': pl.updateLevelNotes,
        'es-ES': es.updateLevelNotes,
    },
    uploadOrganisationImage: {
        'en-EN': en.uploadOrganisationImage,
        'nl-NL': nl.uploadOrganisationImage,
        'de-DE': de.uploadOrganisationImage,
        'nl-BE': be_nl.uploadOrganisationImage,
        'fr-BE': be_fr.uploadOrganisationImage,
        'fr-FR': fr.uploadOrganisationImage,
        'pl-PL': pl.uploadOrganisationImage,
        'es-ES': es.uploadOrganisationImage,
    },
    createShareLink: {
        'en-EN': en.createShareLink,
        'nl-NL': nl.createShareLink,
        'de-DE': de.createShareLink,
        'nl-BE': be_nl.createShareLink,
        'fr-BE': be_fr.createShareLink,
        'fr-FR': fr.createShareLink,
        'pl-PL': pl.createShareLink,
        'es-ES': es.createShareLink,
    },
    updateShareLink: {
        'en-EN': en.updateShareLink,
        'nl-NL': nl.updateShareLink,
        'de-DE': de.updateShareLink,
        'nl-BE': be_nl.updateShareLink,
        'fr-BE': be_fr.updateShareLink,
        'fr-FR': fr.updateShareLink,
        'pl-PL': pl.updateShareLink,
        'es-ES': es.updateShareLink,
    },
    deleteShareLink: {
        'en-EN': en.deleteShareLink,
        'nl-NL': nl.deleteShareLink,
        'de-DE': de.deleteShareLink,
        'nl-BE': be_nl.deleteShareLink,
        'fr-BE': be_fr.deleteShareLink,
        'fr-FR': fr.deleteShareLink,
        'pl-PL': pl.deleteShareLink,
        'es-ES': es.deleteShareLink,
    },
    updatePermissions: {
        'en-EN': en.updatePermissions,
        'nl-NL': nl.updatePermissions,
        'de-DE': de.updatePermissions,
        'nl-BE': be_nl.updatePermissions,
        'fr-BE': be_fr.updatePermissions,
        'fr-FR': fr.updatePermissions,
        'pl-PL': pl.updatePermissions,
        'es-ES': es.updatePermissions,
    },
    getRole: {
        'en-EN': en.getRole,
        'nl-NL': nl.getRole,
        'de-DE': de.getRole,
        'nl-BE': be_nl.getRole,
        'fr-BE': be_fr.getRole,
        'fr-FR': fr.getRole,
        'pl-PL': pl.getRole,
        'es-ES': es.getRole,
    },
    loadAllOrganisationsRequest: {
        'en-EN': en.loadAllOrganisationsRequest,
        'nl-NL': nl.loadAllOrganisationsRequest,
        'de-DE': de.loadAllOrganisationsRequest,
        'nl-BE': be_nl.loadAllOrganisationsRequest,
        'fr-BE': be_fr.loadAllOrganisationsRequest,
        'fr-FR': fr.loadAllOrganisationsRequest,
        'pl-PL': pl.loadAllOrganisationsRequest,
        'es-ES': es.loadAllOrganisationsRequest,
    },
    loadSelectedUserRequest: {
        'en-EN': en.loadSelectedUserRequest,
        'nl-NL': nl.loadSelectedUserRequest,
        'de-DE': de.loadSelectedUserRequest,
        'nl-BE': be_nl.loadSelectedUserRequest,
        'fr-BE': be_fr.loadSelectedUserRequest,
        'fr-FR': fr.loadSelectedUserRequest,
        'pl-PL': pl.loadSelectedUserRequest,
        'es-ES': es.loadSelectedUserRequest,
    },
    updateUserRequest: {
        'en-EN': en.updateUserRequest,
        'nl-NL': nl.updateUserRequest,
        'de-DE': de.updateUserRequest,
        'nl-BE': be_nl.updateUserRequest,
        'fr-BE': be_fr.updateUserRequest,
        'fr-FR': fr.updateUserRequest,
        'pl-PL': pl.updateUserRequest,
        'es-ES': es.updateUserRequest,
    },
    loadPagedUserRequest: {
        'en-EN': en.loadPagedUserRequest,
        'nl-NL': nl.loadPagedUserRequest,
        'de-DE': de.loadPagedUserRequest,
        'nl-BE': be_nl.loadPagedUserRequest,
        'fr-BE': be_fr.loadPagedUserRequest,
        'fr-FR': fr.loadPagedUserRequest,
        'pl-PL': pl.loadPagedUserRequest,
        'es-ES': es.loadPagedUserRequest,
    },
    updateOrganisationMatrixes: {
        'en-EN': en.updateOrganisationMatrixes,
        'nl-NL': nl.updateOrganisationMatrixes,
        'de-DE': de.updateOrganisationMatrixes,
        'nl-BE': be_nl.updateOrganisationMatrixes,
        'fr-BE': be_fr.updateOrganisationMatrixes,
        'fr-FR': fr.updateOrganisationMatrixes,
        'pl-PL': pl.updateOrganisationMatrixes,
        'es-ES': es.updateOrganisationMatrixes,
    },
};

export const getUserErrorMessage = (errorName: UserErrorTypeKeys,  language: keyof MultiLingualString) =>  {
    const message = UserErrors[errorName];
    return message[language] || message['en-EN'];
}
